import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

class Instagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instagram: "",
    };
  }

  async componentDidMount() {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/instagram", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: this.props.author,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) this.setState({ instagram: res.data[0].instagram });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      this.state.instagram.length > 0 && (
        <span className={classes.textSmall}>
          <a
            style={{alignContent: "center", justifyContent: "center", alignItems: "center" }}
            href={"https://www.instagram.com/" + this.state.instagram}
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} style={{ marginRight: 3}} />
            <span style={{ textDecoration: "underline" }}>{this.state.instagram}</span>
          </a>
        </span>
      )
    );
  }
}

const styles = {
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 11,
    fontWeight: 500,
  },
};

const InstagramTranslated = withTranslation("common")(Instagram);
export default injectSheet(styles)(InstagramTranslated);
