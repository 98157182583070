import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import { displayDate } from "../../../context/helper";
import { ArrowLeftCircle, ArrowRightCircle, MoreHorizontal, Send, Share } from "react-feather";
import AudioPlayerButtons from "./AudioPlayerButtons";
import mdh from "../../../assets/images/logo_mdh_de.png";
import { Swipe } from "react-swipe-component";
import drops from "../../../assets/icons/drops.svg";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 3 + 2 * Math.random(),
    };
  }

  onSwipeBack = () => {
    this.props.previous();
    console.log("swipe");
  };
  onSwipeNext = () => {
    this.props.next();
    console.log("swipe");
  };
  onKeyPressed = (e) => {
    if (e.key === "ArrowLeft") {
      this.props.next();
    } else if (e.key === "ArrowRight") {
      this.props.previous();
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Swipe
        nodeName="div"
        onSwipedLeft={this.onSwipeBack}
        onSwipedRight={this.onSwipeNext}
        onSwipedDown={this.onSwipeBack}
        onSwipedUp={this.onSwipeNext}
      >
        <div className={classes.card} onKeyDown={this.onKeyPressed} tabIndex={0}>
          <div className={classes.listCard} key={this.props.displayItems[this.props.index]._id}>
            <div className={classes.price} style={{ display: "flex" }}>
              {this.props.displayItems[this.props.index].duration.split(":")[0] === "0" &&
              parseInt(this.props.displayItems[this.props.index].duration.split(":")[1]) < 30 ? (

                <div className={classes.textSmall} style={{margin:"-2px auto", display: "block",flexDirection: "row", justifyContent: "center", alignItems: "center" }}>             
                    Free
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      src={drops}
                      alt="drops"
                      style={{
                        color: "#87C9FF",
                        fill: "#87C9FF",
                        verticalAlign: "middle",
                        width: 8,
                        marginRight: 4,
                        marginBottom:"2px"
                      }}
                    />
                  </div>
                  <div className={classes.textSmall} style={{ verticalAlign: "middle", marginTop: 2 }}>
                    {this.props.displayItems[this.props.index].duration.split(":")[0] === "0"
                      ? "1"
                      : this.props.displayItems[this.props.index].duration.split(":")[0]}
                  </div>
                </div>
              )}
            </div>
            {this.props.displayItems[this.props.index].author === "mydirtyhobby" && (
              <div className={classes.sponsored}>
                <span className={classes.textSmaller}>Sponsored by</span>
                <img alt="sponsor" className={classes.sponsoredLogo} src={mdh} />
              </div>
            )}
            <div className={classes.infos}>
              <div
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  marginTop: 8,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className={classes.titleText}>
                    <b className={classes.text} style={{ fontSize: 18, fontWeight: 600 }}>
                      {this.props.displayItems[this.props.index].title}
                    </b>
                    <br />
                  </div>
                  <div className={classes.desktopShare}>
                    <button
                      aria-label="Share audio"
                      className={classes.noDefaultButton}
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          window.location.origin + "/preview/" + this.props.displayItems[this.props.index]._id
                        );
                        this.props.setShareUrl(
                          window.location.origin + "/preview/" + this.props.displayItems[this.props.index]._id
                        );
                        this.props.showPopupShare(true);
                        mixpanel.track("Click_share_button");
                      }}
                    >
                      <Share
                        style={{
                          width: 16,
                          marginTop: 5,
                          color: "white",
                        }}
                      />
                    </button>
                  </div>
                  <div className={classes.desktopMore}>
                    <button
                      aria-label="Report content"
                      className={classes.noDefaultButton}
                      onClick={async () => {
                        mixpanel.track("Click_report_button");
                        this.props.showPopupReport(true);
                      }}
                    >
                      <MoreHorizontal
                        style={{
                          fontSize: "28px",
                          marginTop: 7,
                          color: "white",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/*<StarRatings
                 rating={this.state.rating}
                 starEmptyColor="rgba(255, 193, 7,0.2)"
                 starRatedColor="rgba(255, 193, 7, .9)"
                 starDimension="18px"
                 starSpacing="1px"
                 />*/}
                {/*<button
                 aria-label="Play preview"
                 style={{
                 backgroundColor: 'rgba(135, 201, 255, 1)',
                 borderRadius: 5,
                 marginLeft: 0,
                 width: 90,
                 marginTop: -2,
                 }}
                 className={classes.noDefaultButton}
                 onClick={async () => {
                 await this.props.playPreview(this.props.displayItems[this.props.index]);
                 mixpanel.track("Play_Preview");
                 }}>
                 <div>
                 <span className={classes.textSmall}>
                 <b style={{
                 verticalAlign: 'middle',
                 color: 'white',
                 fontSize: 10,
                 marginTop: 3
                 }}>Preview</b>
                 </span>
                 <PlayCircle style={{
                 fill: 'white',
                 color: '#87C9FF',
                 width: 16,
                 verticalAlign: 'middle',
                 marginLeft: 5
                 }} />
                 </div>
                 </button>*/}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  marginLeft: 10,
                  opacity: "0.9",
                  flexWrap: "wrap",
                }}
              >
                <div className={classes.date}>
                  <span className={classes.textSmaller} style={{ fontSize: 14, fontWeight: 500 }}>
                    {displayDate(this.props.displayItems[this.props.index].date)}
                  </span>
                  <span style={{ fontSize: 14, fontWeight: 500 }} className={classes.textSmaller}>
                    {" "}
                    - {this.props.displayItems[this.props.index].lng}
                  </span>
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  marginLeft: 10,
                  opacity: "0.9",
                  flexWrap: "wrap",
                }}
              >
                {this.props.displayItems[this.props.index].hashtags.slice(-3).map((hashtag) => (
                  <p className={classes.textSmaller} style={{ margin: 0, marginRight: 5 }}>
                    [{hashtag}]
                  </p>
                ))}
              </div> */}
              <div
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  marginTop: 4,
                  fontWeight: 400,
                }}
              >
                {this.props.displayItems[this.props.index].description && (
                  <span
                    className={classes.noDefaultButton}
                    style={{
                      color: "white",
                      fontSize: 15,
                      marginTop: 2,
                      fontWeight: 400,
                    }}
                  >
                    {this.props.displayItems[this.props.index].description}
                  </span>
                )}
              </div>
            </div>
            <div className={classes.line} />
            <div className={classes.arrow}>
              <ArrowLeftCircle onClick={this.props.previous} className={classes.iconLeft} />
              <ArrowRightCircle onClick={this.props.next} className={classes.iconRight} />
            </div>

            <Link
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "middle",
                alignItems: "center",
              }}
              to={"/authorprofile/" + this.props.displayItems[this.props.index].author}
            >
              {this.props.profilePics.filter(
                (pic) => pic["author"] === this.props.displayItems[this.props.index].author
              )[0] ? (
                <img
                  alt={"cover"}
                  className={classes.audioListCardPic}
                  src={
                    this.props.profilePics.filter(
                      (pic) => pic["author"] === this.props.displayItems[this.props.index].author
                    )[0].fileUrl
                  }
                />
              ) : (
                <img
                  alt={"cover"}
                  className={classes.audioListCardPic}
                  style={{ opacity: 0.6 }}
                  src={"https://voiyalvoices.de/voiyal.png"}
                />
              )}
              <div className={classes.textSmall1}>
                {this.props.hotVoice ? (
                  <div>
                    <span
                      className="material-icons"
                      style={{
                        fontSize: 18,
                        color: "#87C9FF",
                        marginTop: -3,
                        verticalAlign: "middle",
                      }}
                    >
                      &#xef55;
                    </span>
                    <span style={{ color: "#87C9FF" }}>{this.props.displayItems[this.props.index].author}</span>
                  </div>
                ) : (
                  <span>{this.props.displayItems[this.props.index].author}</span>
                )}
                <Link
                  onClick={() => mixpanel.track("Click_open_chat_listenlist")}
                  to={"/chat/" + this.props.displayItems[this.props.index].author}
                  style={{
                    border: 1,
                    marginTop: 5,
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderColor: "white",
                    color: "white",
                    opacity: 0.6,
                    display: "flex",
                    flexDirection: "row",
                    width: 98,
                    // marginLeft: 3,
                    cursor: "pointer",
                    textAlign: "center",
                    verticalAlign: "center",
                    height:"31px"
                  }}
                  className={classes.noDefaultButton}
                >
                  <Send
                    style={{
                      width: 14,
                      height: 14,
                      marginLeft: 9,
                      marginRight: 4,
                      marginTop:9

                    }}
                  />
                  <span
                    style={{
                      marginLeft: 6,
                      fontSize: 12,
                      fontWeight: 500,
                      marginTop: 2,
                    }}
                  >
                    Message
                  </span>
                </Link>
              </div>
            </Link>
            <div style={{ marginTop: "5px" }}>
              <AudioPlayerButtons
                time={this.props.time}
                next={this.props.next}
                previous={this.props.previous}
                stop={this.props.stop}
                clickPlay={this.props.clickPlay}
                duration={this.props.displayItems[this.props.index].duration}
                progressBar={this.props.progressBar}
              />
            </div>
            <div className="divshadow1"></div>
            <div className="divshadow2"></div>
          </div>
        </div>
      </Swipe>
    );
  }
}

const styles = {
  titleText: {
    marginTop: 8,
    textAlign: "left",
    marginLeft: 10,
    width: 250,
  },
  arrow: {
    display: "none",
    height: 0,
  },
  iconLeft: {
    width: "50px",
    height: 50,
    fill: "#87C9FF",
    marginLeft: "-72.5px",

    color: "white",
    overflow: "overlay",
    cursor: "pointer",
  },
  iconRight: {
    overflow: "overlay",
    width: "50px",
    height: 50,
    fill: "#87C9FF",
    position: "absolute",
    right: "-62.5px",
    color: "white",
    cursor: "pointer",
  },
  infos: {
    height: "180px",
    overflow: "hidden",
  },
  card: {
    outline: "none",
  },

  listCard: {
    display: "inline-block",
    width: "80%",
    margin: 15,
    height: "414px",
    boxShadow: "0px 0px 10px 0px #c59cdc",
    padding: 10,
    textAlign: "left",
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 20,
    position: "relative",
    // "&:hover": {
    //   boxShadow: "0px 0px 10px #C59CDC",
    //   backgroundColor: "#180931",
    // },
    // "&:active": {
    //   boxShadow: "0px 0px 10px #C59CDC",
    //   backgroundColor: "#180931",
    // },
  },
  audioListCardPic: {
    fontSize: "12px",
    width: 55,
    height: 55,
    objectFit: "cover",
    marginTop: 9,
    borderRadius: 50,
    marginLeft: 10,
    verticalAlign: "middle",
  },
  text: {
    color: "white",
    fontSize: 18,
    width: 600,
  },
  textSmall: {
    color: "white",
    fontSize: 14,
    textAlign: "center",
  },
  textSmall1: {
    color: "white",
    marginTop: 5,
    marginLeft: 10,
    verticalAlign: "middle",
    fontWeight: 600,
    fontSize: "14px",
  },
  textSmaller: {
    fontSize: 11,
    color: "rgba(255, 255, 255, .5)",
  },
  price: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#87C9FF",
    borderRadius: 5,
    maxWidth: 47,
    verticalAlign: "middle",
    marginLeft: 10,
    marginTop: 8,
    textAlign: "center",
    height: 21,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgba(255, 255, 255, 0.2)",
    margin: 5,
  },
  sponsored: {
    position: "absolute",
    top: 14,
    right: 10,
    borderRadius: 5,
    backgroundColor: "rgba(255, 255, 255, .2)",
    verticalAlign: "middle",
    width: 160,
    textAlign: "center",
    margin: "auto",
  },
  sponsoredLogo: {
    width: 70,
    verticalAlign: "middle",
    marginTop: 3,
    marginLeft: 4,
  },
  desktopShare: {
    position: "initial",
  },
  desktopMore: {
    position: "initial",
  },
  "@media screen and (max-width: 320px)": {
    listCard: {
      margin: -15,
      marginTop: 10,
    },
  },
  "@media screen and (min-width: 800px)": {
    titleText: {
      width: "auto",
    },
    arrow: {
      display: "block",
    },
    infos: {
      height: 200,
    },
    line: {
      display: "none",
    },
    audioListCardPic: {
      width: 84,
      height: 84,
    },
    textSmall1: {
      fontSize: "18px",
      lineHeight: "27px",
      textAlign: "left",
    },
    listCard: {
      boxShadow: "0px 0px 10px 0px #c59cdc",
      maxWidth: "403px",
      width: "403px",
      height: 446,
    },
    desktopShare: {
      position: "absolute",
      top: "10px",
      right: "40px",
    },
    desktopMore: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
  },
  "@media screen and (min-width: 1200px)": {
    listCard: {
      width: "745px",
      maxWidth: "765px",
      height: "392px",
    },
    infos: {
      height: 145,
    },
  },
};

const ListCardComponentTranslated = withTranslation("common")(ListCard);
export default injectSheet(styles)(ListCardComponentTranslated);
