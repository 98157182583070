import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { Droplet } from "react-feather";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <div className={classes.tutorial}>
          <div className={classes.border}>
            <Droplet
              style={{
                color: "#87C9FF",
                fill: "#87C9FF",
                verticalAlign: "middle",
                width: 18,
                margin: 3,
              }}
            />
            <b
              className={classes.textToken}
              style={{
                verticalAlign: "middle",
                fontWeight: 600,
              }}
            >
              0
            </b>
          </div>
          <div className={classes.center}>
            <b className={classes.textSmall}>{t("tutorial.step1text1")}</b>
            <br />
            <span className={classes.textSmall}>
              {t("tutorial.step1text2")}
            </span>
          </div>
          <div style={{ textAlign: "end" }}>
            <button
              aria-label="Submit tutorial step 1"
              className={classes.submitButton}
              style={{ marginTop: 40, fontSize: 14 }}
              onClick={() => {
                this.props.updateStep(2);
                this.props.step1();
                mixpanel.track("Click_tutorial_step1");
              }}
            >
              {t("listen.next")}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

const styles = {
  body: {
    width: "100%",
    height: "calc(100% + 300px)",
    position: "absolute",
    margin: 0,
    padding: 0,
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 100,
    top: 0,
    left: 0,
  },
  tutorial: {
    maxWidth: 320,
    margin: "auto",
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#180931",
    padding: 20,
    borderRadius: 10,
    boxShadow: "0px 0px 10px #C59CDC",
    width: 260,
    marginTop:"90px"
  },
  text: {
    color: "rgba(197, 156, 220, 1)",
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    textAlign: "center",
  },
  textSmall: {
    color: "rgba(197, 156, 220, 1)",
    fontSize: 14,
    textAlign: "center",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, 1)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 60px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 1)",
    },
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  border: {
    position: "absolute",
    right: "30px",
    top: "22px",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#87C9FF",
    borderRadius: 8,
    maxWidth: 80,
    textAlign: "center",
    alignItems: "center",
    margin: "auto",
    paddingLeft: 5,
    paddingRight: 8,
    display: "flex",
    flexDirection: "row",
  },
  textToken: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 500,
  },
  "@media screen and (min-width: 800px)": {
    tutorial: {
      maxWidth: 682,
      marginTop: "140px",
      marginLeft: "40%",
      width: '50%',
      position: 'absolute',
      right: '3%',
    },
    submitButton: {
      
      width: "calc(100% - 300px)",
      marginRight: 0,
     
    },
    border: {
      display: 'none',
    },
    center: {

      width: 'auto',

    },
  },
};

const Step1Translated = withTranslation("common")(Step1);
export default injectSheet(styles)(Step1Translated);
