import React, { useState } from "react";
import "./payment.css";
import trash from "../../../../assets/icons/trash.svg";
import ConfirmModal from "./ConfirmModal";
const CCard = (props) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClick = () => {
    setOpenDeleteModal(true);
  };

  return (
    <>
      {openDeleteModal && (
        <ConfirmModal onClose={() => setOpenDeleteModal(false)}>Do You want to remove this card ?</ConfirmModal>
      )}

      <div className="card ccard">
        <img class="delete" onClick={() => handleClick()} src={trash} alt="delete" />
        <div className="front active">
          <div className="decoration">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="type">YOUR CARD</div>
          <div className="chip">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="row info">
            <div>
              <div className="row number">
                {props.number.length > 0 ? (
                  <span>{props.number}</span>
                ) : (
                  <span className="default">XXXX XXXX XXXX XXXX</span>
                )}
              </div>
              <div className="row expiration">
                <div className="label">VALID THRU</div>
                <div className="cdate">
                  {props.month.length > 0 ? <span>{props.month}</span> : <span className="default">MM</span>}
                  {props.month.length > 0 && props.year.length > 0 ? (
                    <span>/</span>
                  ) : (
                    <span className="default">/</span>
                  )}
                  {props.year.length > 0 ? <span>{props.year}</span> : <span className="default">YY</span>}
                </div>
              </div>
              <div className="row name">
                {props.name.length > 0 ? <span>{props.name}</span> : <span className="default">NAME SURNAME</span>}
              </div>
            </div>
            <div className="flag">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CCard;
