import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { PlayCircle, SkipBack, SkipForward, Square, StopCircle } from "react-feather";
import { msToTime } from "../../../context/helper";
import playButton from "../../../assets/icons/playbtn.svg";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class AudioPlayerButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
    this.format = this.format.bind(this);
  }

  format(duration) {
    let min = duration.split(":")[0];
    if (min.length < 2) min = "0" + min;
    let sec = duration.split(":")[1];
    if (sec.length < 2) sec = "0" + sec;
    return min + ":" + sec;
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div style={{ width: "96%", margin: "auto" }}>
          <progress style={{ width: "100%" }} value={this.props.progressBar} max="0.98"></progress>
        </div>
        <div className={classes.player}>
          <span style={{  marginLeft:"8px" }} className={classes.textSmall}>{this.state.playing ? msToTime(this.props.time) : "00:00"}</span>
          <div style={{display:"flex"}}>
            <SkipBack onClick={this.props.previous} style={{ width: 25, height: 25, marginRight:"23px",marginTop:23 }} className={classes.iconSmall} />
            {!this.state.playing ? (
              <>
                <PlayCircle
                  onClick={() => {
                    this.props.clickPlay();
                    this.setState({ playing: true });
                  }}
                  style={{ width: 60, height: 60, fill: "#87C9FF", marginTop:"5px" }}
                  className={classes.iconLarge}
                />
                <img
                  src={playButton}
                  alt=""
                  onClick={() => {
                    this.props.clickPlay();
                    this.setState({ playing: true });
                  }}
                  style={{ width: 60, height: 60, fill: "#87C9FF" }}
                  className={classes.iconPlay}
                />
              </>
            ) : (
              <>
                <StopCircle
                  onClick={() => {
                    this.props.stop();
                    this.setState({ playing: false });
                  }}
                  style={{ width: 60, height: 60, fill: "#87C9FF",marginTop:"5px" }}
                  className={classes.iconLarge}
                />
                <div className={classes.iconStop}>
                  <Square
                    onClick={() => {
                      this.props.stop();
                      this.setState({ playing: false });
                    }}
                    style={{ width: 22, height: 22, fill: "white" }}
                  />
                </div>
              </>
            )}
            <SkipForward onClick={this.props.next} style={{ width: 25, height: 25, marginLeft:"23px",marginTop:23 }} className={classes.iconSmall} />
          </div>
          <span style={{  marginRight:"8px" }} className={classes.textSmall}>{this.format(this.props.duration)}</span>
        </div>
      </div>
    );
  }
}

const styles = {
  player: {
    width: "96%",
    height: 80,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    verticalAlign: "middle",
    padding:"10px"
  },
  progressBarUnactive: {
    marginTop: 20,
    borderRadius: 10,
    borderWidth: 2,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    borderStyle: "solid",
    borderColor: "rgba(255, 255, 255, .2)",
  },
  progressBarActive: {
    marginTop: -1,
    borderRadius: 10,
    borderWidth: 3,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    borderStyle: "solid",
    borderColor: "#87C9FF",
    position: "absolute",
  },
  textSmall: {
    color: "white",
    fontSize: 11,
    marginTop: 22,
    fontWeight: 600,
    marginLeft: 5,
    marginRight: 5,
    textAlign: "center",
    verticalAlign: "middle",
    width: 30,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  iconSmall: {
    display: "initial",
    color: "white",
    fill: "white",
    width: 60,
    verticalAlign: "middle",
    margin: 5,
  },
  iconLarge: {
    color: "white",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  iconPlay: {
    display: "none",
  },
  iconStop: {
    display: "none",
  },

  "@media screen and (max-width: 389px)": {
    iconSmall: {
      display: "none",
    },
  },

  "@media screen and (min-width: 800px)": {
    iconLarge: {
      display: "none",
    },
    iconStop: {
      backgroundColor: "#87C9FF",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "60px",
      height: "60px",
      color: "white",
      borderRadius: "50%",
      marginTop: "6px",
    },
    
    iconPlay: {
      width: "60px",
      height: "60px",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fill: "#87C9FF",
      borderRadius: "50%",
      marginTop: "6px",
    },
    player: {
      width: "auto",
    },
  },
};

const AudioPlayerButtonsComponentTranslated = withTranslation("common")(AudioPlayerButtons);
export default injectSheet(styles)(AudioPlayerButtonsComponentTranslated);
