import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link, Redirect } from "react-router-dom";
import { getUser, getUsername } from "../../context/auth";
import Counter from "./Components/Counter";
import MicRecorder from "mic-recorder-to-mp3";
import { withTranslation } from "react-i18next";
import "../../styling/tagsInput.css";
import "../../styling/dropdown.css";
import { UploadCloud, Mic, StopCircle, HelpCircle } from "react-feather";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../general/SettingsButton";
import Form from "./Components/Form";
import Upload from "./Popup/Upload";
import Sidebar from "../../general/Navigation/Sidebar";
import Header from "../../general/Navigation/Header";
import axios from "axios";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class RecordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      recording: false,
      doneRecording: false,
      redirect: false,
      blobURL: "",
      audioData: "",
      audio_file: {},
      isBlocked: false,
      url: "/",
      upload: false,
      ageVerify: false,
      loading: true,
    };
    this.redirect = this.redirect.bind(this);
    this.delete = this.delete.bind(this);
    this.setPopup = this.setPopup.bind(this);
    this.counter = React.createRef();
  }

  componentDidMount() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Permission Granted");
        window.localStream = stream;
        console.log(window.localStream);
        this.setState({ isBlocked: false });
      })
      .catch(() => {
        console.log("Permission Denied");
        this.setState({ isBlocked: true });
      });
    getUser().then((redirect) => {
      this.setState({ redirect: redirect });
    });
    getUsername().then((name) => {
      this.setState({ username: name });
    });
    mixpanel.track("Screen_open_record");
    this.checkIfVerified();
  }

  componentWillUnmount() {
    if (!this.state.isBlocked && window.localStream) {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }
  async checkIfVerified() {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/ageverify/check", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((r) => {
        this.setState({ ageVerify: r.data });
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
    this.setState({ loading: false });
  }

  redirect() {
    this.setState({
      url: "/listenlist",
      redirect: true,
    });
  }

  start = () => {
    if (this.state.isBlocked) {
      alert("Permission Denied");
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          window.localStream = stream;
          console.log(window.localStream);
          this.setState({ isBlocked: false });
        })
        .catch(() => {
          console.log("Permission Denied");
          this.setState({ isBlocked: true });
        });
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({
            doneRecording: false,
            recording: true,
          });
          this.counter.current.startCount();
        })
        .catch((e) => console.error(e));
    }
  };

  setPopup(value) {
    this.setState({
      upload: value,
    });
  }

  stop = () => {
    if (this.counter.current.checkCount() > 9) {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          window.localStream.getTracks().forEach((track) => {
            track.stop();
          });
          const blobURL = URL.createObjectURL(blob);
          console.log(blobURL);
          const file = new File(buffer, Date.now() + ".mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });
          new Response(blob).arrayBuffer().then(() => this.setState({ audio_file: file }));
          this.setState({
            blobURL: blobURL,
            audioData: buffer,
            recording: false,
            doneRecording: true,
          });
          this.counter.current.stopCount();
        })
        .catch((e) => console.log(e));
    }
  };

  delete() {
    this.setState({
      username: "",
      recording: false,
      doneRecording: false,
      redirect: false,
      titleInput: "",
      titleError: "Gib einen Titel ein",
      blobURL: "",
      audioData: "",
      audio_file: {},
      isBlocked: false,
      disabled: true,
      tags: [
        { id: 1, name: "asmr" },
        { id: 2, name: "nsfw" },
      ],
    });
    this.counter.current.setCounterToDefault();
  }

  async fileSelected(e) {
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize <= 20) {
      const blobURL = window.URL.createObjectURL(e.target.files[0]);
      this.setState({
        blobURL: blobURL,
        audioData: e.target.files[0],
        recording: false,
        doneRecording: true,
        audio_file: e.target.files[0],
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    if (this.state.loading === true) {
      return (
        <>
          <Header />
          <Sidebar />
        </>
      );
    } else
      return this.state.redirect ? (
        <Redirect to={{ pathname: this.state.url }} />
      ) : localStorage.getItem("token") === null ? (
        <Redirect to={{ pathname: "/" }} />
      ) : this.state.ageVerify !== true ? (
        <Redirect to={{ pathname: "/verifyage" }} />
      ) : (
        <div className={classes.body}>
          <div className="noHeader">
            <Header />
          </div>

          {this.state.upload && <Upload setPopup={this.setPopup} />}
          <div className={this.state.doneRecording ? classes.hide : classes.start}>
            <SettingsButton />
            <Link to={"/rules"}>
              <HelpCircle className={classes.help} />
            </Link>
            <span className={classes.title}>{t("record.text")}</span>
            <span className={classes.text}>{t("record.start")}</span>
            <div className={classes.recButtons}>
              <div className={!this.state.recording ? classes.start : classes.hide}>
                <div className={classes.startButton}>
                  <button
                    aria-label="Start recording"
                    className={classes.button}
                    id="record"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => {
                      this.start();
                      mixpanel.track("Start_Recording");
                    }}
                  >
                    <Mic style={{ width: 69, height: 69 }} />
                    <span 
                      style={{
                        fontSize:"15px", 
                        lineHeight:"22px", 
                        marginTop:"3%"
                        }}>
                          {t("record.startButton")}</span>
                  </button>
                </div>
                <span className={classes.textSmaller}>{t("record.or")}</span>
                <div className={classes.startButton}>
                  <label htmlFor="upload-audio" className={classes.button}>
                    <UploadCloud style={{ width: 69, height: 69 }} />
                    <span 
                    style={{
                      fontSize:"15px", 
                      lineHeight:"22px", 
                      marginTop:"3%"}} >{t("record.uploadAudio")}</span>
                  </label>
                </div>
                <span style={{textDecorationLine:"underline"}} className={classes.textSmaller}>{t("record.maxMin")}</span>
                <input
                  id="upload-audio"
                  type="file"
                  accept="multiple"
                  placeholder={t("record.uploadAudio")}
                  style={{
                    opacity: 0,
                    position: "absolute",
                    zIndex: -1,
                  }}
                  onChange={(e) => {
                    this.fileSelected(e);
                    mixpanel.track("File_Upload_Recording");
                  }}
                />
              </div>
              <div className={this.state.recording ? classes.start : classes.hide}>
              <div className={classes.startButton}>
              <button className={classes.button}
                  >
                    <Mic style={{ width: 69, height: 69 }} />
                    <span 
                      style={{
                        fontSize:"15px", 
                        lineHeight:"22px", 
                        marginTop:"3%"

                       }}
                      >
                    <Counter ref={this.counter} onStop={this.stop} />  </span>
                  </button>
                </div>
                <button
                  aria-label="Stop recording"
                  className={classes.recButton}
                  id="stop"
                  onClick={() => {
                    this.stop();
                    mixpanel.track("Stop_Recording");
                  }}
                >
                  <div className={classes.stopLine} style={{ verticalAlign: "middle" }}>
                    <span style={{textDecorationLine:"underline", fontSize:"18px", color:"rgba(116, 107, 131, 1)"}}>{t("record.stop")}</span>
                    <StopCircle
                      style={{
                        color: "red",
                        width: 22,
                        height: 22,
                        verticalAlign: "middle",
                        marginLeft: 5,
                      }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 25 }} className={this.state.doneRecording ? classes.stop : classes.hide}>
            <Form
              blobURL={this.state.blobURL}
              audio_file={this.state.audio_file}
              username={this.state.username}
              delete={this.delete}
              setPopup={this.setPopup}
              redirect={this.redirect}
            />
          </div>
          <Sidebar />
          {/* <NavBar /> */}
        </div>
      );
  }
}

const styles = {
  body: {
    backgroundColor: "transparent",
    paddingBottom: 150,
    maxWidth: 450,
    position: "relative",
    margin: "auto",
  },
  recButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  start: {
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  startButton: {
    height: 120,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    borderRadius: 10,
    width: "80%",
    marginTop: 15,
    marginBottom: 10,
  },
  recButton: {
    backgroundColor: "transparent",
    borderWidth: 0,
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(255, 255, 255, 1)",
  },
  stop: {
    alignItems: "center",
    textAlign: "center",
  },
  stopLine: {
    fontSize: "inherit",
  },
  hide: {
    display: "none",
  },
  text: {
    marginBottom: 5,
    color: "rgba(116, 107, 131, 1)",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    lineHeight:"27px"
  },
  textSmaller: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 11,
    textAlign: "center",
    marginTop: 5,
    fontWeight:500
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 22,
    fontWeight: 600,
    color: "white",
    lineHeight:"33px",
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    fontSize: 15,
    fontWeight: 600,
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 15,
  },
  help: {
    position: "absolute",
    left: "5px",
    top: "25px",
    color: "white",
  },

  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: "130px",
      marginLeft: "300px",
      width: "500px",
      maxWidth: "500px",
    },
    title: {
      display: "block",
      marginBottom: "20px",
    },
    // start: {
    //   width: "765px",
    // },
    startButton: {
      width: "765px",
      height: "169px",
    },
    stopLine: {
      fontSize: "18px",
      fontWeight:500,
    },
    recButton: {
      width: "80%",
      height: "169px",
      display: "contents",
    },
    button: {
      marginTop: "29px",
    },
  },
  "@media screen and (min-width: 1060px)": {
    startButton: {
      width: "765px",
    },
    body: {
      width: "765px",
      maxWidth: "765px",
    },
    help:{
      display:"none",
    }
  },
};

const RecordScreenComponentTranslated = withTranslation("common")(RecordScreen);
export default injectSheet(styles)(RecordScreenComponentTranslated);
