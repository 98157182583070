import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ShoppingCart, Edit } from "react-feather";

import mixpanel from "mixpanel-browser";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ProfileButton extends Component {
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <Link to={"/buydrops"}>
          <div className={classes.largeButton}>
            <span
              style={{
                fontSize: 16,
                verticalAlign: "middle",
              }}
            >
              {t("buydrops.title")}
            </span>
            <ShoppingCart
              style={{
                width: 20,
                padding: 5,
                verticalAlign: "middle",
              }}
            />
          </div>
        </Link>
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Link className={classes.button} to="/editprofile">

            <span className="textSmall" style={{ fontSize: 14, marginTop: 2, marginLeft: 2 }}>
              {t("profile.editProfile")}
            </span>
              <Edit style={{ color: "white", width: 20, marginTop: 2, marginLeft: 3 }} />

          </Link>
          <Link className={classes.button} to="/mystories">

            <span className="textSmall" style={{ fontSize: 14, marginTop: 2, marginLeft: 2 }}>
              {t("profile.myStories")}
            </span>

            <svg style={{marginTop: 5, marginLeft: 5}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                style={{fill: 'white'}}
                className="Iconfill"
                d="M15.3211 6.14418C15.1737 6.0995 15.0395 6.01929 14.9304 5.9106C14.8212 5.8019 14.7405 5.66806 14.6952 5.52084C14.44 4.65588 14.012 3.85168 13.437 3.15692C12.8621 2.46216 12.1521 1.89127 11.3501 1.47881C10.5482 1.06634 9.67086 0.82088 8.77129 0.757268C7.87171 0.693656 6.96858 0.813219 6.11655 1.10872C5.26451 1.40423 4.48129 1.86952 3.81428 2.47646C3.14726 3.0834 2.61033 3.81936 2.23595 4.6398C1.86158 5.46025 1.65756 6.34812 1.63624 7.24969C1.61491 8.15126 1.77673 9.04778 2.11189 9.88501C2.18861 10.0615 2.2048 10.2583 2.15792 10.445C2.11104 10.6316 2.00373 10.7974 1.85272 10.9167C1.18916 11.4092 0.671095 12.0719 0.35335 12.8347C0.0356046 13.5975 -0.0699962 14.4321 0.047724 15.25C0.231435 16.3557 0.805494 17.359 1.66571 18.0776C2.52592 18.7962 3.61528 19.1826 4.73606 19.1667H11.6669V12.845L10.5894 13.9225C10.4322 14.0743 10.2217 14.1583 10.0032 14.1564C9.78473 14.1545 9.57572 14.0669 9.42121 13.9124C9.2667 13.7579 9.17906 13.5488 9.17716 13.3303C9.17526 13.1118 9.25926 12.9013 9.41106 12.7442L10.7327 11.4225C11.2015 10.9538 11.8373 10.6905 12.5002 10.6905C13.1631 10.6905 13.7989 10.9538 14.2677 11.4225L15.5894 12.7442C15.669 12.821 15.7325 12.913 15.7761 13.0147C15.8198 13.1163 15.8428 13.2257 15.8438 13.3363C15.8447 13.447 15.8236 13.5567 15.7817 13.6591C15.7398 13.7616 15.678 13.8546 15.5997 13.9328C15.5215 14.0111 15.4284 14.073 15.326 14.1149C15.2236 14.1568 15.1139 14.1778 15.0032 14.1769C14.8926 14.1759 14.7832 14.1529 14.6816 14.1093C14.5799 14.0656 14.4879 14.0021 14.4111 13.9225L13.3336 12.845V19.1558C14.9923 19.1227 16.582 18.4858 17.8048 17.3645C19.0276 16.2433 19.7996 14.7146 19.9761 13.065C20.1085 11.553 19.7187 10.0414 18.8716 8.78197C18.0245 7.52258 16.7714 6.59158 15.3211 6.14418V6.14418Z"
              />
            </svg>

          </Link>
          <Link className={classes.button} to="/myaudios">

            <span className="textSmall" style={{ fontSize: 14, marginTop: 2, marginLeft: 2 }}>
              {t("profile.myVoiyals")}
            </span>

            <span
              className="material-icons"
              style={{
                fontSize: "25px",
                color: "white",
                marginBottom: 0,
                verticalAlign: "middle",
                paddingLeft: "1px"
              }}
            >
              &#xe544;
            </span>

          </Link>
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    textAlign: "center",
    alignItems: "center",
  },
  button: {
    padding: "10px 15px",
    cursor: "pointer",
    alignSelf: "center",
    verticalAlign: "middle",
    color: "#180931",
    backgroundColor: "rgba(135, 201, 255, .85)",
    fontSize: 14,
    fontWeight: 500,
    border: "none",
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
  },
  largeButton: {
    marginTop: 30,
    marginBottom: 15,
    padding: 6,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
  },
  "@media screen and (max-width: 320px)": {
    button: {
      padding: "5px 8px",
    },
    largeButton: {
      marginTop: 10,
    },
  },
  "@media screen and (min-width: 800px)": {
    button: {
      width: "30%",
    },
  },
};

const ProfileButtonComponentTranslated = withTranslation("common")(ProfileButton);
export default injectSheet(styles)(ProfileButtonComponentTranslated);
