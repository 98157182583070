import React, { Component } from "react";
import injectSheet from "react-jss";

class Counter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: 0,
      counterString: "00:00",
    };
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  startCount = () => {
    this.interval = setInterval(() => {
      this.setState({ number: this.state.number + 1 });
      if (this.state.number > 540) this.props.onStop();
      this.convertToString();
    }, 1000);
  };

  convertToString = () => {
    let minutes = Math.floor(this.state.number / 60);
    let seconds = this.state.number % 60;
    if (seconds.toString().length < 2) seconds = "0" + seconds.toString();
    this.setState({ counterString: "0" + minutes + ":" + seconds });
  };

  stopCount = () => {
    clearInterval(this.interval);
  };

  checkCount = () => {
    return this.state.number;
  };

  checkCounterString = () => {
    return this.state.counterString;
  };

  setCounterToDefault = () => {
    this.setState({ number: 0, counterString: "00:00" });
  };

  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.body}>
        <span style={this.state.number > 9 ? { color: "#00ff00" } : { color: "#ff0000" }}>
          {this.state.counterString}
        </span>
      </div>
    );
  };
}

const styles = {
  body: {
    fontSize: 15,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
    marginTop: "2%",
    lineHeight:"22.5px"
  },
};

export default injectSheet(styles)(Counter);
