import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import Autosuggest from "react-autosuggest";
import { getOrCreateChat } from "react-chat-engine";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class NewChatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameInput: "",
      suggestions: [],
      value: "",
    };
    this.createDirectChat = this.createDirectChat.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.openChat) {
      console.log("hier");
      this.createDirectChat(this.props.openChat);
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  getSuggestionValue = (suggestion) => suggestion;

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  createDirectChat() {
    if (this.props.creators.includes(this.state.usernameInput)) {
      getOrCreateChat(this.props.creds, { is_direct_chat: true, usernames: [this.state.usernameInput] }, () =>
        this.setState({ usernameInput: "" })
      );
      mixpanel.track("Screen_start_new_chat");
    } else {
      alert("not a creator");
    }
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.creators.filter((creator) => creator.toLowerCase().slice(0, inputLength) === inputValue);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      usernameInput: newValue,
    });
  };

  renderSuggestion = (suggestion) => <div className={this.props.classes.suggestions}>{suggestion}</div>;

  render = () => {
    const { classes } = this.props;
    const { value, suggestions } = this.state;
    const { t } = this.props;
    const inputProps = {
      placeholder: t("chat.searchCreator"),
      value,
      onChange: this.onChange,
    };

    return (
      <div className={classes.body}>
        {this.props.openChat}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={{
            input: classes.input,
            suggestionsContainer: classes.suggestions,
            suggestionsList: classes.suggestionsList,
          }}
        />
        <button
          aria-label="Create new chat"
          onClick={() => {
            this.createDirectChat();
            if (this.props.setShowList) this.props.setShowList();
          }}
          className={classes.submitButton}
        >
          {t("chat.startChat")}
        </button>
      </div>
    );
  };
}

const styles = {
  body: {
    height: 200,
    width: "100%",
    zIndex: 300000,
    marginTop: 25,
  },
  suggestions: {
    backgroundColor: "white",
    borderRadius: 5,
    fontSize: 14,
    position: "absolute",
  },
  suggestionsList: {
    color: "#180931",
    width: 220,
  },
  input: {
    "&::placeholder": {
      fontSize: 12,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: "rgb(71, 49, 104);",
    fontSize: 15,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 5,
    paddingLeft: 10,
    width: "97%",
    webkitTextFillColor: "white",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 10,
    marginBottom: 5,
    padding: 8,
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 12,
    borderRadius: 5,
    border: 0,
    width: "100%",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  "@media screen and (min-width: 800px)": {
    input: {
      width: "97%",
    },
    body: {
      width: "100%",
    },
    submitButton: {
      width: "100%",
    },
  },
  // "@media screen and (min-width: 1220px)": {
  //   input: {
  //     width: "260px",
  //   },
  // },
};

const NewChatFormTranslated = withTranslation("common")(NewChatForm);
export default injectSheet(styles)(NewChatFormTranslated);
