import React, { Component } from "react";
import FlatList from "flatlist-react";
import { Link, Redirect } from "react-router-dom";
import { getUser, getUsername } from "../../context/auth";
import axios from "axios";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import PopupShare from "./Popups/PopupShare";
import SettingsButton from "../../general/SettingsButton";
import ListCardMyVoiyals from "../../general/ListCardMyVoiyals";
import PreviewPlayer from "../Record/Components/PreviewPlayer";
import Sidebar from "../../general/Navigation/Sidebar";
import Header from "../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class MyAudios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      items: [],
      displayItems: [],
      active_item: {},
      tokens: 0,
      index: -1,
      free: false,
      profilePics: [],
      mainPicUrl: "",
      username: "",
      showPopup: false,
      shareUrl: "",
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.showPopupShare = this.showPopupShare.bind(this);
    this.showPopupShare = this.showPopupShare.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  async handleKeyDown(event) {
    if (event.key === "Enter") {
      if (event.target.value.length === 0)
        this.setState({
          displayItems: this.state.items,
        });
      else {
        const result = this.state.items.filter((item) => item.hashtags.length > 0);
        const finalResult = result.filter((item) => item.hashtags.includes(event.target.value) === true);
        const result2 = this.state.items.filter((item) => item.author.includes(event.target.value) === true);
        this.setState({
          displayItems: finalResult.concat(result2),
        });
      }
    }
  }

  showPopupShare(value) {
    this.setState({
      showPopup: value,
    });
  }

  async componentDidMount() {
    getUser().then((redirect) => {
      this.setState({ redirect: redirect });
    });
    getUsername().then((name) => {
      this.setState({ username: name });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/myvoiyals/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: name,
          },
        })
        .then((res) => {
          if (res.data[0] && res.data[0].audios.length > 0) {
            res.data[0].audios.forEach((id) => {
              axios
                .get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudio/" + id, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  let array = [];
                  this.state.displayItems.forEach(function (item) {
                    array.push(item);
                  });

                  if (res.data[0]) array.push(res.data[0]);
                  this.setState({ displayItems: array, items: array });
                  return;
                })
                .catch((e) => {
                  console.log(e);
                });
            });
          }
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/audios/oneuser", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: this.state.username,
              },
            })
            .then((res) => {
              axios
                .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                  params: {
                    username: name,
                  },
                })
                .then(async (response) => {
                  let array = res.data;
                  array = array.filter((item) => !response.data[0] || !response.data[0].authors.includes(item.author));
                  let array2 = this.state.displayItems.filter(
                    (item) => !response.data[0] || !response.data[0].authors.includes(item.author)
                  );
                  this.setState({
                    displayItems: array.concat(array2),
                    items: this.state.items.concat(res.data),
                  });
                });
            });
        });
    });
    mixpanel.track("Screen_open_my_stories");
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/all", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.setState({ profilePics: res.data });
      });
  }

  setActiveItem = async (item) => {
    try {
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
          params: {
            username: item.author,
          },
        })
        .then((res) => {
          if (res.data.length > 0) this.setState({ mainPicUrl: res.data[0].fileUrl });
          else this.setState({ mainPicUrl: "" });
        });
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudio/" + item._id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.setState({ active_item: res.data[0] });
        });
    } catch (e) {
      console.log(e);
    }
    this.setState({ active_item: item });
  };

  renderItem = (item) => {
    return (
      <ListCardMyVoiyals
        item={item}
        active={item._id === this.state.active_item?._id}
        setActiveItem={this.setActiveItem}
      />
    );
  };
  previous() {
    if (this.state.index - 1 >= 0) {
      this.setActiveItem(this.state.items[this.state.index - 1]);
      this.setState({ index: this.state.index - 1 });
    } else {
      this.setState({ index: this.state.items.length });
    }
  }
  next() {
    if (this.state.index + 1 < this.state.items.length) {
      this.setActiveItem(this.state.items[this.state.index + 1]);
      this.setState({ index: this.state.index + 1 });
    } else {
      this.setState({ index: 0 });
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div>
        <div className="noHeader">
          <Header />
        </div>
        {this.state.showPopup && <PopupShare shareUrl={this.state.shareUrl} showPopupShare={this.showPopupShare} />}
        <div className={classes.body}>
          <SettingsButton />
          <div
            style={{
              justifyContent: "center",
              verticalAlign: "middle",
              alignItems: "center",
              margin: "auto",
              maxWidth: 350,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("myAudios.title")}</span>
            </div>
          </div>
          <div className={classes.playList}>
            <div className={classes.noDesktop}>
              {this.state.mainPicUrl ? (
                <div>
                  <Link to={"/authorprofile/" + this.state.active_item.author}>
                    <img alt={"current title"} src={this.state.mainPicUrl} className={classes.mainPic} />
                  </Link>
                </div>
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <img
                    alt={"current title"}
                    style={{ opacity: 0.5 }}
                    src={"https://voiyal.de/voiyal.png"}
                    className={classes.mainPic}
                  />
                </div>
              )}
              <div className={classes.textSmall}>
                <b>{this.state.active_item.title}</b> -
                <Link to={"/authorprofile/" + this.state.active_item.author}>{this.state.active_item.author}</Link>
              </div>
              {this.state.mainPicUrl && (
                <div
                  style={{
                    alignItems: "center",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <button
                    aria-label="Share audio"
                    className={classes.noDefaultButton}
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        window.location.origin + "/preview/" + this.state.active_item._id
                      );
                      this.setState({ showPopup: true });
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{
                        fontSize: "28px",
                        color: "white",
                      }}
                    >
                      &#xe6b8;
                    </span>
                  </button>
                </div>
              )}
            </div>
             <div className="noHeader">
              <div style={{ textAlign: "start", marginLeft: 10, fontSize: 18, marginTop: 10 }}>
                <span>{this.state.active_item.title}</span>
              </div>
            </div> 
            <div style={{ margin: "0px 2px", padding: "0px 10px" }} >
              <PreviewPlayer
                previous={this.previous}
                next={this.next}
                blobURL={this.state.active_item.url || ""}
                duration={this.state.active_item.duration}
              />
            </div>
            <div className="noHeader">
            <div style={{ display: "flex", alignItems: "center", marginLeft:"5px" }}>
                {this.state.mainPicUrl ? (
                  <div
                    style={{
                      alignItems: "center",
                      margin: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Link to={"/authorprofile/" + this.state.active_item.author}>
                      <img alt={""} src={this.state.mainPicUrl} className={classes.mainPic} />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
               <div className={classes.textSmall} style={{ marginLeft: "5px", fontSize:"15px", fontWeight:500}}>
                  <Link to={"/authorprofile/" + this.state.active_item.author}>{this.state.active_item.author}</Link>
                </div>
              </div>

              {this.state.mainPicUrl && (
                <div
                  style={{
                    alignItems: "center",
                    margin: "auto",
                    textAlign: "center",
                  }}
                ></div>
              )}
            </div>
          </div>
          <div className="divshadow11"></div>
          <div className="divshadow21"></div>
          <div className={classes.list}>
            <FlatList
              list={this.state.displayItems}
              renderItem={this.renderItem}
              renderOnScroll
              renderWhenEmpty={() => (
                <div style={{ margin: "auto" }}>
                  <div style={{ opacity: 0.8 }} className={classes.textSmall}>
                    {t("general.emptyList")}
                  </div>
                  <Link to={"/listenlist"}>
                    <div style={{ textDecoration: "underline" }} className={classes.textSmall}>
                      {t("general.emptyListExplore")}
                    </div>
                  </Link>
                </div>
              )}
            />
          </div>
          <Sidebar />
          {/* <NavBar /> */}
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    maxWidth: "auto",
    margin: " 0px 2%",
    paddingBottom: 100,
  },

  playList: {
    boxShadow: "0px 0px 10px 0px #c59cdc",
    padding: 10,
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 20,
    // display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    textAlign: "center",
  },
  price: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#87C9FF",
    borderRadius: 5,
    width: 59,
    verticalAlign: "middle",
    marginLeft: 10,
    marginTop: 8,
    textAlign: "center",
    height: 23,
    display:"flex"
  },
  date: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: 5,
    marginBottom: 5,
  },
  textSmall: {
    color: "white",
    fontSize: 14,
    textAlign: "center",
  },
  textSmaller: {
    fontSize: 13,
    color: "rgba(255, 255, 255, .65)",
  },
  list: {
    textAlign: "center",
    marginTop: 20,
  },
  playButtonTop: {
    width: 30,
    height: 30,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 22,
    fontWeight: 600,
    color: "white",
    textAlign: "center",
  },
  listCard: {
    display: "inline-block",
    width: 140,
    height: 220,
    marginBottom: 20,
    marginRight: 15,
    padding: 5,
    textAlign: "left",
    listStyleType: "none",
    background: "rgba(135, 201, 255, 0.2)",
    borderRadius: 5,
    position: "relative",
  },
  mainPic: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
  listCardHot: {
    display: "inline-block",
    width: 140,
    height: 220,
    marginBottom: 20,
    marginRight: 10,
    padding: 5,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 5,
    position: "relative",
    boxShadow: "0px 0px 2rem #C59CDC",
  },
  noDesktop: {
    display: "block,",
  },
  "@media screen and (min-width: 800px)": {
    noDesktop: {
      display: "none",
    },
    mainPic: {
      width: "61px",
      height: "61px",
      borderRadius: "50%",
    },
    body: {
      marginTop: "130px",
      width: "500px",
      maxWidth: "500px",
      marginLeft: "300px",
    },
    list: {
      marginTop:"35px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  "@media screen and (min-width: 1060px)": {
    body: {
      width: "765px",
      maxWidth: "765px",
      marginLeft: "300px",
      height:"307px"
    },
  },
};

const MyAudiosTranslated = withTranslation("common")(MyAudios);
export default injectSheet(styles)(MyAudiosTranslated);
