import React, { Component } from "react";
import injectSheet from "react-jss";
import "../../styling/title.css";
import { Redirect } from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import "../../styling/Cookies.css";
import "../../context/Global.css";
import { withTranslation } from "react-i18next";
import Footer from "../../general/Navigation/Footer";
import LogInForm from "./Components/LogInForm";
import SafeArea from "react-safe-area-component";
import mixpanel from "mixpanel-browser";
import Over18 from "./Popups/Over18";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      showPopup: true,
      playing: false,
    };
    this.setRedirect = this.setRedirect.bind(this);
    this.setShowPopup = this.setShowPopup.bind(this);
  }

  componentDidMount() {
    // getUsername().then((username) => {
    //   this.setState({ redirect: !!username });
    // });
    mixpanel.track("Screen_open_login");
  }

  componentWillUnmount() {
    if (this.state.playing) {
      let audio1 = document.getElementById("audio");
      audio1.pause();
      audio1.currentTime = 0;
      this.setState({
        playing: false,
      });
    }
  }

  setRedirect(boolean) {
    this.setState({ redirect: boolean });
  }

  setShowPopup(value) {
    this.setState({
      showPopup: value,
    });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return this.state.redirect ? (
      <Redirect
        to={{
          pathname: "/listenlist",
          state: { username: this.state.name },
        }}
      />
    ) : (
      <SafeArea top bottom>
        <div className={classes.body}>
          <CookieConsent
            disableStyles={true}
            buttonText={t("logIn.cookiesOk")}
            buttonClasses="cookies-btn"
            containerClasses="CookiesContainer"
            enableDeclineButton
            onDecline={() => {
              window.open("/decline", "_self");
            }}
            declineButtonText={t("logIn.cookiesNo")}
            declineButtonClasses="cookies-btn cookies-decline"
            contentClasses="CookiesDescription"
            expires={150}
          >
            <div style={{ marginLeft: 20 }}>
              {t("logIn.cookies")}{" "}
              <a style={{ textDecoration: "underline" }} href="/terms-of-service.html">
                Terms of Service.
              </a>
              <br />
              <span
                style={{
                  fontSize: 12,
                  opacity: 0.8,
                }}
              >
                {t("logIn.cookies2")}
              </span>
              <br />
              <a
                style={{
                  opacity: 0.5,
                  textDecoration: "underline",
                  fontSize: 10,
                }}
                rel="noreferrer"
                href="https://www.voiyal.com/imprint-data-policy.html"
                target={"_blank"}
              >
                {t("logIn.cookiesLink")}
              </a>
            </div>
          </CookieConsent>
          {this.state.showPopup && <Over18 setShowPopup={this.setShowPopup} />}
          <div className={classes.container}>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
              <img
                // style={{ width: 120 }}
                className={classes.desktopLogo}
                src="https://d3bk8pb8wlf64b.cloudfront.net/Logo_GIF.gif"
                alt="loading..."
              />
            </div>
            <div
              style={{
                display: "flex",
                maxWidth: "512px",
                margin: 5,
                marginTop: 0,
              }}
            >
              <span className={classes.title}>{t("logIn.title4")}</span>
            </div>
            <LogInForm setRedirect={this.setRedirect} />
          </div>
          <Footer style={{ position: "relative", bottom: 5, display: "block" }} />
        </div>
      </SafeArea>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    overflowX: "hidden",
    paddingBottom: 100,
  },
  container: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle",
  },
  title: {
    marginTop: 0,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "22px",
    opacity: 0.9,
    marginBottom: 5,
    // color: "white",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "rgba(255, 255, 255, 0.5)",
  },
  desktopLogo: {
    width: "140px",
  },
  "@media screen and (min-width: 800px)": {
    desktopLogo: {
      width: "240px",
    },
    text: {
      fontSize: "15px",
      lineHeight: "22.5px",
      textAlign: "center",
    },
  },
};

const LogInComponentTranslated = withTranslation("common")(LogIn);
export default injectSheet(styles)(LogInComponentTranslated);
