import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

import mixpanel from "mixpanel-browser";
import axios from "axios";
import { validateMessage } from "../../../context/helper";
import drops from "../../../assets/icons/dropsW.svg";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class NewMessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.setInput = this.setInput.bind(this);
  }

  async sendMessage() {
    if (this.state.text.length > 0) {
      if (!this.props.creators.includes(this.props.username)) {
        this.props.setTokens();
        mixpanel.track("Screen_send_message_payed");
      } else {
        mixpanel.track("Screen_send_message_not_payed");
      }
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + "/chatengine/createmessage", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            text: this.state.text,
            messageid: this.props.chatAppState.activeChat,
            username: this.props.username,
            author: this.props.author,
            date: new Date(Date.now()),
          },
        })
        .then(async (res) => {
          this.setState({ text: "" });
        });
      if (!this.props.creators.includes(this.props.username)) {
        try {
          await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/transactions/addtranstospread",
            {},
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                name: this.props.author,
                amount: 1,
              },
            }
          );
        } catch (e) {
          console.log(e);
        }
      }
      mixpanel.track("Screen_send_message");
    }
  }

  setInput(text) {
    let validated = validateMessage(text);
    this.setState({ text: validated });
  }

  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <input
          maxLength="140"
          placeholder={t("chat.messagePlaceholder")}
          className={classes.input}
          value={this.state.text}
          onChange={(text) => this.setInput(text.target.value)}
          onKeyPress={(e) => e.keyCode === 13 && this.sendMessage}
        />
        {this.props.creators.includes(this.props.username) ? (
          <button aria-label="Send message 1" className={classes.submitButton} onClick={this.sendMessage}>
            <span
              style=
                {{
                  marginRight:"5px",
                  fontSize:"20px",
                  marginTop:"-5px"
                }}
              >
            </span>
            <img src={drops} alt=" " className={classes.buttonIcon} />
          </button>
        ) : (
          <button aria-label="Send message 2" className={classes.submitButton} onClick={this.sendMessage}>
           <span
              style=
                {{
                  marginRight:"5px",
                  fontSize:"20px",
                  marginTop:"-5px"
                }}
              >1</span>
            <img src={drops} alt=" " className={classes.buttonIcon} />
          </button>
        )}
      </div>
    );
  };
}

const styles = {
  body: {
    backgroundColor: "#1f094d",
    display: "flex",
    flexDirection: "row",
    bottom: 0,
    zIndex: 10,
  },
  input: {
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginTop: 10,
    marginBottom: 5,
    width: "100%",
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 14,
    fontFamily: "Poppins",
    paddingTop: 10,
    display: "relative",
    zIndex: 10,
    paddingBottom: 10,
    border: "none",
    borderRadius: 10,
    paddingLeft: 16,
    webkitTextFillColor: "white",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonIcon: {
    width: 14,
    fill: "white",
    verticalAlign: "middle",
  },
  submitButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 10,
    marginBottom: 5,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    verticalAlign: "middle",
    zIndex: 10,

    backgroundColor: "#323267",
    color: "white",
    fontWeight: 600,
    textAlign: "center",
    alignSelf: "center",
    fontSize: 14,
    width: 40,
    height: 41,
    borderRadius: 8,
    border: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

const NewMessageFormTranslated = withTranslation("common")(NewMessageForm);
export default injectSheet(styles)(NewMessageFormTranslated);
