import React from "react";
import "../../styling/title.css";
import "../../context/Global.css";
import "./style.css";
import "../../styling/Cookies.css";
import Logo from "../../components/Logo";
import useMediaQuery from "../../shared/utils/DeviceTypes";
import HeaderSearchButton from "../../components/Listen/Components/headerSearchButton";
import HeaderProfile from "../../components/Profile/Components/HeaderProfile";

const Header = (props) => {
  let isDesktop = useMediaQuery("(min-width: 800px)");
  return (
    <header className={" " + (isDesktop ? "header" : undefined)}>
       {/* <BackButton url={"/"} /> */}
      <Logo width={142} />
      {localStorage.getItem("token") !== null && (
        <div className={" " + (isDesktop ? "displayContent" : "displayNone")}>
          <HeaderSearchButton
            filterAllPrices={props.filterAllPrices || null}
            filterFree={props.filterFree || null}
            filterPaid={props.filterPaid || null}
            filterAll={props.filterAll || null}
            filterEnglish={props.filterEnglish || null}
            filterSpanish={props.filterSpanish || null}
            filterGerman={props.filterGerman || null}
          />
          <HeaderProfile />
        </div>
      )}
    </header>
  );
};

export default Header;
