import React from "react";
import { X } from "react-feather";
import "../style.css";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import useMediaQuery from "../../../shared/utils/DeviceTypes";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

const XButton = (props) => {
  const isDesktop = useMediaQuery("(min-width: 800px)");
  return (
    <div className={"backButton openCloseBtn " + (isDesktop ? "" : "notMobile")}>
      <Link to={props.url}>
        <X
          style={{
            width: 30,
            height: 30,
            color: "rgba(255, 255, 255, .85)",
          }}
        />
      </Link>
    </div>
  );
};

export default XButton;
