import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import NewChatForm from "./NewChatForm";
import { getOrCreateChat } from "react-chat-engine";
import { displayDateMessage } from "../../../context/helper";
import ProfilePictureAuthor from "../../Profile/Components/ProfilePictureAuthor";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notSet: true,
      id: 0,
      new: true,
    };
    this.setNotSet = this.setNotSet.bind(this);
  }

  componentWillReceiveProps() {
    const name = this.props.openChat;
    if (this.props.creators.includes(name) && name.length > 0) {
      let map = this.props.chatAppState.chats;
      if (map && this.state.new) {
        const item = Object.keys(map).map((keyName) => this.getMessageAuthor(map[keyName]) === name);
        if (!item.includes(true)) {
          console.log("create new chat", name);
          getOrCreateChat(this.props.chatAppState.creds, { is_direct_chat: true, usernames: [name] }, () =>
            this.setState({ usernameInput: "" })
          );
        } else {
          this.props.chatAppState.setActiveChat(Object.keys(map)[item.indexOf(true)]);
          mixpanel.track("Open_existing_chat");
        }
        this.setState({ new: false });
      }
    } else if (this.state.notSet) {
      if (this.state.id !== 0) {
        this.props.chatAppState.setActiveChat(this.state.id);
        this.setState({ notSet: false });
      }
    }
  }

  setActive(item) {
    if (this.state.notSet) {
      if (this.state.id === 0) {
        this.setState({ id: item.id });
      }
    }
  }

  setNotSet() {
    this.setState({
      notSet: false,
    });
  }

  getMessageAuthor(item) {
    if (item.people[0] && item.people[1]) {
      if (item.people[0].person.username !== this.props.username) {
        return item.people[0].person.username;
      } else {
        return item.people[1].person.username;
      }
    } else {
      return "";
    }
  }

  render = () => {
    const { classes } = this.props;
    let map = this.props.chatAppState.chats;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <span className={classes.title}>{t("chat.yourChats")}</span>
        {this.props.chatAppState.chats &&
          (Object.keys(map).length > 0 ? (
            <div
              style={{
                marginTop: 10,
                height: "100%",
                maxHeight: "660px",
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {Object.keys(map).map(
                (keyName) =>
                  !this.props.blockedUsers.includes(this.getMessageAuthor(map[keyName])) && (
                    <li
                      onClick={() => {
                        this.props.chatAppState.setActiveChat(map[keyName].id);
                      }}
                      className={classes.listElement}
                      key={map[keyName].id}
                    >
                      <span
                        className={classes.text}
                        style={
                          map[keyName].id === this.props.chatAppState.activeChat
                            ? { color: "#87C9FF", width: "100%", cursor: "pointer" }
                            : { width: "100%", cursor: "pointer" }
                        }
                      >
                        <div
                          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => {
                            this.props.chatAppState.setActiveChat(map[keyName].id);
                          }}
                        >
                          <ProfilePictureAuthor name={this.getMessageAuthor(map[keyName])} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "-webkit-fill-available",
                              marginRight: 5,
                            }}
                          >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <div>{this.getMessageAuthor(map[keyName])}</div>
                              <div
                                style={{
                                  fontWeight: 500,
                                  fontSize: " 11px",
                                  lineHeight: "16px",
                                  color: "rgba(255, 255, 255, 0.5)",
                                }}
                              >
                                {/* {map[keyName].last_message.sender === this.getMessageAuthor(map[keyName]) && */}
                                {map[keyName].last_message.text}
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "rgba(255, 255, 255, 0.5)",
                                  fontWeight: "500",
                                }}
                              >
                                {displayDateMessage(map[keyName].last_message.created)}
                              </div>
                              {map[keyName].people.find((obj) => {
                                return obj.person.username === this.props.username;
                              }).last_read !== map[keyName].last_message.id &&
                                map[keyName].last_message.created && <div className="newSms">N</div>}
                            </div>
                          </div>
                        </div>
                      </span>
                    </li>
                  )
              )}
            </div>
          ) : (
            <div>
              <span className={classes.text}>{t("chat.noChatList")}</span>
            </div>
          ))}
        <NewChatForm creds={this.props.chatAppState.creds} creators={this.props.creators} />
      </div>
    );
  };
}

const styles = {
  body: {
    paddingTop: 30,
    backgroundColor: "#1f094d;",
    width: 650,
    height: "80vh",
  },
  text: {
    // display: "flex",
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    fontWeight: 600,
  },
  title: {
    color: "white",
    fontSize: 15,
    fontWeight: 600,
    marginTop: 20,
    width: "100%",
  },
  listElement: {
    listStyleType: "none",
    display: "flex",
    flexDirection: "row",
    borderBottom: "  1px solid rgba(255, 255, 255, 0.2)",
    cursor: "pointer",
  },
  "@media screen and (max-width: 450px)": {
    body: {
      width: 50,
      height: 650,
    },
  },

  "@media screen and (min-width: 800px)": {
    body: {
      height: "calc(100% - 130px)",

      width: "100%",
    },
    title: {
      fontSize: 22,
    },
  },
};

const ChatListTranslated = withTranslation("common")(ChatList);
export default injectSheet(styles)(ChatListTranslated);
