import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import mixpanel from "mixpanel-browser";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class PopupShare extends Component {
  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.popup}>
        <div className={classes.popupInner}>
        <h4 style={{ marginBottom: 20, fontSize: 16 }}>{t("donationpopup.share1")}</h4>
            <div style={{display:"flex", gap:"0.5%"}}>
              <FacebookShareButton url={this.props.shareUrl} quote={t("common:donationpopup.shareFacebookQuote")}>
                <FacebookIcon size={40} round={true}/>
              </FacebookShareButton>
              <FacebookMessengerShareButton style={{margin: 3}} url={this.props.shareUrl} quote={t("common:donationpopup.shareFacebookQuote")}>
                <FacebookMessengerIcon size={40} round={true}/>
              </FacebookMessengerShareButton>
            <EmailShareButton style={{margin: 3}} url={this.props.shareUrl} subject={t("common:donationpopup.shareEmailTitle")} body={t("common:donationpopup.shareEmailBody")}>
                <EmailIcon size={40} round={true}/>
              </EmailShareButton>
              <RedditShareButton style={{margin: 3}} url={this.props.shareUrl} title={t("common:donationpopup.shareFacebookQuote")}>
                <RedditIcon size={40} round={true}/>
              </RedditShareButton>
              <TelegramShareButton style={{margin: 3}} url={this.props.shareUrl} title={t("common:donationpopup.shareEmailBody")}>
                <TelegramIcon size={40} round={true}/>
              </TelegramShareButton>
              <TwitterShareButton style={{margin: 3}} url={this.props.shareUrl} title={t("common:donationpopup.shareEmailBody")}>
                <TwitterIcon size={40} round={true}/>
              </TwitterShareButton>
              <WhatsappShareButton style={{margin: 3}} url={this.props.shareUrl} title={t("common:donationpopup.shareEmailBody")}>
                <WhatsappIcon size={40} round={true}/>
              </WhatsappShareButton> 
            </div>        
          <div>
            <span className={classes.textSmall}>{t("donationpopup.share2")}</span>
          </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              aria-label="Close popup"
              className="submitButton"
              style={{
                margin: 0,
                marginTop: 40,
                fontSize: 15,
              }}
              onClick={() => {
                this.props.showPopupShare(false);
                mixpanel.track("Click_share");
              }}
            >
              {t("donationpopup.ok")}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

const styles = {
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 100,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    position: "absolute",
    borderRadius: 20,
    left: "10%",
    zIndex: 102,
    right: "10%",
    top: "10%",
    bottom: "48%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    background: "#180931",
  },
  textSmall: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    textAlign: "center",
  },
  "@media screen and (min-width: 800px)": {
    popup: {
      top: "200px",
      width: "100%",
    },
    popupInner:{
      width:"30%",
      maxWidth:"511px",
      maxHeight:"447px",
      padding:"20px",
      marginLeft:"36.5%",
      minWidth:"300px"
    },
  },
};

const PopupShareTranslated = withTranslation("common")(PopupShare);
export default injectSheet(styles)(PopupShareTranslated);