import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import AudioPlayerButtons from "../Components/AudioPlayerButtons";


mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <div>
          <div className={classes.tutorial}>
            <div className={classes.center}>
              <span className={classes.text}>{t("tutorial.step2text1")}</span>
              <br />
              <span className={classes.textSmall}>
                {t("tutorial.step2text2")}
              </span>
            </div>
            <div style={{ textAlign: "center", width: 300, margin: "auto" }}>
              <button
                aria-label="Submit tutorial step 2"
                className={classes.submitButton}
                onClick={() => {
                  this.props.updateStep(3);
                  mixpanel.track("Click_tutorial_step2");
                }}
              >
                {t("tutorial.done")}
              </button>
            </div>
          </div>
           <div>
            <div className={classes.audioplayer}>
              <AudioPlayerButtons
                time={0}
                next={() => console.log("none")}
                previous={() => console.log("none")}
                stop={() => console.log("none")}
                clickPlay={() => console.log("none")}
                duration={"6:09"}
                progressBar={"0"}
              />
            </div>
          </div> 
        </div>
      </div>
    );
  };
}

const styles = {
  body: {
    width: "100%",
    height: "100%",
    position: "absolute",
    margin: 0,
    padding: 0,
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    top: 0,
    left: 0,
    zIndex: 100,
    paddingBottom: 200,
  },
  center: {
    marginTop: 80,
    marginBottom: 10,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#180931",
    padding: 20,
    borderRadius: 10,
    boxShadow: "0px 0px 10px #C59CDC",
    width: 260,
    display: "absolute",
  },
  text: {
    color: "rgba(197, 156, 220, .85)",
    fontSize: 18,
    marginTop: 15,
    fontWeight: 600,
    textAlign: "center",
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  tutorial: {
    display: "block",
  },
  textSmall: {
    color: "rgba(197, 156, 220, 1)",
    fontSize: 14,
    textAlign: "center",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, 1)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 60px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 1)",
    },
  },

  audioplayer: {
    marginTop: 26,
    marginBottom: 10,
    boxShadow: "none",
    width: "78%",

    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor: "#180931",
    padding: 20,
    borderRadius: 10,

    display: "absolute",
  },
  "@media screen and (min-width: 600px)": {
    audioplayer:{
      marginTop:46,
    }
  },
  "@media screen and (max-width: 320px)": {
    audioplayer:{
      marginTop:21,
    }
  },

  "@media screen and (min-width: 800px)": {
    audioplayer: {
      position: "absolute",
      top: "506px",
      left:"calc(50% - 82px)",
      width: "404px",
      height:"84px",
      padding: 20,
      borderRadius: 10,
      boxShadow: "none",
    },

    center: {
      
      lineHeight:"20px",
      width: "383px"
      
      
    },
    submitButton: {
      width: "339px",
      marginLeft: 0,
      marginRight: 0,
      textAlign: "center!important",
      marginTop:"5px"
      
    },

    tutorial: {
      position: "absolute",
      top: "265px",
      left: "calc(50% - 104px)",
      width: "500px",
    },
  },

  "@media screen and (min-width: 1200px)": {
    audioplayer: {
      position: "absolute",
      top: "451px",
      left:"calc(50% - 237px)",
      width: "745px",
      // backgroundColor: "#180931",
      height:"84px",
      padding: 20,
      borderRadius: 10,
      boxShadow: "none",
    },
    tutorial: {
      position: "absolute",
      top: "610px",
      left: "calc(50% - 221px)",
      width: "740px",
    },
    center: {

      
      width: "700px",
    },
    submitButton: {
      width: "339px",
      marginLeft: 0,
      marginRight: 0,
      textAlign: "center!important",
     
    },
  },
};

const Step2Translated = withTranslation("common")(Step2);
export default injectSheet(styles)(Step2Translated);
