import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

class Twitter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twitter: "",
    };
  }

  async componentDidMount() {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/twitter", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: this.props.author,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) this.setState({ twitter: res.data[0].twitter });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      this.state.twitter.length > 0 && (
        <span className={classes.textSmall}>
          <a
            style={{ display: "flex", paddingTop: "0px" }}
            href={
              this.state.twitter.includes("twitter")
                ? this.state.twitter
                : "https://www.twitter.com/" + this.state.twitter
            }
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} style={{ marginRight: 3, marginTop: 8 }} />
            <span style={{ textDecoration: "underline" }}>
              {this.state.twitter.substring(this.state.twitter.lastIndexOf("/") + 1)}
            </span>
          </a>
        </span>
      )
    );
  }
}

const styles = {
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 11,
    fontWeight: 500,
  },
};

const TwitterTranslated = withTranslation("common")(Twitter);
export default injectSheet(styles)(TwitterTranslated);
