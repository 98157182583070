import React, { Component } from "react";
import injectSheet from "react-jss";
import { User } from "react-feather";
import axios from "axios";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import Header from "../../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      usernameError: false,
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.confirmName = this.confirmName.bind(this);
  }

  handleChangeInput(event) {
    let input = event.target.value;
    this.setState({ username: input });
    if (input.indexOf(" ") !== -1) this.setState({ usernameError: true });
    else this.setState({ usernameError: false });
  }

  randomName() {
    this.setState({ username: "User" + Date.now() });
  }

  async confirmName(alertMessage) {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/username/check", {
        params: {
          username: this.state.username,
        },
      })
      .then((res) => {
        if (!res.data) {
          this.props.setStep(this.props.step + 1);
          this.props.setUsername(this.state.username);
        } else {
          alert(alertMessage);
        }
      });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <div>
        <Header />
        <div className={classes.body}>
          <div style={{ marginBottom: 35, width: 350, marginLeft: 12 }}>
            <h6 className={classes.text} style={{ marginBottom: 5 }}>
              {t("register.step2.title")}
            </h6>
          </div>
          <form className={classes.form}>
            <span className={classes.textSmall} >
              {t("register.step2.text")}
            </span>
            <div className={classes.input_container}>
              <User className={classes.input_image} />
              <input
                placeholder={t("register.step2.input")}
                maxLength="35"
                autoCapitalize="none"
                onChange={this.handleChangeInput}
                className={classes.input}
                color={"white"}
              />
            </div>
            <span
              style={{
                fontWeight: "bold",
                color: "red",
                marginLeft: 10,
                fontSize: "10px",
              }}
            >
              {this.state.usernameError ? t("register.step2.error") : ""}
            </span>
            <button
              aria-label="Submit register Step 1"
              onClick={async (e) => {
                e.preventDefault();
                await this.confirmName(t("register.step2.alert"));
                mixpanel.track("Submit_register_step1", {
                  username: this.state.username,
                });
              }}
              className={classes.submitButton}
              // disabled={this.state.username.length === 0}
            >
              {t("register.step1.submit")}
            </button>
            <button
              aria-label="Submit final step register"
              onClick={async (e) => {
                e.preventDefault();
                this.randomName();
                await this.confirmName(t("register.step2.alert"));
                mixpanel.track("Submit_register_step1", {
                  username: "random",
                });
                mixpanel.track("Choose_random_name");
              }}
              className={classes.submitButton1}
              style={{ opacity: 0.6, maxWidth: 200, height: 40, padding: 3 }}
            >
              <span style={{ opacity: 1 }}>{t("register.step1.skip")}</span>
            </button>
            <div
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            ></div>
          </form>
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    marginTop: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 14,
    fontWeight: 550,
    marginLeft: "13px",
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 25,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 40,
    color: "white",
    width: "calc(100% - 65px)",
    webkitTextFillColor: "white",
  },

  input_image: {
    color: "white",
    position: "absolute",
    bottom: 13,
    left: 20,
    width: 25,
    height: 25,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.5)",
    },
  },
  submitButton1: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.5)",
    },
  },
  "@media screen and (min-width: 800px)": {
    submitButton: {
      width: "calc(100% - 200px)",
    },
    // submitButton1: {
    //   display: "none",
    // },
    input: {
      maxWidth: "450px",
    },
    body: {
      fontSize: "15px",
      marginTop: "0px",
    },
    text: {
      width: "480px",
      textAlign: "center",
      fontSize: "22px",
    },
  },
  input_container: {
    position: "relative",
    padding: 0,
    margin: 0,
  },
};

const Step2ComponentTranslated = withTranslation("common")(Step2);
export default injectSheet(styles)(Step2ComponentTranslated);
