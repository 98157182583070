import React, { Component } from "react";
import injectSheet from "react-jss";
import { validatePassword } from "../../../context/helper";
import { withTranslation } from "react-i18next";
import { Lock, Eye } from "react-feather";
import mixpanel from "mixpanel-browser";
import Header from "../../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: "",
      passwordError: false,
      passwordError2: false,
      checkbox: false,
      checkbox2: false,
    };
    this.handleChangeInput1 = this.handleChangeInput1.bind(this);
    this.handleChangeInput2 = this.handleChangeInput2.bind(this);
  }

  handleChangeInput1(event) {
    this.setState({ password: event.target.value });
    this.setState({ password2: "" });
    if (validatePassword(event.target.value)) {
      this.setState({ passwordError: false });
    } else {
      this.setState({ passwordError: true });
    }
  }

  handleChangeInput2(event) {
    this.setState({ password2: event.target.value });
    if (this.state.password === event.target.value) {
      this.setState({ passwordError2: false });
    } else {
      this.setState({ passwordError2: true });
    }
  }

  showPassword() {
    let x = document.getElementById("password1");
    let y = document.getElementById("password2");
    if (x.type === "password") {
      x.type = "text";
      y.type = "text";
    } else {
      x.type = "password";
      y.type = "password";
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <div className={classes.body}>
        <Header />
        <div className={classes.header}>
          <h6
            className={classes.text}
            style={{
              marginBottom: 5,
            }}
          >
            {t("register.step3.title")}
          </h6>
        </div>
        <form className={classes.form}>
          <span className={classes.textSmall1}>{t("register.step3.text")}</span>
          <div className={classes.input_container}>
            <Lock className={classes.input_image} />
            <input
              type="password"
              id="password1"
              placeholder={t("register.step3.input1")}
              className={classes.input}
              onChange={this.handleChangeInput1}
            />
            <Eye className={classes.showPass} onClick={this.showPassword} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "red",
              fontSize: "10px",
              marginLeft: 20,
              marginBottom: 4,
            }}
          >
            {this.state.passwordError ? t("register.step3.error1") : ""}
          </span>
          <div className={classes.input_container}>
            <Lock className={classes.input_image} />
            <input
              type="password"
              id="password2"
              placeholder={t("register.step3.input2")}
              className={classes.input}
              onChange={this.handleChangeInput2}
            />
            <Eye className={classes.showPass} onClick={this.showPassword} />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "red",
              fontSize: "10px",
              marginLeft: 20,
              marginBottom: 4,
            }}
          >
            {this.state.passwordError2 ? t("register.step3.error2") : ""}
          </span>
          <div className={classes.checks}>
            <div className={classes.inputs}>
              <div>
                <input
                  type="checkbox"
                  id="c1"
                  checked={this.state.checkbox}
                  onChange={() =>
                    this.setState({
                      checkbox: !this.state.checkbox,
                    })
                  }
                />
                <label htmlFor="c1" className={classes.textSmall}>
                  <span></span>
                </label>
              </div>
              <div>
                <span className={classes.textSmall}>{t("register.step3.agbsRead1")}</span>
                <span> </span>
                <a href={"terms-of-service.html"} style={{ textDecoration: "underline" }} rel="noreferrer" target="_blank">
                  <span className={classes.textSmall}>
                    {t("register.step3.agbsRead2")}
                  </span>
                </a>
                <span className={classes.textSmall}>{t("register.step3.agbsRead3")}</span>
                <a href={"imprint-data-policy.html"} style={{ textDecoration: "underline" }} rel="noreferrer" target="_blank">
                  <span className={classes.textSmall}>
                    {t("register.step3.agbsRead4")}
                  </span>
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 30,
                maxWidth: 350,

                marginRight: "auto",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id="c2"
                  checked={this.state.checkbox2}
                  onChange={() =>
                    this.setState({
                      checkbox2: !this.state.checkbox2,
                    })
                  }
                />
                <label htmlFor="c2" className={classes.textSmall}>
                  <span></span>
                </label>
              </div>
              <div>
                <span className={classes.textSmall}>{t("register.step3.overEighteen")}</span>
              </div>
            </div>
          </div>
          <button
            aria-label="Submit register"
            onClick={async (e) => {
              e.preventDefault();
              await this.props.setPassword(this.state.password);
              await this.props.signUp();
              await this.props.setStep(this.props.step + 1);
              mixpanel.track("Submit_register_signup");
            }}
            className={classes.submitButton}
            disabled={
              this.state.password.length === 0 ||
              this.state.password2.length === 0 ||
              this.state.passwordError ||
              this.state.passwordError2 ||
              !this.state.checkbox ||
              !this.state.checkbox2
            }
          >
            {t("register.step3.submit")}
          </button>
        </form>
      </div>
    );
  }
}

const styles = {
  body: {
    marginTop: 20,
    
  },
  header: {
    marginBottom: "px",
    width: "350px",
    marginLeft: "12px",
  },
  inputs: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
    //maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 14,
    fontWeight: 550,
    display: "contents",
  },
  textSmall1: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 14,
    fontWeight: 550,
    marginLeft:"10px",

  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    color: 'white',
    marginBottom: 10,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 25,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 45,
    //maxWidth: 300,
    width: "calc(100% - 65px)",
    webkitTextFillColor: "white",
  },
  input_container: {
    position: "relative",
    padding: 0,
    margin: 0,
  },
  input_image: {
    color: "white",
    position: "absolute",
    bottom: 23,
    left: 20,
    width: 25,
    height: 25,
  },
  showPass: {
    width: 25,
    color: "white",
    position: "absolute",
    height: 25,
    opacity: 0.8,
    bottom: 23,
    right: 20,
  },
  checks: {
    flexDirection: "column",
    marginLeft: "10px",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  "@media screen and (min-width: 800px)": {
    // body: {
    //   marginTop: "120px",
    // },
    submitButton: {
      width: "calc(100% - 200px)",
    },
    inputs: {
      width: "512px",
    },
    input: {
      maxWidth: "450px",
      color:"white",
    },
    body: {
      fontSize: "15px",
    },
    header: {
      marginBottom: "10px",
    },
    text: {
      width: "512px",
      textAlign: "center",
      fontSize:"22px"
    },
    textSmall: {
      width: "512px",
      textAlign: "center",
      marginBottom: "25px",
    },
    textSmall1: {
      width: "512px",
      textAlign: "center",
      marginBottom: "25px",
      marginLeft: '13px',
    },
    checks: {
      marginTop: "100px",
    },
  },
};

const Step3ComponentTranslated = withTranslation("common")(Step3);
export default injectSheet(styles)(Step3ComponentTranslated);
