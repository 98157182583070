import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { getUser, getUsername } from "../../context/auth";
import axios from "axios";
import AudioPlayer from "./Components/AudioPlayer";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import SettingsButton from "../../general/SettingsButton";
import TokensAmount from "./Components/TokensAmount";
import ListCard from "./Components/ListCard";
import PopupBuy from "./Popups/PopupBuy";
import PopupShare from "./Popups/PopupShare";
import PopupReport from "./Popups/PopupReport";
import PopupDropsEarned from "./Popups/PopupDropsEarned";
import AudioPlayerPreview from "./Components/AudioPlayerPreview";
import Filter from "./Components/Filter";
import SearchButton from "./Components/SearchButton";
import mixpanel from "mixpanel-browser";
import Tutorial from "./Tutorial";
import Header from "../../general/Navigation/Header";
import Sidebar from "../../general/Navigation/Sidebar";
import { GlobalStoreContext } from "../../context/globalStore";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ListenList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      items: [],
      displayItems: [],
      myVoiyals: [],
      active_item: {},
      tokens: 0,
      free: false,
      mainPicUrl: "",
      username: "",
      index: 0,
      size: 0,
      redirectUrl: "",
      newDrops: "0",
      hotvoice: false,
      showPopupBuy: false,
      showPopupShare: false,
      showPopupDrops: false,
      showPopupReport: false,
      dropsAmount: "",
      chkbox1: false,
      chkbox2: false,
      chkbox3: false,
      chkbox4: false,
      tutorial: false,
      tutStep1: false,
      time: 0,
      progressBar: 0,
      startTime: 0,
      shareUrl: "",
      profilePics: [],
    };
    this.playPreview = this.playPreview.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.increaseRating = this.increaseRating.bind(this);
    this.decreaseRating = this.decreaseRating.bind(this);
    this.showPopupBuy = this.showPopupBuy.bind(this);
    this.showPopupShare = this.showPopupShare.bind(this);
    this.showPopupReport = this.showPopupReport.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.clickPlay = this.clickPlay.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.setTutStep1 = this.setTutStep1.bind(this);
    this.filterAll = this.filterAll.bind(this);
    this.filterEnglish = this.filterEnglish.bind(this);
    this.filterGerman = this.filterGerman.bind(this);
    this.filterSpanish = this.filterSpanish.bind(this);
    this.filterFree = this.filterFree.bind(this);
    this.filterPaid = this.filterPaid.bind(this);
    this.filterAllPrices = this.filterAllPrices.bind(this);
    this.setShareUrl = this.setShareUrl.bind(this);

    this.audioPlayer = React.createRef();
  }
  static contextType = GlobalStoreContext;

  async updateRating() {
    this.setState({
      rating: 3 + 2 * Math.random(),
    });
  }

  async filterEnglish(value) {
    if (value) {
      let arrayFiltered = value.filter((item) => item.lng === "English");
      this.setState({
        displayItems: arrayFiltered,
      });
    } else {
      let arrayFiltered = this.state.items.filter((item) => item.lng === "Spanish");
      this.setState({
        displayItems: arrayFiltered,
      });
    }
  }

  async filterGerman(value) {
    if (value) {
      let arrayFiltered = value.filter((item) => item.lng === "Deutsch");
      this.setState({
        displayItems: arrayFiltered,
      });
    } else {
      let arrayFiltered = this.state.items.filter((item) => item.lng === "Spanish");
      this.setState({
        displayItems: arrayFiltered,
      });
    }
  }

  async filterSpanish(value) {
    if (value) {
      let arrayFiltered = value.filter((item) => item.lng === "Spanish");
      this.setState({
        displayItems: arrayFiltered,
      });
    } else {
      let arrayFiltered = this.state.items.filter((item) => item.lng === "Spanish");
      this.setState({
        displayItems: arrayFiltered,
      });
    }
  }

  async filterFree() {
    const { items = [] } = this.state;
    // eslint-disable-next-line no-unused-vars
    const tt = items.filter((item) => {
      let ttt = item.duration?.split(":")[0] === "0" && parseInt(item.duration?.split(":")[1]) < 30;
      return ttt;
    });
    return items.filter((item) => item.duration?.split(":")[0] === "0" && parseInt(item.duration?.split(":")[1]) < 30);
  }

  async filterAllPrices() {
    return this.state.items;
  }

  async filterPaid() {
    const { items = [] } = this.state;
    return items.filter((item) => item.duration?.split(":")[0] !== "0");
  }

  async filterAll(value) {
    if (value) {
      this.setState({
        displayItems: value,
      });
    } else {
      this.setState({
        displayItems: this.state.items,
      });
    }
  }

  async increaseRating() {
    this.setState({
      rating: this.state.rating + 0.2,
    });
  }

  async decreaseRating() {
    this.setState({
      rating: this.state.rating - 0.2,
    });
  }

  showPopupBuy(value) {
    this.setState({
      showPopupBuy: value,
    });
  }

  showPopupShare(value) {
    this.setState({
      showPopupShare: value,
    });
  }

  showPopupReport(value) {
    this.setState({
      showPopupReport: value,
    });
  }

  stopAudio() {
    this.audioPlayer.current.stopAudio();
  }

  closePopup(value) {
    this.setState({ showPopupDrops: value });
  }
  setTutStep1() {
    this.setState({ tutStep1: false });
  }

  setShareUrl(url) {
    this.setState({
      shareUrl: url,
    });
  }

  async componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const [globalStore, setGlobalStore, getHeaderData] = this.context;
    getHeaderData();
    getUser().then(async (redirect) => {
      await getUsername().then(async (name) => {
        this.setState({ username: name });
        await axios
          .get(process.env.REACT_APP_BACKEND_URL + "/showrules", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(async (res) => {
            if (res.data.filter((e) => e.username === name).length === 0) {
              this.setState({ redirectUrl: !name ? "/" : "/rules" });
              let newRules = {
                username: name,
              };
              await axios
                .post(process.env.REACT_APP_BACKEND_URL + "/showrules/add/", newRules, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  console.log("add rules show");
                });
              this.setState({ redirect: true });
            } else {
              this.setState({ redirect: redirect });
              await axios
                .get(process.env.REACT_APP_BACKEND_URL + "/tutorial", {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then(async (res) => {
                  console.log(res.data.filter((e) => e.username === name));
                  if (res.data.filter((e) => e.username === name).length === 0) {
                    let newTutorial = {
                      username: name,
                    };
                    this.setState({ tutorial: true, tutStep1: true });
                    await axios.post(process.env.REACT_APP_BACKEND_URL + "/tutorial/add/", newTutorial, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    });
                  }
                });
            }
          });
        if (!redirect && localStorage.getItem("token") != null) {
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/tokens/get/", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: name,
              },
            })
            .then((res) => {
              if (res.data) this.setState({ tokens: res.data });
              else console.log("token error");
            });
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/myvoiyals/all", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: name,
              },
            })
            .then((res) => {
              if (res.data[0]) this.setState({ myVoiyals: res.data[0].audios });
            });
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/audios/all", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(async (res) => {
              let array =  res.data.filter((item) => item.curated);
              array = res.data.filter((item) => !this.state.myVoiyals.includes(item._id));
             
              await axios
                .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                  params: {
                    username: this.state.username,
                  },
                })
                .then(async (response) => {
                  if (response.data.length > 0) this.setState({ blockedUsers: response.data[0].authors });
                  array = res.data.filter((item) => item.curated);
                  array = array.filter((item) => !response.data[0] || !response.data[0].authors.includes(item.author));
                  this.setState({
                    displayItems: [...array],
                    items: [...array],
                    size: array.length,
                  });
                  const values = this.props.location.pathname;
                  const id = values.substring(values.lastIndexOf("/") + 1);
                  if (id !== "listenlist") {
                    await axios
                      .get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudiopreview/" + id)
                      .then((res) => {
                        if (res.data.length > 0) {
                          let newArray = res.data.concat(array);
                          this.setState({
                            displayItems: [...newArray],
                          });
                        }
                      });
                  }
                });
              await axios
                .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
                  params: { username: this.state.displayItems[this.state.index].author },
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  this.setState({ profilePics: res.data });
                });
            });
          mixpanel.track("Screen_open_listen");
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/lastlogindrops", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: name,
              },
            })
            .then((res) => {
              if (res.data > 0)
                this.setState({
                  dropsAmount: res.data,
                  showPopupDrops: true,
                });
            });
        } else {
          this.setState({ redirect: true });
        }
        if (this.state.displayItems[this.state.index]) {
          await axios
            .get(
              process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.state.displayItems[this.state.index].author,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              this.setState({ hotvoice: res.data });
            });
        }
      });
    });
  }

  async next() {
    if (this.state.index + 1 < this.state.displayItems.length) {
      await this.stopAudio();
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.state.displayItems[this.state.index + 1].author,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({ hotvoice: res.data, time: 0, progressBar: 0 });
        });
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
          params: { username: this.state.displayItems[this.state.index + 1].author },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.setState({ profilePics: res.data });
        });
      this.setState({ index: this.state.index + 1 });
    } else {
      this.setState({ index: 0 });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
          params: { username: this.state.displayItems[0].author },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.setState({ profilePics: res.data });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.state.displayItems[0].author, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.setState({ hotvoice: res.data });
        });
    }
    this.updateRating();
    this.audioPlayer.current.resetRating();
    this.audioPlayer.current.displayRatingFalse();
    mixpanel.track("Click_next_button");
  }

  async previous() {
    if (this.state.index - 1 >= 0) {
      await this.stopAudio();
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.state.displayItems[this.state.index - 1].author,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({ hotvoice: res.data, time: 0, progressBar: 0 });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
          params: { username: this.state.displayItems[this.state.index - 1].author },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.setState({ profilePics: res.data });
        });
      this.setState({ index: this.state.index - 1 });
    } else {
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
          params: { username: this.state.displayItems[this.state.size - 1].author },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.setState({ profilePics: res.data });
        });
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.state.displayItems[this.state.size - 1].author,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({ hotvoice: res.data });
        });
      this.setState({ index: this.state.size - 1 });
    }
    this.updateRating();
    this.audioPlayer.current.resetRating();
    this.audioPlayer.current.displayRatingFalse();
    mixpanel.track("Click_back_button");
  }

  clickPlay() {
    this.showPopupBuy(true);
    mixpanel.track("Play_Story", {
      story: this.state.active_item.title,
    });
  }

  updateTime(time) {
    if (this.state.startTime === 0) {
      this.setState({ startTime: time.timeStamp });
    } else {
      let mili = parseInt(this.state.displayItems[this.state.index].duration.split(":")[1]);
      mili += parseInt(this.state.displayItems[this.state.index].duration.split(":")[0]) * 60;

      let value = mili * 1000;

      this.setState({
        time: time.timeStamp - this.state.startTime,

        progressBar: (time.timeStamp - this.state.startTime) / value,
      });
    }
  }

  setActiveItem = async (item) => {
    this.setState({ startTime: 0, time: 0, progressBar: 0 });
    let price = item.duration.split(":")[0];
    if (price === "0" && parseInt(item.duration.split(":")[1]) > 30) price = "1";
    if (item.sponsored) price = 0;
    this.audioPlayer.current.displayRatingTrue();
    if (item.author === this.state.username) alert("You cant buy your own story.");
    else {
      if (this.state.tokens >= price) {
        try {
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
              params: {
                username: item.author,
              },
            })
            .then((res) => {
              if (res.data.length > 0) this.setState({ mainPicUrl: res.data[0].fileUrl });
              else this.setState({ mainPicUrl: "" });
            });
          await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/myvoiyals/newvoiyal",
            {},
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: this.state.username,
                id: item._id,
              },
            }
          );
        } catch (e) {
          console.log(e);
        }
        await axios.get(process.env.REACT_APP_BACKEND_URL + "/tokens/deleteTokenAmount", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.state.username,
            amount: price,
          },
        });
        await axios
          .get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudio/" + item._id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            this.setState({ active_item: res.data[0] });
            const newTokens = this.state.tokens - price;
            this.setState({ tokens: newTokens });
            // eslint-disable-next-line no-unused-vars
            const [globalStore, setGlobalStore] = this.context;
            setGlobalStore({ tokens: newTokens });
          });
        try {
          await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/transactions/addtrans",
            {
              author: item.author,
              amount: price,
              free: this.state.free,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          );
        } catch (e) {
          console.log(e);
        }
        try {
          await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/transactions/addtranstospread",
            {},
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                name: item.author,
                amount: price,
              },
            }
          );
        } catch (e) {
          console.log(e);
        }
        await axios.get(process.env.REACT_APP_BACKEND_URL + "/cio/dropsEarned", {
          params: {
            author: item.author,
            id: this.state.username + Date.now(),
          },
        });
      } else {
        this.props.history.push("/buydrops");
      }
    }
  };

  async playPreview() {
    let player = document.getElementById("player");
    player.play();
    mixpanel.track("Play_preview");
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect ? (
      <Redirect to={{ pathname: this.state.redirectUrl }} />
    ) : (
      <div>
        {this.state.tutorial && <Tutorial step1={this.setTutStep1} />}
        {this.state.showPopupShare && (
          <PopupShare shareUrl={this.state.shareUrl} showPopupShare={this.showPopupShare} />
        )}
        {this.state.showPopupReport && (
          <PopupReport
            displayItems={this.state.displayItems}
            index={this.state.index}
            username={this.state.username}
            showPopupReport={this.showPopupReport}
          />
        )}
        {this.state.showPopupDrops && (
          <PopupDropsEarned closePopup={this.closePopup} dropsAmount={this.state.dropsAmount} />
        )}
        {this.state.showPopupBuy && (
          <PopupBuy
            showPopup={this.showPopupBuy}
            setActiveItem={this.setActiveItem}
            displayItems={this.state.displayItems}
            index={this.state.index}
          />
        )}
        <div className={this.state.tutStep1 ? classes.bodyt1 : classes.body }>
          <div>
            <div className={classes.listHeader}>
              <Filter
                filterAllPrices={this.filterAllPrices}
                filterFree={this.filterFree}
                filterPaid={this.filterPaid}
                filterAll={this.filterAll}
                filterEnglish={this.filterEnglish}
                filterSpanish={this.filterSpanish}
                filterGerman={this.filterGerman}
              />
              <TokensAmount tokens={this.state.tokens} />
              <SettingsButton style={{ paddingTop: 25 }} />
            </div>
            <Header
              filterAllPrices={this.filterAllPrices}
              filterFree={this.filterFree}
              filterPaid={this.filterPaid}
              filterAll={this.filterAll}
              filterEnglish={this.filterEnglish}
              filterSpanish={this.filterSpanish}
              filterGerman={this.filterGerman}
              tokenAmount={this.state.tokens}
            />

            {/* <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                marginTop: 10,
              }}
            >
              <img style={{ width: 120 }} src="https://d3bk8pb8wlf64b.cloudfront.net/Logo_GIF.gif" alt="loading..." />
            </div> */}
            <div className={classes.listHeader}>
              <AudioPlayerPreview displayItems={this.state.displayItems} index={this.state.index} />

              <SearchButton />
            </div>
          </div>
          <div className={classes.list}>
            {this.state.displayItems.length === 0 && (
              <span className={classes.textSmaller}>{t("listen.noAudios")}</span>
            )}
            {this.state.displayItems[this.state.index] && (
              <ListCard
                displayItems={this.state.displayItems}
                index={this.state.index}
                hotVoice={this.state.hotvoice}
                props={this.state.rating}
                setShareUrl={this.setShareUrl}
                showPopupShare={this.showPopupShare}
                showPopupReport={this.showPopupReport}
                playPreview={this.playPreview}
                next={this.next}
                previous={this.previous}
                clickPlay={this.clickPlay}
                stop={this.stopAudio}
                time={this.state.time}
                progressBar={this.state.progressBar}
                profilePics={this.state.profilePics}
              />
            )}
          </div>
          <AudioPlayer
            ref={this.audioPlayer}
            activeItem={this.state.active_item}
            setActiveItem={this.setActiveItem}
            increaseRating={this.increaseRating}
            decreaseRating={this.decreaseRating}
            updateTime={this.updateTime}
          />
          <Sidebar />
          {/* <NavBar /> */}
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    paddingBottom: 100,
    position: "relative",
    overflowX: "hidden",
    marginLeft: 0,
    marginRight: 20,

    width: "100%",
  },
  bodyt1: {
    paddingBottom: 100,
    position: "relative",
    overflowX: "hidden",
    marginLeft: 0,
    marginRight: 20,

    width: "100%",
  },
  listHeader: {
    display: "initial",
  },
  "@media screen and (max-width: 320px)": {
    body: {
      maxWidth: 300,
    },
  },
  list: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    verticalAlign: "middle",
  },
  textSmaller: {
    fontSize: 13,
    color: "rgba(255, 255, 255, .65)",
  },
  "@media screen and (min-width: 800px) and (min-width:700px)": {
    body: {
      marginTop: "210px",
      marginLeft: "300px",
      maxWidth: 800,
      overflow: "visible",
      width: "100%",
    },
    bodyt1: {
      marginTop: "300px",
      marginLeft: "300px",
      maxWidth: 800,
      overflow: "visible",
      width: "100%",
    },
    listHeader: {
      display: "none",
    },
    list: {
      width: "765px",
    },
  },
  "@media screen and (min-width: 800px) and (max-height: 650px)": {
    body: {
     marginTop: 120
    }
  }
};

const ListenListComponentTranslated = withTranslation("common")(ListenList);
export default injectSheet(styles)(ListenListComponentTranslated);
