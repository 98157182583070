import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BackButton from "../general/Navigation/components/BackButton";
import SettingsButton from "../general/SettingsButton";

class Rules extends Component {
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <div>
          <BackButton url={"/listenlist"} />
          <SettingsButton />
          <h6 className={classes.title} style={{ marginBottom: 20, marginTop: 25 }}>
            {t("rules.title")}
          </h6>
        </div>
        <div style={{ marginBottom: 15 }}>
          <span className={classes.textSmall} style={{ fontStyle: "italic" }}>
            {t("rules.text1")}
          </span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <div>
            <b className={classes.text}>{t("rules.title2")}</b>
          </div>
          <span className={classes.textSmall}>{t("rules.text2")}</span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <div>
            <b className={classes.text}>{t("rules.title3")}</b>
          </div>
          <span className={classes.textSmall}>{t("rules.text3")}</span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <div>
            <b className={classes.text}>{t("rules.title4")}</b>
          </div>
          <span className={classes.textSmall}>{t("rules.text4a")}</span>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              "https://docs.google.com/spreadsheets/d/13l_oGtiYwt4LwwWMK0paCvVnDw3Giew9LyfYk1Wieb4/edit?usp=sharing"
            }
            className={classes.link}
          >
            {t("rules.text4b")}
          </a>
          <span className={classes.textSmall}>{t("rules.text4c")}</span>
        </div>
        <div style={{}}>
          <span className={classes.text}>{t("rules.text5a")}</span>
          <a
            target="_blank"
            rel="noreferrer"
            href={"https://www.voiyalvoices.com/terms-of-service.html"}
            className={classes.link}
          >
            {t("rules.text5b")}
          </a>
        </div>
        <div style={{}}>
          <span className={classes.textSmall} style={{ fontStyle: "italic" }}>
            {t("rules.text7")}
          </span>
        </div>
        <div style={{ textAlign: "center", alignSelf: "center"}}>
          <Link to={"/listenlist"}>
            <span className={classes.submitButton}>{t("listen.next")}</span>
          </Link>
        </div>

        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .6)",
    fontSize: 14,
    fontWeight: 550,
  },
  title: {
    color: "rgba(255, 255, 255, 1)",
    fontWeight: 600,
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
  },
  submitButton: {
    marginTop: 30,
    marginBottom: 15,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .95)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    display: "block",
    borderRadius: 5,
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
  },
  body: {
    paddingBottom: 100,
    position: "relative",

    lineHeight: 1.1,
    overflowX: "hidden",
    marginLeft: 20,
    marginRight: 20,
    maxWidth: 450,
  },
  link: {
    textDecoration: "underline",
    color: "#00BFFF",
    fontSize: 14,
  },
  "@media screen and (max-width: 320px)": {
    body: {
      maxWidth: 300,
    },
  },
  "@media screen and (min-width: 800px)": {
    body: {
      margin: "0 auto",
      maxWidth: "75%",
    },
  },
};

const RulesTranslated = withTranslation("common")(Rules);
export default injectSheet(styles)(RulesTranslated);
