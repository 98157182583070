import React from "react";
import i18next from "i18next";
import { Globe } from "react-feather";
import { MenuItem } from "react-pro-sidebar";
const LanguageSwitch = () => {
  return (
    <div>
      <MenuItem>
        <div
          style={{ display: "flex" }}
          onClick={() => {
            i18next.changeLanguage("en");
            localStorage.setItem("i18next", "en");
          }}
        >
          <Globe style={{ paddingRight: "5px" }} />
          <div>English</div>
        </div>
      </MenuItem>
      <MenuItem>
        <div
          className="laguageButton"
          onClick={() => {
            i18next.changeLanguage("de");
            localStorage.setItem("i18next", "de");
          }}
        >
          <Globe style={{ paddingRight: "5px" }} />
          <div>Deutsch</div>
        </div>
      </MenuItem>
    </div>
  );
};

export default LanguageSwitch;
