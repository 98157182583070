import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { MoreVertical } from "react-feather";

import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class SettingsButton extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.settings}>
        <Link to="/settings">
          <MoreVertical
            style={{
              maxWidth: 50,
              color: "rgba(255, 255, 255, .85)",
            }}
          />
        </Link>
      </div>
    );
  }
}

const styles = {
  settings: {
    position: "absolute",
    right: "5px",
    top: "25px",
  },
  "@media screen and (min-width: 800px)": {
    settings: {
      display: "none",
    },
  },
};

const SettingsButtonComponentTranslated = withTranslation("common")(SettingsButton);
export default injectSheet(styles)(SettingsButtonComponentTranslated);
