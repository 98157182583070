import React, { Component } from "react";
import { displayDate } from "../context/helper";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { Play } from "react-feather";
import drops from "../assets/icons/drops.svg";

class ListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <li key={this.props.item.idx} className={this.props.item.hot ? classes.listCardHot : classes.listCard}>
         <div>
            <div style={{ position: "absolute", fontSize:"11px", lineHeight:"16px", display:"flex", gap:"5px" }} className={classes.textSmall}>   
              {this.props.item.duration}
              <div style={{
                width: "17px",
                backgroundColor: "#87C9FF",
                height: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                borderRadius: "50%",
                marginTop: "-1px",
              }}>
                <Play
                  onClick={async () => {
                    await this.play();
                  }}
                  style={{ width: 16, height: 14, fill: "white", marginLeft: "5px", marginRight:"3px" }}
                />
              </div>
            </div> 
        </div>
        <div className={classes.date}>
          <span className={classes.textSmaller}>{displayDate(this.props.item.date)}</span>
        </div>
        <a
          className={classes.noDefaultButton}
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
          }}
          href={window.location.origin + "/listenlist/" + this.props.item._id}
        >
          <b style={{marginTop:15, fontSize:"15px", fontWeight:600}}className={classes.text}>{this.props.item.title}</b>
          {this.props.withNames && (
            <div 
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              textAlign: "center",
              marginLeft: 6,
              marginTop: 7,
              lineHeight:"16px",           
            }}>
                <span className={classes.textSmaller}>{this.props.item.author}</span>
            </div>
         )}
          {this.props.item.hashtags && this.props.item.hashtags.length > 0 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                textAlign: "center",
                marginLeft: 6,
                marginTop: 7,
                lineHeight:"16px",           
              }}
            >
              {this.props.item.hashtags.slice(0, 3).map((hashtag) => (
                <p className={classes.textSmaller} style={{ marginTop: 10, marginLeft:"1px", marginRight:"3px" }}>
                  [{hashtag}]
                </p>
              ))}
              <p className={classes.textSmaller} style={{ marginTop: 10  }}>
                ...
              </p>
            </div>
          )}
          <div>
          <div className={classes.price}>
            {this.props.item.duration.split(":")[0] === "0" && parseInt(this.props.item.duration.split(":")[1]) < 30 ? (
              <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display:"flex" }}>
                <span className={classes.textSmaller} style={{ marginTop:"5px" }}>
                  FREE
                </span>
              </div>
            ) : (
              <div
                style={{   

                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  height:28,
                  marginBottom:"1px",
                  display:"flex"
                }}
              >
                <img
                  src={drops}
                  alt="drops"
                  style={{
                    color: "#87C9FF",
                    fill: "#87C9FF",
                    verticalAlign: "middle",
                    height: 12.3,
                    width: 9.2,
                    marginRight: 4,
                    marginBottom:"1px"
                  }}
                />
                <span
                  
                  style={{ fontSize: 15, fontWeight: 600, color: "white",marginBottom:"0px" }}
                >
                  {this.props.item.duration.split(":")[0] === "0" ? "1" : this.props.item.duration.split(":")[0]}
                </span>
              </div>
            )}
          </div>
          <div className={classes.iconPlay}>
            <Play
              onClick={async () => {
                await this.play();
              }}
              style={{ width: 15, height: 15, fill: "white", marginLeft: "3px" }}
            />
          </div>
          </div> 
        </a>
      </li>
    );
  }
}

const styles = {
  date: {
    position: "absolute",
    top: 5,
    right: 0,
    marginRight: 5,
    marginBottom: 5,
  },
  listCard: {
    display: "inline-block",
    width: "90%",
    marginRight: "2%",
    marginLeft: "2%",
    height: 160,
    marginBottom: 20,
    padding: 8,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    borderRadius: 10,
    position: "relative",
    "&:hover": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
    "&:active": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
  },
  listCardHot: {
    display: "inline-block",
    width: "90%",
    height: 160,
    marginRight: "2%",
    marginLeft: "2%",
    marginBottom: 20,
    padding: 8,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    borderRadius: 10,
    position: "relative",
    boxShadow: "0px 0px 2rem #C59CDC",
    "&:hover": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
    "&:active": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
  },
  textSmaller: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 11,
    textAlign: "center",
    lineHeight:"16px",
    fontWeight:500
  },
  textSmall: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 14,
    fontWeight: 600,
    lineHeight:"16px"
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
    marginLeft: "6px",
    lineHeight:"22px"
  },
  price: {
    left: 5,
    borderWidth: 1,
    bottom: 5,
    textAlign: "center",
    position: "absolute",
    borderColor: "#87C9FF",
    borderStyle: "solid",
    borderRadius: 5,
    height: 26,
  },
  iconPlay: {
    display: "none",
  },
  "@media screen and (min-width: 800px)": {
    listCard: {
      width: "46%",
      height: 160,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 25,
    },
    price: {
      left: 15,
      bottom: 15,
      width: 53,
      height: 26,
    },
    date: {
      top: 12,
      right: 15,
    },
    textSmall: {
      top: 20,
      left: 15,
    },
    iconPlay: {
      width: "28px",
      backgroundColor: "#87C9FF",
      height: "28px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      bottom: 15,
      right: 15,
      color: "white",
      borderRadius: "50%",
    },
  },
};

const ListCardTranslated = withTranslation("common")(ListCard);
export default injectSheet(styles)(ListCardTranslated);
