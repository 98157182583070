import React, { Component } from "react";
import { getUser, getUsername } from "../../../context/auth";
import { withTranslation } from "react-i18next";
import injectSheet from "react-jss";
import visa from "../../../assets/images/visa_mastercard_logo_small.png";
import masterCard from "../../../assets/images/american_express_logo_small.png";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import BackButton from "../../../general/Navigation/components/BackButton";
import Sidebar from "../../../general/Navigation/Sidebar";
import Header from "../../../general/Navigation/Header";
import { Redirect } from "react-router-dom";
import Checkout from "../../Profile/Views/Checkout";
import drops from "../../../assets/icons/drops.svg";
import axios from "axios";

const crypto = require("crypto");
const createHmac = crypto.createHmac;
const secret = "5CCGFe3KN8zZQtrvJaQPsWLc";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class BuyDrops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      chkbox1: false,
      chkbox2: true,
      chkbox3: false,
      chkbox4: false,
      chkbox5: false,
      amount: 9.99,
      storyAmount: 10,
      checkout: false,
      redirect: false,
      redirectUrl: "/",
    };
    this.goBack = this.goBack.bind(this);
    this.updatehash = this.updatehash.bind(this);
    this.getCCBillUrl = this.getCCBillUrl.bind(this);
  }

  async componentDidMount() {
    getUser().then((redirect) => {
      this.setState({ redirect: redirect });
    });
    getUsername().then((name) => {
      this.setState({ username: name });
    });
    mixpanel.track("Screen_open_buy_drops");
  }

  getCCBillUrl() {
    const { amount } = this.state;
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/ccbill/generate-url", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          amount,
        },
      })
      .then((res) => {
        if (!res?.data) {
          alert("Error opening CC Bill Form");
          return;
        }
        document.location.replace(res.data);
      });
  }

  goBack() {
    this.setState({ checkout: false });
  }
  updatehash() {
    const hash = createHmac("md5", secret).update(`${this.state.amount}${0}${840}${secret}`).digest("hex");

    return hash;
  }
  render() {
    const { t } = this.props;
    const { classes } = this.props;

    return this.state.redirect ? (
      <Redirect to={{ pathname: this.state.redirectUrl }} />
    ) : this.state.checkout ? (
      <Checkout price={this.state.amount} amount={this.state.storyAmount} goBack={() => this.goBack()} />
    ) : (
      <div className={classes.body}>
        <BackButton url={"/profile"} />
        <div className="noHeader">
          <Header />
        </div>
        <SettingsButton />
        <div>
          <div className={classes.headerText}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("buydrops.title")}</span>
            </div>
            <div>
              <span className={classes.textSmall}>{t("profile.addToken")}</span>
            </div>
            <div style={{ marginTop: 10 }}>
              <span className={classes.textSmaller}>{t("profile.buyText")}</span>
            </div>
          </div>
          <div className={classes.plans}>
            <div
              style={{
                textAlign: "left",
                marginLeft: 30,
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  marginTop: 25,
                  verticalAlign: "middle",
                  justify: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="c1"
                  checked={this.state.chkbox1}
                  onChange={() => {
                    this.setState({
                      chkbox1: true,
                      chkbox2: false,
                      chkbox3: false,
                      chkbox4: false,
                      amount: 5.99,
                      storyAmount: 5,
                    });
                    mixpanel.track("Choose_5_drops");
                  }}
                />
                <label htmlFor="c1" className="textSmall">
                  <span></span>
                </label>
                <span className={classes.label} style={{ fontSize: 18 }}>
                  {t("pricing.1")}
                  {t("pricing.1a")}
                  <img
                    src={drops}
                    alt=""
                    className="material-icons"
                    style={{
                      width: 13,
                      marginLeft: 2,
                      marginTop: -2,
                      color: "#87C9FF",
                      verticalAlign: "middle",
                    }}
                  />
                </span>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  marginTop: 15,
                  verticalAlign: "middle",
                  justify: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="c2"
                  checked={this.state.chkbox2}
                  onChange={() => {
                    this.setState({
                      chkbox1: false,
                      chkbox2: true,
                      chkbox3: false,
                      chkbox4: false,
                      amount: 9.99,
                      storyAmount: 10,
                    });
                    mixpanel.track("Choose_10_drops");
                  }}
                />
                <label htmlFor="c2" className="textSmall">
                  <span></span>
                </label>
                <span className={classes.label} style={{ fontSize: 18 }}>
                  {t("pricing.2")}
                  {t("pricing.2a")}
                  <img
                    src={drops}
                    alt=""
                    className="material-icons"
                    style={{
                      width: 13,
                      marginLeft: 2,
                      marginTop: -2,
                      color: "#87C9FF",
                      verticalAlign: "middle",
                    }}
                  />
                </span>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  marginTop: 15,
                  verticalAlign: "middle",
                  justify: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="c3"
                  checked={this.state.chkbox3}
                  onChange={() => {
                    this.setState({
                      chkbox1: false,
                      chkbox2: false,
                      chkbox3: true,
                      chkbox4: false,
                      amount: 25.99,
                      storyAmount: 30,
                    });
                    mixpanel.track("Choose_30_drops");
                  }}
                />
                <label htmlFor="c3" className="textSmall">
                  <span></span>
                </label>
                <span className={classes.label} style={{ fontSize: 18 }}>
                  {t("pricing.3")}
                  {t("pricing.3a")}
                  <img
                    src={drops}
                    alt=""
                    className="material-icons"
                    style={{
                      width: 13,
                      marginLeft: 2,
                      marginTop: -2,
                      color: "#87C9FF",
                      verticalAlign: "middle",
                    }}
                  />
                </span>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  marginTop: 15,
                  marginBottom: 25,
                  verticalAlign: "middle",
                  justify: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="c4"
                  checked={this.state.chkbox4}
                  onChange={() => {
                    this.setState({
                      chkbox1: false,
                      chkbox2: false,
                      chkbox3: false,
                      chkbox4: true,
                      amount: 74.99,
                      storyAmount: 100,
                    });
                    mixpanel.track("Choose_100_drops");
                  }}
                />
                <label htmlFor="c4" className="textSmall">
                  <span></span>
                </label>
                <span className={classes.label} style={{ fontSize: 18 }}>
                  {t("pricing.4")}
                  {t("pricing.4a")}
                  <img
                    src={drops}
                    alt=""
                    className="material-icons"
                    style={{
                      width: 13,
                      marginLeft: 2,
                      marginTop: -2,
                      color: "#87C9FF",
                      verticalAlign: "middle",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={this.getCCBillUrl}
              className={classes.submitButton}
              rel="noreferrer"
            >
              {t("buydrops.checkout")}
            </div>
          </div>
          <div className={classes.cardFooter}>
            <div className={classes.verticalLine} />
            <span className={classes.textSmaller} style={{ color: "rgba(255, 255, 255, .45)" }}>
              {t("buydrops.tax")}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "center",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <img alt={"cover"} className={classes.cardLogo} style={{ opacity: 0.8 }} src={visa} />
              <img alt={"cover"} className={classes.cardLogoMaster} style={{ opacity: 0.8 }} src={masterCard} />
            </div>
          </div>
        </div>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    maxWidth: "450px",
    textAlign: "center",
    paddingBottom: "15px",
  },
  cardLogo: {
    width: 120,
    height: 25,
    marginTop: 15,
  },
  cardFooters: {
    position: "fixed",
    bottom: 80,
    width: "90%",
    maxWidth: 350,
  },
  backButtonIcon: {
    width: 12,
    height: 20,
    marginTop: 10,
    position: "absolute",
  },
  cardLogoMaster: {
    width: 40,
    marginLeft: 20,
    marginTop: 7,
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: "white",
  },
  textSmall: {
    color: "rgba(116, 107, 131, 1)",
    fontSize: 15,
    fontWeight: 600,
  },
  textSmaller: {
    color: "rgba(255, 255, 255, 0.5)",
    fontSize: 11,
  },
  headerText: {
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    maxWidth: 350,
    textAlign: "center",
  },
  label: {
    color: "white",
    fontSize: 15,
    fontWeight: 500,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  verticalLine: {
    borderTopColor: "rgba(255, 255, 255, .2)",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  plans: {
    marginBottom: "20px",
    display: "flex",
    maxWidth: "350px",
    flexDirection: "column",
  },
  "@media screen and (min-width: 800px)": {
    body: {
      top: "100px",
      left: "50%",
      margin: " 0px -93px",
      maxWidth: "512px",
    },
    submitButton: {
      width: "calc(100% - 160px)",
      marginBottom: "50px",
    },
    plans: {
      margin: "auto",
      display: "flex",
      maxWidth: "412px",
      flexDirection: "column",
      backgroundColor: "#180931",
      border: "1px solid rgba(255, 255, 255, .25)",
      boxShadow: "0px 0px 10px #C59CDC",
      borderRadius: "20px",
      marginBottom: "150px",
    },
    headerText: {
      maxWidth: "512px",
      paddingBottom: 35,
    },
    title: {
      marginTop: 25,
      marginBottom: 15,
      fontSize: 18,
      fontWeight: 600,
      color: "white",
    },
    textSmall: {
      color: "rgba(116, 107, 131, 1)",
      fontSize: "17px",
      lineHeight: "27px",
      fontWeight: 600,
    },
    textSmaller: {
      color: "rgba(255, 255, 255, 0.5)",
      fontSize: "15px",
      lineHeight: "22.5px",
    },
    cardFooters: {
      position: "inherit",
      width: "100%",
      maxWidth: "512px",
    },
    verticalLine: {
      display: "none",
    },
  },
};

const BuyDropsComponentTranslated = withTranslation("common")(BuyDrops);
export default injectSheet(styles)(BuyDropsComponentTranslated);
