import React, { Component } from "react";
import injectSheet from "react-jss";
import Step1 from "./register/step1";
import Step2 from "./register/step2";
import Step3 from "./register/step3";
import Confirm from "./register/confirm";
import { Auth } from "aws-amplify";
import axios from "axios";

import mixpanel from "mixpanel-browser";
import Footer from "../../general/Navigation/Footer";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      email: "",
      username: "",
      password: "",
    };
    this.setStep = this.setStep.bind(this);
    this.signUp = this.signUp.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  componentDidMount() {
    mixpanel.track("Screen_open_register");
  }

  async setStep(step) {
    this.setState({
      step: step,
    });
  }

  async setEmail(value) {
    this.setState({
      email: value,
    });
  }

  async setUsername(value) {
    this.setState({
      username: value,
    });
  }

  async setPassword(value) {
    this.setState({
      password: value,
    });
  }

  async signUp() {
    try {
      const { user } = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          name: this.state.username,
        },
      });
      console.log(user);
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/tokens/create", {
          username: this.state.username,
        })
        .then((res, error) => {
          if (error) {
            console.log(error);
          } else {
            axios.get(process.env.REACT_APP_BACKEND_URL + "/cio/add", {
              params: {
                email: this.state.email,
                name: this.state.username,
                id: this.state.username,
              },
            });
            axios.post(process.env.REACT_APP_BACKEND_URL + "/username/add/", {
              username: this.state.username,
            });
            axios.post(process.env.REACT_APP_BACKEND_URL + "/emails/add", {
              email: this.state.email,
            });
            console.log(res.data);
          }
        });
    } catch (e) {
      console.log("Error in sign up process", e);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.body}>
          {this.state.step === 1 && <Step1 step={this.state.step} setEmail={this.setEmail} setStep={this.setStep} />}
          {this.state.step === 0 && (
            <Step2 step={this.state.step} setUsername={this.setUsername} setStep={this.setStep} />
          )}
          {this.state.step === 2 && (
            <Step3 step={this.state.step} setPassword={this.setPassword} signUp={this.signUp} setStep={this.setStep} />
          )}
          {this.state.step === 3 && <Confirm />}
        </div>
        <Footer />
      </div>
    );
  }
}

const styles = {
  body: {
    paddingBottom: 100,
    position: "relative",
    overflowX: "hidden",
    marginLeft: 20,
    marginRight: 20,
    // maxWidth: 450,
  },
  "@media screen and (max-width: 320px)": {
    body: {
      maxWidth: 300,
    },
  },
  "@media screen and (min-width: 800px)": {
    body: {
      width: "512px",
      position: "absolute",
      margin: " 0px -256px",
      top: "97px",
      left: "50%",
    },
  },
};

export default injectSheet(styles)(Register);
