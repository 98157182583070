import React from "react";
import ReactDOM from "react-dom";

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDeleteModal: false,
    };
  }
  toggleModal = () => {
    this.setState({
      openDeleteModal: !this.state.openDeleteModal,
    });
  };
  /* Close modal when clickin outside */
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.pop) {
      const domNode = ReactDOM.findDOMNode(this.pop);
      if (domNode.contains(e.target)) {
        return;
      }
    }
    this.handleClickOutside();
  };

  handleClickOutside = (e) => {
    this.props.onClose();
  };

  render() {
    // gray background
    const backdropStyle = {
      textAlign: "center",
      alignItems: "center",
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      zIndex: 100,
      color: "white",
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      backgroundColor: "rgba(0,0,0, 0.5)",
    };

    // The modal "window"
    const modalStyle = {
      borderRadius: 5,
      maxWidth: 500,
      minHeight: 300,
      padding: 30,
      textAlign: "center",
      alignItems: "center",
      position: "fixed",
      width: "100%",
      height: "30%",
      top: "20%",
      zIndex: 100,
      color: "white",
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      backgroundColor: "rgba(0,0,0, 0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    };

    return (
      <div style={backdropStyle}>
        <div
          style={modalStyle}
          ref={(node) => {
            this.pop = node;
          }}
        >
          {this.props.children}
          <div className="modalButtons">
            <button className="modalButton" id="deleteButton" onClick={() => this.props.onClose()}>
              Delete
            </button>
            <button className="modalButton" id="cancelButton" onClick={() => this.props.onClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmModal;
