import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../general/Navigation/Footer";
import { getUsername } from "../../context/auth";

import { Experiment, Variant, emitter } from "@marvelapp/react-ab-test";
import lips from "../../assets/images/lips.jpeg";
import meme from "../../assets/images/whisper_meme.jpeg";
import mixpanel from "mixpanel-browser";
import Header from "../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

emitter.addWinListener(function (experimentName, variantName) {
  mixpanel.track("Experiment", {
    name: experimentName,
    variant: variantName,
  });
});

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: "",
      title: "",
      description: "",
      id: "",
      pathName: "",
      urlAudio: "",
    };
    this.timeUpdate = this.timeUpdate.bind(this);
  }

  async componentDidMount() {
    const values = this.props.location.pathname;
    const id = values.substring(values.lastIndexOf("/") + 1);
    await getUsername().then(async (name) => {
      if (name) this.setState({ redirect: true, pathname: "/listenlist/" + id });
    });
    axios.get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudiopreview/" + id).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          author: res.data[0].author,
          title: res.data[0].title,
          description: res.data[0].description,
          id: id,
          urlAudio: res.data[0].url,
        });
      }
    });
    mixpanel.track("Screen_open_audio_preview_pornhublink");
  }

  timeUpdate() {
    let player = document.getElementById("player");
    if (player.currentTime > 31) {
      player.pause();
      player.currentTime = 0;
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect ? (
      <Redirect
        to={{
          pathname: this.state.pathname,
          state: { username: this.state.name },
        }}
      />
    ) : (
      <div className={classes.body}>
        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 50 }}>
          <Header />
          {/* <Link to={"/"}>
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                marginTop: 10,
              }}
            >
              <img style={{ width: 120 }} src="https://d3bk8pb8wlf64b.cloudfront.net/Logo_GIF.gif" alt="loading..." />
            </div>
          </Link> */}
          <div className={classes.card}>
            <div
              style={{
                marginTop: 50,
                textAlign: "center",
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <span className={classes.text} style={{ marginBottom: 10 }}>
                {t("previewNew.header")}
              </span>
            </div>
            <Experiment name="previewPageCTAExperiment">
              <Variant name="control1">
                <div></div>
              </Variant>
              <Variant name="lips">
                <div
                  style={{
                    marginTop: 5,
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  <img alt="lips" style={{ maxWidth: 200, borderRadius: 10 }} src={lips} />
                </div>
              </Variant>
              <Variant name="control">
                <div
                  style={{
                    marginTop: 5,
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  <img alt="meme" style={{ maxWidth: 150, borderRadius: 10 }} src={meme} />
                </div>
              </Variant>
            </Experiment>
            <div style={{ textAlign: "center", marginTop: 25 }}>
              <div>
                <b className={classes.textSmall}>{this.state.title}</b>
              </div>
              <span className={classes.textSmall} style={{ opacity: 0.8 }}>
                {" "}
                by {this.state.author}
              </span>
            </div>
            <div
              style={{
                textAlign: "center",
                alignSelf: "center",
                justifyContent: "center",
                marginTop: 15,
              }}
            >
              <audio
                id="player"
                controls
                controlsList={"nodownload"}
                autoPlay={true}
                className={classes.audioPlayer}
                onTimeUpdate={this.timeUpdate}
                src={this.state.urlAudio}
              />
            </div>
            <div className={classes.buttons}>
              <Link
                to="/register"
                class="submitButton"
                onClick={() => emitter.emitWin("previewPageCTAExperiment")}
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  verticalAlign: "middle",
                  marginBottom: 10,
                }}
              >
                <span
                  className="material-icons"
                  style={{
                    color: "#180931",
                    verticalAlign: "middle",
                    marginRight: 5,
                  }}
                >
                  &#xe038;
                </span>
                <span>{t("previewNew.register")}</span>
              </Link>
              <span className={classes.textSmaller}>{t("preview.or")}</span>
              <Link
                to="/"
                className={classes.textSmaller}
                style={{
                  textDecoration: "underline",
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                {t("previewNew.login")}
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const styles = {
  card: {
    zIndex: 20,
    display: "inline-block",
    width: 320,
    margin: 15,
    height: 430,
    padding: 5,
    textAlign: "left",
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 15,
    position: "relative",
    boxShadow: "0px 0px 8px 3px #c59cdc",
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 18,
  },
  date: {
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: 5,
    marginTop: 7,
  },
  buttons: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 16,
    textAlign: "center",
  },
  textSmaller: {
    fontSize: 13,
    color: "rgba(255, 255, 255, .65)",
  },
  body: {
    marginBottom: 100,
    paddingTop: 20,
  },
  playButtonTop: {
    width: 30,
    height: 30,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  listCard: {
    display: "inline-block",
    width: 320,
    margin: 15,
    height: 250,
    padding: 5,
    textAlign: "left",
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 5,
    position: "relative",
  },
  registerButton: {
    fontFamily: "Poppins",
    padding: 5,
    paddingLeft: 12,
    paddingRight: 12,
    alignSelf: "center",
    background: "rgba(135, 201, 255, .85)",
    color: "#180931",
    fontWeight: "bold",
    fontSize: 18,
    borderRadius: 5,
    marginBottom: 25,
    verticalAlign: "middle",
  },
  list: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    verticalAlign: "middle",
  },
  textToken: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 32,
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    verticalAlign: "middle",
  },
  mainPic: {
    width: 120,
    height: 120,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 25,
  },
  audioListCardPic: {
    width: 70,
    height: 70,
    margin: 10,
    borderRadius: 5,
    position: "absolute",
    bottom: 20,
    right: 0,
    marginRight: 5,
    marginBottom: 7,
  },
  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: "120px",
      maxWidth: 800,
      width: "100%",
    },
    card: {
      width: 500,
      height: 280,
    },
    buttons: {
      marginTop: 150,
    },
    registerButton: {
      width: "50%",
    },
  },
  "@media screen and (min-width: 1111px)": {
    card: {
      width: "765px",
      maxWidth: "765px",
    },
  },
};

const PreviewComponentTranslated = withTranslation("common")(Preview);
export default injectSheet(styles)(PreviewComponentTranslated);
