import { createContext, useEffect, useState } from "react";
import { getUsername } from "./auth";
import { checkHotVoice, checkIfVerified, getProfilePic, getTokens, getTwitter } from "./api";

//create a context, with createContext api
export const GlobalStoreContext = createContext({});

const GlobalStoreProvider = (props) => {
  // this state will be shared with all components
  const [store, setStore] = useState({});
  useEffect(() => {
    getHeaderData().then();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHeaderData = async () => {
    const username = await getUsername();
    const isVerified = await checkIfVerified();
    const tokens = await getTokens(username);
    const isHotVoice = await checkHotVoice(username);
    const { picUrl = "", picName = "" } = await getProfilePic(username);
    const twitter = await getTwitter(username);
    setGlobalStore({ username, tokens, isHotVoice, isVerified, picUrl, picName, twitter });
  };

  const setGlobalStore = (value) => {
    setStore({ ...store, ...value });
  };

  return (
    // this is the provider providing state
    <GlobalStoreContext.Provider value={[store, setGlobalStore, getHeaderData]}>
      {props.children}
    </GlobalStoreContext.Provider>
  );
};

export default GlobalStoreProvider;
