import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import axios from "axios";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class PopupReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
    };
    this.report = this.report.bind(this);
  }

  async report() {
    await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/report/add",
      {
        user: this.props.username,
        author: this.props.displayItems[this.props.index].author,
        title: this.props.displayItems[this.props.index].title,
        reason: this.state.reason,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  }

  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.popup}>
        <div className={classes.popupInner} style={{ bottom: "22%" }}>
          <h4
            style={{
              marginBottom: 5,
              fontSize: 16,
            }}
          >
            {t("donationpopup.report1")}
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <span className={classes.textSmall}>{t("donationpopup.report2")}</span>
          </div>
          <div
            style={{
              textAlign: "left",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: 370,
            }}
          >
            <div style={{marginTop: 4}}>
              <input
                type="checkbox"
                id="c1"
                checked={this.state.chkbox1}
                onChange={() => {
                  this.setState({
                    chkbox1: true,
                    chkbox2: false,
                    chkbox3: false,
                    chkbox4: false,
                    reason: "reason1",
                  });
                }}
              />
              <label htmlFor="c1" className="textSmall">
                <span></span>
              </label>
              <span className="textSmall">
                {t("donationpopup.reason1")}
                <Link style={{ textDecoration: "underline" }} to={"/rules"}>
                  Guidelines.
                </Link>
              </span>
            </div>
            <div style={{marginTop: 4}}>
              <input
                type="checkbox"
                id="c2"
                checked={this.state.chkbox2}
                onChange={() => {
                  this.setState({
                    chkbox1: false,
                    chkbox2: true,
                    chkbox3: false,
                    chkbox4: false,
                    reason: "reason2",
                  });
                }}
              />
              <label htmlFor="c2" className="textSmall">
                <span></span>
              </label>
              <span className="textSmall">{t("donationpopup.reason2")}</span>
            </div>
            <div style={{marginTop: 4}}>
              <input
                type="checkbox"
                id="c3"
                checked={this.state.chkbox3}
                onChange={() => {
                  this.setState({
                    chkbox1: false,
                    chkbox2: false,
                    chkbox3: true,
                    chkbox4: false,
                    reason: "reason3",
                  });
                }}
              />
              <label htmlFor="c3" className="textSmall">
                <span></span>
              </label>
              <span className="textSmall">{t("donationpopup.reason3")}</span>
            </div>
            <div style={{marginTop: 4}}>
              <input
                type="checkbox"
                id="c4"
                checked={this.state.chkbox4}
                onChange={() => {
                  this.setState({
                    chkbox1: false,
                    chkbox2: false,
                    chkbox3: false,
                    chkbox4: true,
                    reason: "reason4",
                  });
                }}
              />
              <label htmlFor="c4" className="textSmall">
                <span></span>
              </label>
              <span className="textSmall">{t("donationpopup.reason4")}</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              aria-label="Submit report"
              className="submitButton"
              style={{
                margin: 0,
                marginTop: 30,
                fontSize: 13,
              }}
              onClick={async () => {
                this.props.showPopupReport(false);
                await this.report();
                mixpanel.track("Click_report", {
                  reason: this.state.reason,
                });
                await axios
                  .post(
                    process.env.REACT_APP_BACKEND_URL + "/report/addUser",
                    {},
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                      params: {
                        username: this.props.username,
                        author: this.props.displayItems[this.props.index].author
                      },
                    }
                  )}
              }
            >
              {t("donationpopup.report")}
            </button>
            <button
              aria-label="Cancle report"
              className="submitButton"
              style={{
                opacity: 0.7,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 15,
              }}
              onClick={() => this.props.showPopupReport(false)}
            >
              {t("listen.popUpCancel")}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

const styles = {
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "110%",
    top: 0,
    zIndex: 100,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    position: "absolute",
    borderRadius: 20,
    left: "10%",
    zIndex: 102,
    right: "10%",
    top: "10%",
    bottom: "40%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    background: "#180931",
  },
  "@media screen and (min-width: 800px)": {
    popup: {
      top: "100px",
      width: "100%",
    },
    popupInner: {
      width: "30%",
      maxWidth: "511px",
      maxHeight: "447px",
      padding: "20px",
      marginLeft: "35%",
      minWidth: "350px",
    },
  },
};

const PopupReportTranslated = withTranslation("common")(PopupReport);
export default injectSheet(styles)(PopupReportTranslated);
