import React, { Component } from "react";
import "./payment.css";

export class Card extends Component {
  render() {
    return (
      <div
        className={`noHeader card ${this.props.processing ? "processing" : ""} ${
          this.props.complete ? "complete" : ""
        }`}
      >
        <div className={`back ${this.props.flipped ? "active" : ""}`}>
          <div className="decoration">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="band"></div>
          <div className="cvv">
            {this.props.fields.cvv.length > 0 ? (
              <span>{this.props.fields.cvv}</span>
            ) : (
              <span className="default">123</span>
            )}
          </div>
        </div>
        <div className={`front ${this.props.flipped ? "" : "active"}`}>
          <div className="decoration">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="type">YOUR CARD</div>
          <div className="chip">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="row info">
            <div>
              <div className="row number">
                {this.props.fields.number.length > 0 ? (
                  <span>{this.props.fields.number}</span>
                ) : (
                  <span className="default">XXXX XXXX XXXX XXXX</span>
                )}
              </div>
              <div className="row expiration">
                <div className="label">VALID THRU</div>
                <div className="cdate">
                  {this.props.fields.month.length > 0 ? (
                    <span>{this.props.fields.month}</span>
                  ) : (
                    <span className="default">MM</span>
                  )}
                  {this.props.fields.month.length > 0 && this.props.fields.year.length > 0 ? (
                    <span>/</span>
                  ) : (
                    <span className="default">/</span>
                  )}
                  {this.props.fields.year.length > 0 ? (
                    <span>{this.props.fields.year}</span>
                  ) : (
                    <span className="default">YY</span>
                  )}
                </div>
              </div>
              <div className="row name">
                {this.props.fields.name.length > 0 ? (
                  <span>{this.props.fields.name}</span>
                ) : (
                  <span className="default">NAME SURNAME</span>
                )}
              </div>
            </div>
            <div className="flag">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
