import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import AudioList from "./AudioList";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import BackButton from "../../../general/Navigation/components/BackButton";
import Sidebar from "../../../general/Navigation/Sidebar";
import Header from "../../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class MyStories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
  }

  componentDidMount() {
    mixpanel.track("Screen_open_my_uploads");
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <Header />
        <SettingsButton />
        <BackButton  url={"/profile"} />
        <div>
          <div
            style={{
              justifyContent: "center",
              verticalAlign: "middle",
              alignItems: "center",
              margin: "auto",
              maxWidth: 350,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("yourAudios.yourAudios")}</span>
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <AudioList />
        </div>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    maxWidth: 450,
    paddingBottom: 100,
    margin: "auto",
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 22,
    fontWeight: '600',
    color: "white",
    textAlign: "center",
  },
  backButtonIcon: {
    width: 12,
    height: 20,
    margin: 10,
    position: "absolute",
    left: 5,
    top: 25,
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 18,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 14,
    fontWeight: '600',
    textAlign: "center",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgba(255, 255, 255, .65)",
    marginBottom: 20,
  },
  list: {
    width: 320,
    marginRight: 40,
    marginLeft: 0,
  },
  listElement: {
    "&:hover": {
      color: "#C59CDC",
    },
    color: "rgba(255, 255, 255, .65)",
    fontSize: 22,
    listStyleType: "none",
    marginBottom: 10,
  },
  noDefaultButton: {
    margin: 0,
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
  listElementSmall: {
    "&:hover": {
      color: "#C59CDC",
    },
    listStyleType: "none",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
  "@media screen and (min-width: 800px)": {
    body: {
      // marginTop: '-80px',
      marginTop: "130px",
      marginLeft: "300px",
      width: "500px",
      maxWidth: "500px",
    },
    "@media screen and (min-width: 1060px)": {
      startButton: {
        width: "750px",
      },
      body: {
        width: "765px",
        maxWidth: "765px",
      },
    },
  },
};

const MyStoriesTranslated = withTranslation("common")(MyStories);
export default injectSheet(styles)(MyStoriesTranslated);
