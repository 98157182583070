import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Droplet } from "react-feather";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class TokensAmount extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Link to={"/buydrops"}>
        <div className={classes.border}>
          <Droplet
            style={{
              color: "#87C9FF",
              fill: "#87C9FF",
              verticalAlign: "middle",
              width: 18,
              margin: 3,
            }}
          />
          <b
            className={classes.text}
            style={{
              verticalAlign: "middle",
              fontWeight: 600,
            }}
          >
            {this.props.tokens}
          </b>
        </div>
      </Link>
    );
  }
}

const styles = {
  border: {
    position: "absolute",
    right: "30px",
    top: "22px",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#87C9FF",
    borderRadius: 8,
    maxWidth: 80,
    textAlign: "center",
    alignItems: "center",
    margin: "auto",
    paddingLeft: 5,
    paddingRight: 8,
    display: "flex",
    flexDirection: "row",
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 500,
  },
};

const TokensAmountComponentTranslated = withTranslation("common")(TokensAmount);
export default injectSheet(styles)(TokensAmountComponentTranslated);
