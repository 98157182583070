import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import ChatHeader from "./ChatHeader";
import NewMessageForm from "./NewMessageForm";
import axios from "axios";
import NewChatForm from "./NewChatForm";
import { displayDateMessage } from "../../../context/helper";
import background from "../../../assets/images/Logo_V_round_final.png";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ChatFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      notSet: true,
      showList: false,
    };
    this.setShowList = this.setShowList.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/chatengine/getmessages", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          messageid: nextProps.chatAppState.activeChat,
        },
      })
      .then(async (res) => {
        this.setState({
          messages: res.data,
        });
      });
    let element = document.getElementById("list");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  setShowList() {
    this.setState({ showList: !this.state.showList });
  }

  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div
        className={classes.body}
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "60% ",
          backgroundRepeat: "no-repeat",
          maxHeight: "760px",
        }}
      >
        {/* <img className={classes.backgroundImage} src={background} alt={"background"} /> */}

        {this.props.chatAppState.chats &&
        this.props.chatAppState.chats[this.props.chatAppState.activeChat] &&
        this.props.chatAppState.chats[this.props.chatAppState.activeChat].people.length > 1 &&
        !this.props.blockedUsers.includes(
          this.props.chatAppState.chats[this.props.chatAppState.activeChat].people[0].person.username
        ) &&
        !this.props.blockedUsers.includes(
          this.props.chatAppState.chats[this.props.chatAppState.activeChat].people[1].person.username
        ) ? (
          <div className="feedLength">
            <ChatHeader
              setShowList={this.setShowList}
              showList={this.state.showList}
              creators={this.props.creators}
              chatAppState={this.props.chatAppState}
              chat={this.props.chatAppState.chats[this.props.chatAppState.activeChat]}
              username={this.props.username}
              blockedUsers={this.props.blockedUsers}
              getBlocks={this.props.getBlocks}
            />
            {this.state.messages.length > 0 && !this.state.showList ? (
              <div id="list" className={classes.listContainer}>
                {" "}
                {this.state.messages.map((item) => (
                  <div
                    style={
                      item.sender.username === this.props.username
                        ? { display: "flex", justifyContent: "flex-end" }
                        : { display: "flex", justifyContent: "flex-start" }
                    }
                  >
                    <div>
                      <div
                        key={item.created}
                        className={classes.message}
                        style={
                          item.sender.username === this.props.username
                            ? {
                                backgroundColor: "rgba(71, 49, 104, 1)",
                                borderBottomRightRadius: 0,
                                marginLeft: 40,
                                width: "fit-content",
                              }
                            : {
                                backgroundColor: "rgba(135, 201, 255, 0.25)",
                                borderBottomLeftRadius: 0,
                                marginRight: 40,
                                width: "fit-content",
                              }
                        }
                      >
                        <span style={{ maxWidth: 100 }} className={classes.text}>
                          {item.text}
                        </span>
                      </div>
                      <div
                        style={
                          item.sender.username === this.props.username
                            ? { display: "flex", flexDirection: "row-reverse", marginTop: "-10px", marginBottom: 5 }
                            : { display: "flex", flexDirection: "row", marginTop: "-10px", marginBottom: 5 }
                        }
                      >
                        <span className={classes.textSmall}>{displayDateMessage(item.created)}</span>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </div>
            ) : (
              !this.state.showList && (
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <span className={classes.text}>{t("chat.noMessages")}</span>
                </div>
              )
            )}
            {!this.state.showList && (
              <NewMessageForm
                setTokens={this.props.setTokens}
                author={
                  this.props.chatAppState.chats[this.props.chatAppState.activeChat].people[1].person.username !==
                  this.props.username
                    ? this.props.chatAppState.chats[this.props.chatAppState.activeChat].people[1].person.username
                    : this.props.chatAppState.chats[this.props.chatAppState.activeChat].people[0].person.username
                }
                creators={this.props.creators}
                username={this.props.username}
                setShowList={this.props.setShowList}
                chatAppState={this.props.chatAppState}
              />
            )}
          </div>
        ) : (
          <div>
            <span className={classes.title}>{t("chat.noChats")}</span>
            <br />
            <span style={{ opacity: 0.7 }} className={classes.text}>
              {t("chat.noChats2")}
            </span>
            <div className={classes.hide}>
              <NewChatForm
                creds={this.props.chatAppState.creds}
                creators={this.props.creators}
                setShowList={this.props.setShowList}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
}

const styles = {
  body: {
    backgroundColor: "transparent",
    height: "100%",
    // paddingTop: 30,
    paddingRight: 10,
    width: "100%",
    marginLeft: 10,
  },
  title: {
    color: "white",
    opacity: 1,
    fontSize: 15,
  },
  hide: {
    display: "none",
  },
  text: {
    color: "rgba(255, 255, 255, .9)",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 400,
  },
  backgroundImage: {
    position: "absolute",
    opacity: 0.2,
    width: "100%",
    marginTop: "20%",
    zIndex: 0,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .75)",
    fontSize: 8,
    textAlign: "center",
  },
  message: {
    position: "relative",
    padding: 8,
    backgroundColor: "#180931",
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5,
    overflowWrap: "break-word",
    zIndex: 100,
    // width: "fit-content",
  },
  listContainer: {
    position: "relative",
    overflow: "hidden",
    overflowY: "scroll",
    // height: "60vh",
    height: "calc(100% - 123px)",
    zIndex: 1000,
  },
  "@media screen and (max-width: 400px)": {
    body: {
      // maxWidth: 290,
      minHeight: 600,
      marginLeft: 0,
    },
    hide: {
      display: "block",
    },
  },
  "@media screen and (min-width: 800px)": {
    body: {
      backgroundColor: "#1f094d",
      paddingTop: 30,
      width: "160px",
      marginLeft: "0px",
      // height: "90%",
      height: "calc(100% - 130px)",
      boxShadow: "0px 0px 8px 3px #c59cdc",
      borderRadius: 15,
      padding: 20,
    },
    listContainer: {
      // height: "54vh",
      height: "calc(100% - 106px)",
      paddingRight: 10,
    },
  },
  "@media screen and (min-width: 860px)": {
    body: {
      width: "220px",
    },
  },
  "@media screen and (min-width: 1024px)": {
    body: {
      width: "360px",
    },
  },
  "@media screen and (min-width: 1220px)": {
    body: {
      width: "520px",
    },
  },
  "@media screen and (min-width: 1430px)": {
    body: {
      width: "620px",
    },
  },
  "@media screen and (min-width: 1650px)": {
    body: {
      width: "620px",
    },
  },
};

const ChatFeedTranslated = withTranslation("common")(ChatFeed);
export default injectSheet(styles)(ChatFeedTranslated);
