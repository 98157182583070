import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/Logo.gif";

const Logo = (props) => {
  return (
    <Link to="/listenlist">
      <div className="logoButton">
        {/* <h1>Voyal</h1> */}

        <img
          style={{ width: props.width, height: 71 }}
          src={logo}
          // src="../assets/images/Logo_round_final_transparent 1.png"
          alt="loading..."
        />
      </div>
    </Link>
  );
};

export default Logo;
