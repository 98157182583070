import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link } from "react-router-dom";
import { PlusCircle, ShoppingBag, Home, User, MessageCircle } from "react-feather";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeElement: window.location.pathname,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.body}>
        <Link to="/listenlist">
          <button
            aria-label="Link to home"
            onClick={() => this.setState({ activeElement: "/listenlist" })}
            className={classes.noDefaultButton}
          >
            <Home
              className={classes.icon}
              style={this.state.activeElement === "/listenlist" ? { fill: "white" } : {}}
            />
          </button>
        </Link>
        <Link to="/chat">
          <button
            aria-label="Link chat"
            onClick={() => this.setState({ activeElement: "/chat" })}
            className={classes.noDefaultButton}
          >
            <MessageCircle
              className={classes.icon}
              style={this.state.activeElement === "/chat" ? { fill: "white" } : {}}
            />
          </button>
        </Link>
        <Link to="/record">
          <button
            aria-label="Link record"
            onClick={() => this.setState({ activeElement: "/record" })}
            className={classes.noDefaultButton}
          >
            <PlusCircle
              className={classes.icon}
              style={this.state.activeElement === "/record" ? { fill: "white" } : {}}
            />
          </button>
        </Link>
        <Link to="/myaudios">
          <button
            aria-label="Link my audios"
            onClick={() => this.setState({ activeElement: "/myaudios" })}
            className={classes.noDefaultButton}
          >
            <ShoppingBag
              className={classes.icon}
              style={this.state.activeElement === "/myaudios" ? { fill: "white" } : {}}
            />
          </button>
        </Link>
        <Link to="/profile">
          <button
            aria-label="Link profile"
            onClick={() => this.setState({ activeElement: "/profile" })}
            className={classes.noDefaultButton}
          >
            <User
              className={classes.icon}
              style={
                this.state.activeElement === "/profile"
                  ? {
                      color: "white",
                      fill: "white",
                    }
                  : { color: "#746B83" }
              }
            />
          </button>
        </Link>
      </div>
    );
  }
}

const styles = {
  body: {
    width: "100%",
    display: "flex",
    bottom: "0",
    left: 0,
    zIndex: 1000,
    position: "fixed",
    backgroundColor: "#180931",
    boxShadow: "0px 0px 12px #C59CDC",
    textAlign: "center",
    justifyContent: "center",
  },
  icon: {
    color: "#180931",
    fill: "#746B83",
    width: 34,
    height: 34,
    margin: 12,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
};

export default injectSheet(styles)(NavBar);
