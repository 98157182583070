import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link, Redirect } from "react-router-dom";
import { deleteAccount } from "../../../context/auth";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import Sidebar from "../../../general/Navigation/Sidebar";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    mixpanel.track("Screen_delete_account");
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className={classes.body}>
        <div className={classes.button}>
          <button
            aria-label="Delete account"
            className={classes.noDefaultButton}
            onClick={async (e) => {
              e.preventDefault();
              await deleteAccount();
              this.props.history.push("/");
            }}
          >
            {t("settings.deleteReally")}
          </button>
        </div>
        <div className={classes.button}>
          <Link to="/profile">
            <span className={classes.text}>{t("settings.deleteNo")}</span>
          </Link>
        </div>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    maxWidth: 450,
    minWidth: 320,
    paddingBottom: 100,
    marginTop: 50
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 14,
    textAlign: "center",
  },
  noDefaultButton: {
    margin: 0,
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
  button: {
    marginTop: 20,
    textAlign:"center"
  },
  
     "@media screen and (min-width: 800px)":{
      body: {
        position: "absolute",
        right: "calc(50% - 295px)",
      },
      button: {
        alignItems:"center",
      }
    }
};

const DeleteAccountComponentTranslated = withTranslation("common")(DeleteAccount);
export default injectSheet(styles)(DeleteAccountComponentTranslated);
