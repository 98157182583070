import i18next from "i18next";
import moment from "moment";

export function validateEmail(email) {
  let valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/.test(email);
  return valid;
}

export function validatePassword(password) {
  let valid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(password);
  return valid;
}

export function getDate() {
  return new Date();
}

export function format(duration) {
  let min = duration / 60;
  if (min.length < 2) min = "0" + min;
  let sec = duration % 60;
  if (sec.length < 2) sec = "0" + sec;
  return parseInt(min) + ":" + parseInt(sec);
}

export function msToTime(s) {
  s = s / 1000;
  let secs = (s % 1000).toString().length < 2 ? "0" + (s % 1000) : s % 1000;
  let mins = parseInt(secs / 60);
  secs =
    parseInt(secs - mins * 60).toString().length < 2 ? "0" + parseInt(secs - mins * 60) : parseInt(secs - mins * 60);
  return mins + ":" + secs;
}

export function sToTime(s) {
  let mins = Math.floor(s / 60);
  let secs = s - mins * 60;
  return mins + ":" + secs;
}

function getDateDiff(date) {
  let today = new Date();
  let dateOld = new Date(date);
  return Math.ceil((today - dateOld) / (1000 * 60 * 60 * 24)) - 1;
}

export function validateMessage(text) {
  let validate = text.replace(/\d+$/g, "*").replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/, "**********");
  return validate;
}

export function displayDate(timestamp) {
  let date = getDateDiff(timestamp);
  if (date > 365) {
    return i18next.t("common:helpers.years1") + (Math.ceil(date / 365) - 1) + i18next.t("common:helpers.years2");
  } else if (date > 30) {
    return i18next.t("common:helpers.months1") + (Math.ceil(date / 30) - 1) + i18next.t("common:helpers.months2");
  } else if (date === 0) {
    return i18next.t("common:helpers.today");
  } else if (date === 1) {
    return i18next.t("common:helpers.yesterday");
  } else {
    return i18next.t("common:helpers.days1") + date + i18next.t("common:helpers.days2");
  }
}

export function displayDateMessage(timestamp) {
  var formats = {
    sameDay: "hh:mm",

    sameElse: "DD/M/YYYY hh:mm",
  };

  var output = moment(timestamp).calendar(null, formats);
  return output;
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
