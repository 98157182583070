import React, { Component } from "react";
import injectSheet from "react-jss";
import { validateEmail } from "../../../context/helper";
import axios from "axios";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { Mail } from "react-feather";
import Header from "../../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      email2: "",
      emailError: false,
      emailError2: false,
    };
    this.handleChangeInput1 = this.handleChangeInput1.bind(this);
    this.handleChangeInput2 = this.handleChangeInput2.bind(this);
  }

  handleChangeInput1(event) {
    this.setState({ email: event.target.value.toLowerCase() });
    this.setState({ email2: "" });
    if (validateEmail(event.target.value.toLowerCase())) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  }

  handleChangeInput2(event) {
    this.setState({ email2: event.target.value.toLowerCase() });
    if (this.state.email.toLowerCase() === event.target.value.toLowerCase()) {
      this.setState({ emailError2: false });
    } else {
      this.setState({ emailError2: true });
    }
  }

  async submitStep1(alertMessage) {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/emails/check", {
        params: {
          email: this.state.email,
        },
      })
      .then((res) => {
        if (!res.data) {
          this.props.setEmail(this.state.email);
          this.props.setStep(this.props.step + 1);
        } else {
          alert(alertMessage);
        }
      });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <div className={classes.body}>
        <Header />
        <div className={classes.header}>
          <h6 className={classes.text} style={{ marginBottom: 5 }}>
            {t("register.step1.title")}
          </h6>
        </div>
        <form className={classes.form}>
          <span className={classes.textSmall}>{t("register.step1.text")}</span>
          <div className={classes.input_container}>
            <Mail className={classes.input_image} />
            <input
              placeholder={t("register.step1.input1")}
              autoCapitalize="none"
              value={this.state.email}
              className={classes.input}
              onChange={this.handleChangeInput1}
              style={{ color: "white" }}
            />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "red",
              marginLeft: 15,
              fontSize: "10px",
            }}
          >
            {this.state.emailError ? t("register.step1.error1") : ""}
          </span>
          <div className={classes.input_container}>
            <Mail className={classes.input_image} />
            <input
              placeholder={t("register.step1.input2")}
              autoCapitalize="none"
              value={this.state.email2}
              className={classes.input}
              onChange={this.handleChangeInput2}
              style={{ color: "white" }}
            />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "red",
              marginLeft: 15,
              fontSize: "10px",
            }}
          >
            {this.state.emailError2 ? t("register.step1.error2") : ""}
          </span>
          <button
            aria-label="Submit register Step 2"
            className={classes.submitButton}
            onClick={async (e) => {
              e.preventDefault();
              await this.submitStep1(t("register.step1.alert"));
              mixpanel.track("Submit_register_step2", {
                email: this.state.email,
              });
            }}
            disabled={
              this.state.email.length === 0 ||
              this.state.email2.length === 0 ||
              this.state.emailError ||
              this.state.emailError2
            }
          >
            {t("register.step0.submit")}
          </button>
        </form>
      </div>
    );
  }
}

const styles = {
  body: {
    marginTop: 20,
    
  },
  header: {
    marginBottom: "35px",
    width: "350px",
    marginLeft: "12px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    lineHeight:"20px"
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 14,
    fontWeight: 550,
    marginLeft:"10px",
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    color: 'white',
    marginBottom: 10,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 20,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 45,
    width: "calc(100% - 65px)",
    webkitTextFillColor: "white",
  },
  input_container: {
    position: "relative",
    padding: 0,
    margin: 0,
  },
  input_image: {
    color: "white",
    position: "absolute",
    bottom: 19,
    left: 20,
    width: 25,
    height: 25,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: "58%",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "#87c9ff57)",
      cursor: "help",
    },
  },
  "@media screen and (min-width: 800px)": {
    submitButton: {
      width: "calc(100% - 200px)",
      // marginTop: "150px",
    },
    input: {
      maxWidth: "450px",
    },
    body: {
      fontSize: "15px",
    },
    header: {
      marginBottom: "10px",
    },
    text: {
      width: "512px",
      textAlign: "center",
      fontSize: 22,
    },
    textSmall: {
      width: "512px",
      textAlign: "center",
      marginBottom: "25px",
      paddingLeft: "10px",
    },
  },
};

const Step1ComponentTranslated = withTranslation("common")(Step1);
export default injectSheet(styles)(Step1ComponentTranslated);
