import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import BackButton from "../../../general/Navigation/components/BackButton";
import axios from "axios";
import { getUser, getUsername } from "../../../context/auth";
import { CheckCircle } from "react-feather";
import Sidebar from "../../../general/Navigation/Sidebar";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class BlockedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      blockedUsers: [],
    };
    this.deleteBlock = this.deleteBlock.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount() {
    getUser().then(async (redirect) => {
      await getUsername().then(async (name) => {
        this.setState({ username: name });
        axios
          .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              username: name,
            },
          })
          .then((res) => {
            if (res.data.length > 0) this.setState({ blockedUsers: res.data[0].authors });
          });
      });
    });
  }

  async getUsers() {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: this.state.username,
        },
      })
      .then((res) => {
        this.setState({ blockedUsers: res.data[0].authors });
      });
  }

  deleteBlock(name) {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/report/deleteUsers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: this.state.username,
          author: name,
        },
      })
      .then(async (res) => {
        await this.getUsers();
      });
  }

  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <SettingsButton />
        <BackButton url={"/settings"} />
        <div>
          <div
            style={{
              justifyContent: "center",
              verticalAlign: "middle",
              alignItems: "center",
              margin: "auto",
              maxWidth: 350,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("settings.blockedUsers")}</span>
            </div>
          </div>
        </div>
        <div>
          {this.state.blockedUsers.map((item) => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <span className={classes.text}>{item}</span>
                <div className={classes.button} onClick={() => this.deleteBlock(item)}>
                  <CheckCircle className={classes.icon} />
                  <span className={classes.textSmall}>Unblock</span>
                </div>
              </div>
              <div className={classes.line} />
            </div>
          ))}
        </div>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  };
}

const styles = {
  body: {
    position: "relative",
    maxWidth: 450,
    minWidth: 320,
    paddingBottom: 100,
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: "white",
    textAlign: "center",
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
  },
  textSmall: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 12,
    textAlign: "center",
  },
  icon: {
    color: "rgba(255, 255, 255, .85)",
    width: 15,
    height: 15,
    marginRight: 5,
    verticalAlign: "middle",
  },
  button: {
    cursor: "pointer",
    marginLeft: 10,
    borderWidth: 1,
    width: 75,
    borderRadius: 10,
    borderStyle: "solid",
    padding: 6,
    paddingBottom: 4,
    verticalAlign: "middle",
    borderColor: "rgba(255,255,255,0.5)",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginRight: 10,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgba(255, 255, 255, 0.2)",
    margin: 10,
  },
  "@media screen and (min-width: 800px)":{
    body: {
      position: "absolute",
      right: "calc(50% - 300px)",
    },
    button: {
      alignItems:"center",
    }
  }
};

const BlockedUsersTranslated = withTranslation("common")(BlockedUsers);
export default injectSheet(styles)(BlockedUsersTranslated);
