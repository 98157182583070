import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Filter as FilterIcon, X } from "react-feather";
import injectSheet from "react-jss";
import mixpanel from "mixpanel-browser";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
    };
  }

  setActive() {
    this.setState({ open: true });
    if (localStorage.getItem("language_voiyal") === "English") {
      this.props.filterEnglish();
      mixpanel.track("filter_lng_english");
      this.setState({ checkbox1: true });
    } else if (localStorage.getItem("language_voiyal") === "German") {
      this.props.filterGerman();
      mixpanel.track("filter_lng_german");
      this.setState({ checkbox2: true });
    } else if (localStorage.getItem("language_voiyal") === "Spanish") {
      this.props.filterSpanish();
      mixpanel.track("filter_lng_spanish");
      this.setState({ checkbox3: true });
    } else if (localStorage.getItem("language_voiyal") === "all") {
      this.props.filterAll();
      mixpanel.track("filter_lng_all");
      this.setState({ checkbox4: true });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.filter}>
        <div
          className={classes.filterButton}
          onClick={() => {
            this.setActive();
          }}
        >
          <FilterIcon className={classes.icon} />
        </div>
        {this.state.open && (
          <div className={classes.popup}>
            <div className={classes.popupInner}>
              <h5 className="noHeader" style={{ position: "absolute", top: -10, right: "46%" }}>
                Filter
              </h5>
              <span style={{ fontSize: 15 }}>Choose language</span>
              <button
                aria-label="Filter options"
                className={classes.noDefaultButton}
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  width: "auto",
                }}
                onClick={() => this.setState({ open: false })}
              >
                <X
                  style={{
                    opacity: 0.6,
                    color: "white",
                  }}
                />
              </button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 10,
                  maxWidth: 350,

                  marginRight: "auto",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id="c1"
                    checked={this.state.checkbox1}
                    onChange={() =>
                      this.setState({
                        checkbox1: true,
                        checkbox2: false,
                        checkbox3: false,
                        checkbox4: false,
                      })
                    }
                  />
                  <label htmlFor="c1" className={classes.textSmall}>
                    <span></span>
                  </label>
                </div>
                <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                  English
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  maxWidth: 350,

                  marginRight: "auto",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id="c2"
                    checked={this.state.checkbox2}
                    onChange={() =>
                      this.setState({
                        checkbox1: false,
                        checkbox2: true,
                        checkbox3: false,
                        checkbox4: false,
                      })
                    }
                  />
                  <label htmlFor="c2" className={classes.textSmall}>
                    <span></span>
                  </label>
                </div>
                <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                  German
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  maxWidth: 350,

                  marginRight: "auto",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id="c3"
                    checked={this.state.checkbox3}
                    onChange={() =>
                      this.setState({
                        checkbox1: false,
                        checkbox2: false,
                        checkbox3: true,
                        checkbox4: false,
                      })
                    }
                  />
                  <label htmlFor="c3" className={classes.textSmall}>
                    <span></span>
                  </label>
                </div>
                <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                  Spanish
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  maxWidth: 350,

                  marginRight: "auto",
                  marginBottom: 5,
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id="c4"
                    checked={this.state.checkbox4}
                    onChange={() =>
                      this.setState({
                        checkbox1: false,
                        checkbox2: false,
                        checkbox3: false,
                        checkbox4: true,
                      })
                    }
                  />
                  <label htmlFor="c4" className={classes.textSmall}>
                    <span></span>
                  </label>
                </div>
                <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                  All
                </span>
              </div>
              <div>
                <span style={{ fontSize: 15 }}>Content</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 5,
                    maxWidth: 350,

                    marginRight: "auto",
                    marginBottom: 5,
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="c5"
                      checked={this.state.checkbox5}
                      onChange={() =>
                        this.setState({
                          checkbox5: true,
                          checkbox6: false,
                          checkbox7: false,
                        })
                      }
                    />
                    <label htmlFor="c5" className={classes.textSmall}>
                      <span></span>
                    </label>
                  </div>
                  <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                    Free
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 5,
                    maxWidth: 350,

                    marginRight: "auto",
                    marginBottom: 5,
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="c6"
                      checked={this.state.checkbox6}
                      onChange={() =>
                        this.setState({
                          checkbox5: false,
                          checkbox6: true,
                          checkbox7: false,
                        })
                      }
                    />
                    <label htmlFor="c6" className={classes.textSmall}>
                      <span></span>
                    </label>
                  </div>
                  <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                    Paid
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 5,
                    maxWidth: 350,

                    marginRight: "auto",
                    marginBottom: 5,
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="c7"
                      checked={this.state.checkbox7}
                      onChange={() =>
                        this.setState({
                          checkbox5: false,
                          checkbox6: false,
                          checkbox7: true,
                        })
                      }
                    />
                    <label htmlFor="c7" className={classes.textSmall}>
                      <span></span>
                    </label>
                  </div>
                  <span style={{ fontWeight: 400 }} className={classes.textSmaller}>
                    All
                  </span>
                </div>
              </div>
              <button
                aria-label="Submit filter"
                className={classes.submitButton}
                onClick={async () => {
                  this.setState({ open: false });
                  let result;
                  if (this.state.checkbox5) {
                    result = await this.props.filterFree();
                  } else if (this.state.checkbox6) {
                    result = await this.props.filterPaid();
                  } else {
                    result = await this.props.filterAllPrices();
                  }
                  if (this.state.checkbox1) {
                    this.props.filterEnglish(result);
                    localStorage.setItem("language_voiyal", "English");
                  } else if (this.state.checkbox2) {
                    this.props.filterGerman(result);
                    localStorage.setItem("language_voiyal", "German");
                  } else if (this.state.checkbox3) {
                    this.props.filterSpanish(result);
                    localStorage.setItem("language_voiyal", "Spanish");
                  } else if (this.state.checkbox4) {
                    this.props.filterAll(result);
                    localStorage.setItem("language_voiyal", "all");
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  filterButton: {
    position: "absolute",
    left: "22px",
    top: "22px",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#87C9FF",
    borderRadius: 10,
    maxWidth: 80,
    textAlign: "center",
    alignItems: "center",
    margin: "auto",
    paddingLeft: 5,
    paddingRight: 8,
    height: "auto",
  },
  filter: {
    display: "initial",
  },
  icon: {
    verticalAlign: "middle",
    width: 18,
    margin: 3,
    marginLeft: 5,
    color: "white",
    marginTop: "auto",
  },
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 100,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    position: "absolute",
    borderRadius: 10,
    left: "10%",
    zIndex: 102,
    right: "10%",
    top: "10%",
    maxHeight: 400,
    bottom: "25%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    background: " #2E2F5A",
    maxWidth: 320,
  },
  submitButton: {
    cursor: "pointer",
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  textSmaller: {
    marginTop: 7,
    fontSize: 13,
    color: "rgba(255, 255, 255, .65)",
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  "@media screen and (min-width: 800px)": {
    filterButton: {
      position: "initial",
      backgroundColor: "rgba(135, 201, 255, .85)",
      height: "45px",
      width:"32px",
      borderStyle: "none",
    },
    icon: {
      width: 20,
      marginTop: 12,
    },
    filter: {
      margin: "auto",
      padding: " 0px 10px ",
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
    },

    popup: {
      // height: "328px",
      maxWwidth: "512px",
    },
    popupInner: {
      height: "100%",
      maxWidth: "512px",
      textAlign: "start",
      padding: "54px 0px 80px 74px",
      maxHeight:360,
    },
    submitButton: {
      width: "calc(100% - 200px)",
      marginTop: "15px",
      marginLeft: "60px",
    },
  },
};

const FilterComponentTranslated = withTranslation("common")(Filter);
export default injectSheet(styles)(FilterComponentTranslated);
