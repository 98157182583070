import React, { Component } from "react";
import injectSheet from "react-jss";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Twitter } from "react-feather";
import mixpanel from "mixpanel-browser";
import mdh from "../../../assets/images/logo_mdh_de.png";
import ProfilePicture from "./ProfilePicture";
import { Link } from "react-router-dom";
// import Twitter from "../../../general/Twitter";
import drops from "../../../assets/icons/drops.svg";
import { GlobalStoreContext } from "../../../context/globalStore";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      redirect: false,
      tokens: 0,
      hotVoice: false,
      twitter: "",
      tokensProps: 0,
    };
    this.addTokens = this.addTokens.bind(this);
  }
  static contextType = GlobalStoreContext;

  async componentDidMount() {
    if (window.performance) {
      if (window.location.search.length > 0) {
        let params = new URLSearchParams(window.location.search);
        axios
          .get(process.env.REACT_APP_BACKEND_URL + "/session", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            if (res.data.filter((e) => e.id === params.get("session_id")).length === 0) {
              this.addTokens(parseInt(params.get("amount")), params.get("session_id"));
              const newSession = {
                id: params.get("session_id"),
              };
              axios.post(process.env.REACT_APP_BACKEND_URL + "/session/add", newSession, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              });
            }
          });
      }
    }
    mixpanel.track("Screen_open_my_profile");
  }

  async addTokens(amount, sessionId) {
    const [globalStore, setGlobalStore] = this.context;
    const { username, tokens } = globalStore;
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/tokens/add/" + amount, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: { username, sessionId: sessionId },
      })
      .then((res) => {
        if (res.data) setGlobalStore({ tokens: tokens + amount });
        else console.log("add token error");
      });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      tokensProps: nextProps.tokens,
    };
  }
  render() {
    const { classes } = this.props;
    const [globalStore] = this.context;
    const { username, tokens = 0, isHotVoice = false, twitter = "" } = globalStore;
    return (
      <div className={classes.body}>
        <Link to="/profile">
          <ProfilePicture name={username} />
        </Link>
        <div className={classes.profileInfo}>
          <Link to="/profile">
            <h6
              className={classes.text}
              style={
                isHotVoice
                  ? {
                      marginTop: "0",
                      marginBottom: 5,
                      color: "#87C9FF",
                    }
                  : {
                      marginTop: "0",
                      marginBottom: 5,
                    }
              }
            >
              {isHotVoice && (
                <span
                  className="material-icons"
                  style={{
                    fontSize: 18,
                    marginRight: 3,
                    color: "#87C9FF",
                    verticalAlign: "middle",
                  }}
                >
                  &#xef55;
                </span>
              )}
              {username}
            </h6>
          </Link>
          {twitter.length > 1 && (
          <div className={classes.twitter}>
            <a style={{ display: "flex" }} href={"https://www.twitter.com/" + twitter} target="_blank" rel="noreferrer">
                <Twitter
                  style={{
                    width: 12,
                    fill: "rgb(255, 255, 255,.5)",
                    color: "rgb(255, 255, 255,.5)",
                    strokeWidth: "0px",
                  }}
                />      
              <span style={{ paddingLeft: "5px", paddingTop: "3px" }}>{twitter}</span>
            </a>
          </div>
          )}
        </div>
        {username === "mydirtyhobby" && (
          <div className={classes.sponsored}>
            <span className={classes.textSmall}>Sponsored by</span>
            <img alt="sponsor" className={classes.sponsoredLogo} src={mdh} />
          </div>
        )}
        <div className={classes.tokens}>
          <img src={drops} alt="drops" className={classes.drops} />
          <b className={classes.points}>{tokens} </b>
        </div>
      </div>
    );
  }
}

const styles = {
  logo: {
    position: "absolute",
    left: "5px",
    top: "5px",
  },
  text: {
    fontSize: 15,
    lineHeight: "22px",
    color: "#87C9FF",
    textAlign: "left",
    fontWeight: 600,
  },
  points: {
    lineHeight: "22px",
    color: "#fff",
    textAlign: "right",
    fontWeight: 600,
    verticalAlign: "middle",
    fontSize: "22px",
    paddingRight: 20,
    marginTop: "3px",
  },
  drops: {
    color: "#87C9FF",
    fill: "#87C9FF",
    verticalAlign: "middle",
    width: 15.1,
    height: 20.2,
    margin: "10px 11px 7px 15px",
  },
  tokens: {
    height: "45px",
    display: "flex",
    alignContent: "flex-start",
    justifyContent: "center",
    marginTop: 15,
    marginBottom: 20,
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#87C9FF",
    borderRadius: 5,
    width: "99.26px",
    textAlign: "center",
    alignItems: "center",
    marginLeft: "28px"
  },
  body: {
    display: "flex",
    width: "300px",
    flexWrap: "wrap",
  },
  profileInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  twitter: {
    display: "flex",
    fontWeight: "500",
    fontSize: "11px",
    lineHeight: "16px",
    textDecorationLine: "underline",
    color: "rgba(255, 255, 255, 0.5)",
  },
  "@media screen and (min-width: 800px)": {
    points: {
      lineHeight: "33px",
      fontSize: "22px",
    },
  },
};

const ProfileScreenComponentTranslated = withTranslation("common")(ProfileScreen);
export default injectSheet(styles)(ProfileScreenComponentTranslated);
