import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

import mixpanel from "mixpanel-browser";
import axios from "axios";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: "-",
      listenCounter: "0",
      twitter: "no twitter connected",
      instagram: "no instagram connected",
    };
  }

  async componentWillReceiveProps() {
    if (this.props.name.length > 0) {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/bio", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.props.name,
          },
        })
        .then((res) => {
          if (res.data.length > 0) this.setState({ bio: res.data[0].text });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/twitter", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.props.name,
          },
        })
        .then((res) => {
          if (res.data.length > 0) this.setState({ twitter: res.data[0].twitter });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/instagram", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.props.name,
          },
        })
        .then((res) => {
          if (res.data.length > 0) this.setState({ instagram: res.data[0].instagram });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/transactions/getCount", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.props.name,
          },
        })
        .then((res) => {
          if (res.data > 0)
            this.setState({
              listenCounter: res.data,
            });
        });
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <span className={classes.textHeadline}>{t("profile.twitter")}</span>
        <div>
          <span className={classes.text}>{this.state.twitter}</span>
        </div>
        <div className={classes.line} />
        <span className={classes.textHeadline}>{t("profile.instagram")}</span>
        <div>
          <span className={classes.text}>{this.state.instagram}</span>
        </div>
        <div className={classes.line} />
        <span className={classes.textHeadline}>{t("profile.bio")}</span>
        <div>
          <span className={classes.text}>{this.state.bio}</span>
        </div>
        <div className={classes.line} />
        <span className={classes.textHeadline}>{t("profile.listenCounter")}</span>
        <div>
          <span className={classes.text}>{this.state.listenCounter}</span>
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    marginTop: 15,
    marginLeft: 5,
    marginRight: 5,
  },
  textHeadline: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 11,
    fontWeight: 500,
    textAlign: "center",
    verticalAlign: "middle",
  },
  text: {
    color: "white",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
  },
  line: {
    marginTop: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(255, 255, 255, 0.2)",
    marginBottom: 10,
  },
};

const ProfileInfoComponentTranslated = withTranslation("common")(ProfileInfo);
export default injectSheet(styles)(ProfileInfoComponentTranslated);
