import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import SafeArea from "react-safe-area-component";
import mixpanel from "mixpanel-browser";
import { Auth } from "aws-amplify";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Confirm extends Component {

  async componentDidMount(){
    mixpanel.track("Screen_open_confirm_account");
    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    let username = params.get('userName');
    let code = params.get('confirmation_code');
    await this.confirmSignUp(username, code).then( () => {
      this.props.history.push('/');
      }
    )
  }

  async confirmSignUp(username, code) {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <SafeArea top bottom>
        <div className={classes.title}>
          Confirm E-mail
        </div>
      </SafeArea>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    overflowX: "hidden",
    paddingBottom: 150,
  },
  title: {
    marginTop: 0,
    fontSize: 16,
    fontWeight: 600,
    opacity: 0.9,
    marginBottom: 5,
    color: "white",
  },
};

const ConfirmTranslated = withTranslation("common")(Confirm);
export default injectSheet(styles)(ConfirmTranslated);
