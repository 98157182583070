import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

class TrendingHashtags extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, items } = this.props;
    const list = items.find(el => el.hashtags.length > 3 )

    return (
      <div>
        <span>      
            <div className={classes.trendyHashtags}>
             <li style={{ display: "inline" }}>
                  <a className="noDefaultButton" href={window.location.origin + "/listenlist/" + list._id}>
                      <div style={{ marginTop: 5,display: "flex", justifyContent:"space-evenly"}}>
                        {list.hashtags.slice(0,3).map(el => 
                            (
                            <div className={classes.tiles}>
                                <span className={classes.textSmall}>#{el.replace( /\s/g, '')}</span>
                            </div>)                         
                        )}
                      </div>    
                  </a>
                </li>
            </div>
        </span>
      </div>
    );
  }
}

const styles = {
  trendyHashtags: {
    display: "block",
  },
  tiles: {
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
  },
  textSmall: {
    color: "white",
    fontSize: 20,
    textAlign: "center",
    fontWeight: 600,
  },
  noDefaultButton: {
    margin: 0,
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
};

const TrendingHashtagsTranslated = withTranslation("common")(TrendingHashtags);
export default injectSheet(styles)(TrendingHashtagsTranslated);
