import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import FlatList from "flatlist-react";
import axios from "axios";

class TrendingCreators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url1: "",
      url2: "",
      url3: "",
      displayItems: [],
    };
    this.getProfilePic = this.getProfilePic.bind(this);
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    let items = nextProps.items;
    let displayItems = items.slice(0, 3);
    this.setState({ displayItems: displayItems });
    await this.getProfilePic(displayItems[0]?.author, "url1");
    await this.getProfilePic(displayItems[1]?.author, "url2");
    await this.getProfilePic(displayItems[2]?.author, "url3");
  }

  async getProfilePic(name, index) {
    console.log("props", name);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
        params: {
          username: name,
        },
      })
      .then((res) => {
        if (index === "url1") this.setState({ url1: res.data[0] ? res.data[0].fileUrl : "" });
        if (index === "url2") this.setState({ url2: res.data[0] ? res.data[0].fileUrl : "" });
        if (index === "url3") this.setState({ url3: res.data[0] ? res.data[0].fileUrl : "" });
      });
  }

  renderItem = (item, idx) => {
    const { classes } = this.props;
    return (
      <li key={idx} style={{ display: "inline" }}>
        <a className="noDefaultButton" href={window.location.origin + "/authorprofile/" + item.author}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {idx === 0 && (
                <img
                  alt="profile"
                  src={this.state.url1 || "https://voiyalvoices.de/voiyal.png"}
                  className={classes.profileImage}
                />
              )}
              {idx === 1 && (
                <img
                  alt="profile"
                  src={this.state.url2 || "https://voiyalvoices.de/voiyal.png"}
                  className={classes.profileImage}
                />
              )}
              {idx === 2 && (
                <img
                  alt="profile"
                  src={this.state.url3 || "https://voiyalvoices.de/voiyal.png"}
                  className={classes.profileImage}
                />
              )}
              <span className={classes.textSmall}>{item.author}</span>
            </div>
          </div>
        </a>
        <div className={classes.verticalLine} />
      </li>
    );
  };

  render() {
    const { classes, t } = this.props;
    const { displayItems } = this.state;
    return (
      <div>
        <span>
          {displayItems.length > 0 && (
            <div className={classes.trendyCreators}>
              <FlatList
                list={displayItems}
                renderItem={this.renderItem}
                renderWhenEmpty={() => <div className={classes.textSmall}>{t("general.emptyList")}</div>}
                renderOnScroll
              />
            </div>
          )}
        </span>
      </div>
    );
  }
}

const styles = {
  trendyCreators: {
    display: "block",
  },
  textSmall: {
    fontSize: 15,
    textAlign: "center",
    fontWeight: 600,
    color: "white",
    marginTop: 14,
    marginLeft: 15,
  },
  noDefaultButton: {
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
  },
  verticalLine: {
    borderTopColor: "rgba(255, 255, 255, .2)",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  profileImage: {
    width: 50,
    height: 50,
    objectFit: "cover",
    marginTop: 5,
    borderRadius: 50,
    marginLeft: 10,
    verticalAlign: "middle",
  },

  "@media screen and (min-width: 800px)": {
    trendyCreators: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
  },
};

const TrendingCreatorsTranslated = withTranslation("common")(TrendingCreators);
export default injectSheet(styles)(TrendingCreatorsTranslated);
