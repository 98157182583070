import React, { Component } from "react";
import injectSheet from "react-jss";
import Step1 from "./Tutorials/step1";
import Step2 from "./Tutorials/step2";

class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
    this.updateStep = this.updateStep.bind(this);
  }

  updateStep(step) {
    this.setState({
      step: step,
    });
  }

  render = () => {
    return (
      <div>
        {this.state.step === 1 && <Step1 step1={this.props.step1} updateStep={this.updateStep} />}
        {this.state.step === 2 && <Step2 updateStep={this.updateStep} />}
        {this.state.step === 3 && <div />}
      </div>
    );
  };
}

const styles = {};

export default injectSheet(styles)(Tutorial);
