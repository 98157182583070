import axios from "axios";

export function getTokens(username) {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + "/tokens/get", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        username,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.error(e));
}

export function getTransactions(username) {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + "/transactions/getCount", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        username,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.error(e));
}

export function checkHotVoice(username) {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + "/hotvoice", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        user: username,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.error(e));
}

export function checkIfVerified() {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + "/ageverify/check", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      console.log(e);
      return false;
    });
}

export function getProfilePic(username) {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
      params: {
        username,
      },
    })
    .then((res) => {
      if (res.data.length > 0) {
        return {
          picUrl: res.data[0].fileUrl,
          picName: res.data[0].fileName,
        };
      }
      else{
        return{
          picUrl: "",
          picName: "",
      }}
    });
}

export function getTwitter(username) {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + "/twitter", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        username,
      },
    })
    .then((res) => {
      if (res.data.length > 0) return res.data[0].twitter;
    });
}
