import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class PopupDropsEarned extends Component {
  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.popup}>
        <div className={classes.popupInner}>
          <h4
            style={{
              marginBottom: 5,
              fontSize: 16,
            }}
          >
            {t("donationpopup.drops1")}
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <span className={classes.textSmall}>
              <b>{this.props.dropsAmount} </b> {t("donationpopup.drops2")}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              aria-label="Close popup"
              className="submitButton"
              style={{
                margin: 0,
                marginTop: 40,
                fontSize: 15,
              }}
              onClick={() => {
                this.props.closePopup(false);
                mixpanel.track("Submit_earned_drops_popup");
              }}
            >
              {t("donationpopup.ok")}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

const styles = {
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 100,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    position: "absolute",
    borderRadius: 20,
    left: "10%",
    zIndex: 102,
    right: "10%",
    top: "10%",
    bottom: "40%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    background: "#180931",
  },
  "@media screen and (min-width: 800px)": {
    popup: {
      top: "200px",
      width: "100%",
    },
    popupInner: {
      width: "30%",
      maxWidth: "511px",
      maxHeight: "447px",
      padding: "20px",
      marginLeft: "36.5%",
      minWidth: "300px",
    },
  },
};

const PopupDropsEarnedTranslated = withTranslation("common")(PopupDropsEarned);
export default injectSheet(styles)(PopupDropsEarnedTranslated);
