import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { createBrowserHistory as history } from "history";

import LogIn from "./components/authentification/Login";
import Register from "./components/authentification/Register";
import ListenList from "./components/Listen/ListenScreen";
import RecordScreen from "./components/Record/RecordScreen";
import ProfileScreen from "./components/Profile/ProfileScreen";
import SettingsScreen from "./components/Profile/Views/Settings";
import Forgotpassword from "./components/authentification/Components/Forgotpassword";
import DeleteAccount from "./components/Profile/Views/DeleteAccount";
import MyAudios from "./components/Listen/MyAudios";
import AuthorProfile from "./components/AuthorProfile/AuthorProfile";
import EditProfile from "./components/Profile/Views/EditProfile";
import MyStories from "./components/Profile/Views/MyStories";
import BuyDrops from "./components/Profile/Views/BuyDrops";
import Rules from "./components/Rules";
import Confirm from "./components/authentification/Components/confirm";
import Preview from "./components/AudioPreview/preview";
import PreviewNew from "./components/AudioPreview/previewNew";
import Search from "./components/Search/Search";
import Chat from "./components/Chat/Chat";
import BlockedUsers from "./components/Profile/Views/blockedUsers";
import mixpanel from "mixpanel-browser";
import VerifyAge from "./components/Profile/Views/VerifyAge";
import Payout from "./components/Profile/Views/Payout";
import Checkout from "./components/Profile/Views/Checkout";
import Payment from "./components/Profile/Views/Payment";
import GlobalStoreProvider from "./context/globalStore";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });
mixpanel.track("Start_app");

Amplify.configure(awsconfig);

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <GlobalStoreProvider>
            <Route exact path="/" component={LogIn} />
            <Route path="/register" component={Register} />
            <Route path="/listenlist" component={ListenList} />
            <Route path="/record" component={RecordScreen} />
            <Route path="/profile" component={ProfileScreen} />
            <Route path="/settings" component={SettingsScreen} />
            <Route path="/forgotpassword" component={Forgotpassword} />
            <Route path="/deleteaccount" component={DeleteAccount} />
            <Route path="/myaudios" component={MyAudios} />
            <Route path="/authorprofile/:author" component={AuthorProfile} />
            <Route path="/editprofile" component={EditProfile} />
            <Route path="/mystories" component={MyStories} />
            <Route path="/buydrops" component={BuyDrops} />
            <Route path="/rules" component={Rules} />
            <Route path="/preview" component={Preview} />
            <Route path="/search" component={Search} />
            <Route path="/sneakpeek" component={PreviewNew} />
            <Route path="/chat" exact component={Chat} />
            <Route path="/chat/:name" component={Chat} />
            <Route path="/blockedusers" component={BlockedUsers} />
            <Route path="/verifyage" component={VerifyAge} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/payment" component={Payment} />
            <Route path="/payout" component={Payout} />
            <Route path="/confirmmail" component={Confirm} />
          </GlobalStoreProvider>
        </div>
      </Router>
    );
  }
}

export default App;
