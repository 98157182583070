import React, { Component } from "react";
import FlatList from "flatlist-react";
import { Link, Redirect } from "react-router-dom";
import { getUser, getUsername } from "../../context/auth";
import axios from "axios";
import { Send } from "react-feather";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import ListCard from "../../general/ListCard";
import Twitter from "../../general/Twitter";
import mixpanel from "mixpanel-browser";
import PopupShare from "../Listen/Popups/PopupShare";
import SettingsButton from "../../general/SettingsButton";
import { MoreVertical } from "react-feather";
import Instagram from "../../general/Instagram";
import Sidebar from "../../general/Navigation/Sidebar";
import Header from "../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class AuthorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      items: [],
      displayItems: [],
      username: "",
      active_item: {},
      tokens: 0,
      free: false,
      profilePics: [],
      mainPicUrl: "",
      myVoiyals: [],
      bio: "",
      listenCounter: 0,
      hotVoice: false,
      showPopup: false,
      bioInput: "",
      shareUrl: ""
    };
    this.handleInputBio = this.handleInputBio.bind(this);
    this.showPopupShare = this.showPopupShare.bind(this);
  }

  showPopupShare(value) {
    this.setState({
      showPopupShare: value,
    });
  }

  async componentDidMount() {
    getUser().then(async (redirect) => {
      this.setState({ redirect: redirect });
    });
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
        params: {
          username: this.props.match.params.author,
        },
      })
      .then((res) => {
        if (res.data.length > 0) this.setState({ mainPicUrl: res.data[0].fileUrl });
        else this.setState({ mainPicUrl: "" });
      });
    mixpanel.track("Screen_open_authorprofile");
    getUsername().then((name) => {
      this.setState({ username: name });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/audios/oneuser", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.props.match.params.author,
          },
        })
        .then((res) => {
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.props.match.params.author, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              this.setState({ hotvoice: res.data });
            });
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/tokens/get/", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: name,
              },
            })
            .then((res) => {
              if (res.data) this.setState({ tokens: res.data });
              else console.log("not auth");
            });
          if (res.data) {
            axios
              .get(process.env.REACT_APP_BACKEND_URL + "/transactions/getCount", {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                params: {
                  username: this.props.match.params.author,
                },
              })
              .then((res) => {
                if (res.data > 0)
                  this.setState({
                    listenCounter: res.data,
                  });
              });
            res.data.forEach((item) => {
              axios
                .get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudiopreview/" + item._id, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  let array = [];
                  this.state.displayItems.forEach(function (item) {
                    array.push(item);
                    
                  });
                  if (res.data[0]) {array.push(res.data[0]); array = array.filter((item) => item.curated)}
                  this.setState({ displayItems: array, items: array });
                });
            });
          }
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/myvoiyals/all", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: name,
              },
            })
            .then((res) => {
              if (res.data[0]) this.setState({ myVoiyals: res.data[0].audios });
            });
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/bio", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                username: this.props.match.params.author,
              },
            })
            .then((res) => {
              if (res.data.length > 0) this.setState({ bio: res.data[0].text });
            });
        });
    });
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/all", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.setState({ profilePics: res.data });
      });
  }

  handleInputBio(event) {
    this.setState({ bioInput: event.target.value });
  }

  renderItem = (item, idx) => {
    return <ListCard idx={idx} item={item} myVoiyals={this.state.myVoiyals} activeItem={this.state.active_item} />;
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className={classes.body}>
        <div className="noHeader">
          <Header />
        </div>
        {this.state.showPopupShare && <PopupShare showPopupShare={this.showPopupShare} />}
        <SettingsButton />
        <div
          style={{
            marginTop: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div>
            {this.state.mainPicUrl ? (
              <div>
                <img alt={"current title"} src={this.state.mainPicUrl} className={classes.mainPic} />
              </div>
            ) : (
              <div class="center">
                <img
                  alt={"current title"}
                  style={{ opacity: 0.5 }}
                  src={"https://voiyalvoices.de/voiyal.png"}
                  className={classes.mainPic}
                />
              </div>
            )}
          </div>
          <div className={classes.authorsHeader}>
            <div>
              <div className={classes.title}>
                <span
                  style={
                    this.state.hotvoice
                      ? {
                          color: "#87C9FF",
                          fontSize: 16,
                          fontWeight: 600,
                        }
                      : { color: "white", fontSize: 22, fontWeight: 600 }
                  }
                >
                  {this.props.match.params.author}
                </span>
                {this.state.hotvoice && (
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 16,
                      marginLeft: 3,
                      color: "#87C9FF",
                      marginTop: 5,
                    }}
                  >
                    &#xef55;
                  </span>
                )}
              </div>
              {/* bio */}
              {this.state.bio.length > 0 && (
                <div className={classes.bio}>
                  <span className={classes.textSmall}>{this.state.bio}</span>
                </div>
              )}
            </div>
            <div className={classes.socials}>
              {/* message */}
              <div>
                <Link
                  onClick={() => mixpanel.track("Click_open_chat_profile")}
                  to={"/chat/" + this.props.match.params.author}
                  style={{
                    border: 1,
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderColor: "white",
                    color: "white",
                    opacity: 0.6,
                    paddingTop: 0,
                    marginBottom: 5,
                    marginTop: 5,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    width: "90px",
                    maxWidth: 150,
                    cursor: "pointer",
                    textAlign: "center",
                    verticalAlign: "center",
                    fontSize: "13px",
                    height: "25px",
                  }}
                  className={classes.noDefaultButton}
                >
                  <Send
                    style={{
                      width: 14,
                      verticalAlign: "center",
                      marginLeft: 4,
                      marginRight: 4,
                      marginTop: 1,
                    }}
                  />
                  <span
                    style={{
                      marginRight: 4,
                      marginTop: -1,
                      fontWeight:500
                    }}
                  >
                    Message
                  </span>  
                </Link>
                <div>
                    <Twitter author={this.props.match.params.author} />
                    <Instagram style={{ marginRight: "54px" }} author={this.props.match.params.author} />
                </div>
              </div>
              {/* socials */}
              <div className={classes.socialIn}>

                <button
                  style={{ paddingTop: "6px" }}
                  aria-label="Share Profile Link"
                  className={classes.noDefaultButton1}
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      window.location.origin + "/authorprofile/" + this.props.match.params.author
                    );
                    this.setState({ showPopupShare: true });
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      margin: 0,
                      marginTop: -2,
                      fontSize: "18px",
                      color: "white",
                      opacity: 0.6,
                    }}
                  >
                    &#xe6b8;
                  </span>
                </button>
                <Link to='/settings' className={classes.noMobile} style={{marginTop: 1}}>
                  <MoreVertical />
                </Link> 
              </div>
            </div>
          </div>
        </div>
        <div className={classes.list}>
          <ul className={classes.listIn}>
            <FlatList
              style={{ marginLeft: "auto", marginRight: "auto", cursor: "pinter" }}
              list={this.state.displayItems}
              renderItem={this.renderItem}
              renderWhenEmpty={() => <div className={classes.textSmall}>{t("general.emptyList")}</div>}
              renderOnScroll
            />
          </ul>
        </div>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  date: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: 5,
    marginBottom: 5,
  },
  linkHome: {
    position: "absolute",
    left: "20px",
    top: "18px",
  },
  list: {
    marginTop: 20,
  },
  body: {
    paddingBottom: 100,
    maxWidth: 450,
    position: "relative",
    overflowX: "hidden",
    marginLeft: 20,
    marginRight: 20,
  },
  noMobile: {
    display: 'none'
  },
  socials: {
    display: "initial",
  },
  bio: {
    marginBottom: 5,
    marginTop: 5,
    maxWidth: 300,
    marginLeft: 5,
    marginRight: 5, 
    lineHeight: "21px" 
  },
  authorsHeader: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
  },
  mainPic: {
    objectFit: "cover",
    width: 100,
    height: 100,
    borderRadius: 100,
    margin: 5,
    marginRight: 15,
  },
  textSmall: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 14,
    fontWeight: 500,
  },
  title: {
    verticalAlign: "middle",
    marginLeft: 5,
    display: "flex",
    flexDirection: "row",
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  noDefaultButton1: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
    paddingTop: "0px",
    textAlign: "start",
  },
  socialIn: {
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
    alignItems: "flex-start",
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      textAlign: "center",
      color: "#C59CDC",
    },
    "&:focus": {
      outlineColor: "#C59CDC",
    },
    fontFamily: "Poppins",
    maxWidth: 500,
    backgroundColor: "rgba(255, 255, 255, .85)",
    fontSize: 22,
    padding: 5,
    border: "none",
    borderRadius: 5,
    color: "#180931",
    marginBottom: 25,
    webkitTextFillColor: "white",
  },
  listIn: {
    display: "block",
    paddingLeft: 0,
  },
  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: "130px",
      width: "500px",
      maxWidth: "500px",
      marginLeft: "300px",
    },
    noMobile: {
      display: 'block'
    },
    noDefaultButton: {
      paddingTop: "6px",
    },
    authorsHeader: {
      flexDirection: "row",
      marginTop: 10,
      justifyContent: "space-between",
      width: "85%",
    },
    socials: {
      display: "flex",
      alignItems: "flex-start",
    },
    socialIn: {
      flexWrap: "nowrap",
      width: "max-content",
      flexDirection: "row",
    },
    listIn: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingLeft: 5,
      paddingRight: 5,
    },
    bio: {
      maxWidth: "420px",
    },
  },

  "@media screen and (min-width: 1111px)": {
    body: {
      width: "765px",
      maxWidth: "765px",
      marginLeft: "300px",
    },
  },
};

const AuthorProfileTranslated = withTranslation("common")(AuthorProfile);
export default injectSheet(styles)(AuthorProfileTranslated);
