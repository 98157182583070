import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { getUsername } from "../../../context/auth";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import BackButton from "../../../general/Navigation/components/BackButton";
import Sidebar from "../../../general/Navigation/Sidebar";
import Header from "../../../general/Navigation/Header";
import UploadProfilePicture from "../Components/UploadProfilePicture";
import XButton from "../../../general/Navigation/components/XButton";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      twitter: "no twitter account is connected",
      twitterInput: "",
      bio: "",
      bioInput: "",
      instagram: "",
      instagramInput: "",
    };
    this.handleInputBio = this.handleInputBio.bind(this);
    this.handleInputInstagram = this.handleInputInstagram.bind(this);
    this.handleInputTwitter = this.handleInputTwitter.bind(this);
    this.onSubmitTwitter = this.onSubmitTwitter.bind(this);
    this.onSubmitBio = this.onSubmitBio.bind(this);
    this.onSubmitAll = this.onSubmitAll.bind(this);
    this.onSubmitInstagram = this.onSubmitInstagram.bind(this);
  }

  async componentDidMount() {
    getUsername().then((name) => {
      this.setState({ username: name });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/twitter", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: name,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) this.setState({ twitter: res.data[0].twitter });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/instagram", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: name,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) this.setState({ instagram: res.data[0].instagram });
        });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/bio", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: name,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) this.setState({ bio: res.data[0].text });
        });
    });
    mixpanel.track("Screen_edit_profile");
  }

  handleInputTwitter(event) {
    this.setState({ twitterInput: event.target.value });
  }

  handleInputInstagram(event) {
    if(event.target.value.includes("@")) this.setState({ instagramInput: event.target.value.replace("@", "") });
    else this.setState({ instagramInput: event.target.value });
  }

  handleInputBio(event) {
    this.setState({ bioInput: event.target.value });
  }

  onSubmitTwitter() {
    let newTwitter = {
      author: this.state.username,
      twitter: this.state.twitterInput,
    };
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/twitter/add/", newTwitter, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data)
          this.setState({
            twitter: this.state.twitterInput,
            twitterInput: "",
          });
        else console.log("add twitter error");
      });
  }

  onSubmitInstagram() {
    let newInstagram = {
      author: this.state.username,
      instagram: this.state.instagramInput,
    };
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/instagram/add/", newInstagram, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data)
          this.setState({
            instagram: this.state.instagramInput,
            instagramInput: "",
          });
        else console.log("add instagram error");
      });
  }

  onSubmitBio() {
    let newBio = {
      author: this.state.username,
      text: this.state.bioInput,
    };
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/bio/add/", newBio, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data)
          this.setState({
            bio: this.state.bioInput,
            bioInput: "",
          });
        else console.log("add bio error");
      });
  }

  onSubmitAll() {
    if (this.state.bioInput.length > 0) this.onSubmitBio();
    if (this.state.twitterInput.length > 0) this.onSubmitTwitter();
    if (this.state.instagramInput.length > 0) this.onSubmitInstagram();
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <div className="noHeader">
          <Header />
        </div>
        <SettingsButton />
        <BackButton url={"/profile"} />
        <XButton url={"/profile"} />
        <div>
          <div
            style={{
              justifyContent: "center",
              verticalAlign: "middle",
              alignItems: "center",
              margin: "auto",
              maxWidth: 350,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("profile.editProfile")}</span>
            </div>
            <UploadProfilePicture name={this.state.username} />
          </div>
        </div>
        <div className={classes.margin}>
          <span className={classes.textSmall}>{t("profile.twitter")}</span>
          <div>
            <b className={classes.textSmaller}>{this.state.twitter}</b>
          </div>
          <input
            placeholder={t("profile.twitterInput")}
            className={classes.input}
            onChange={this.handleInputTwitter}
            maxLength={40}
          />
        </div>
        <div className={classes.margin}>
          <span className={classes.textSmall}>{t("profile.instagram")}</span>
          <div>
            <b className={classes.textSmaller}>{this.state.instagram}</b>
          </div>
          <input
            placeholder={t("profile.instagramInput")}
            className={classes.input}
            onChange={this.handleInputInstagram}
            maxLength={40}
          />
        </div>
        <div className={classes.margin}>
          <span className={classes.textSmall}>{t("profile.bio")}</span>
          <div>
            <b className={classes.textSmaller}>{this.state.bio}</b>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <textarea
              maxLength="140"
              placeholder={t("profile.bioInput")}
              value={this.state.bioInput}
              style={{ fontFamily: "Poppins", fontSize: 16, height: "10rem" }}
              className={classes.input}
              onChange={this.handleInputBio}
            />
          </div>
        </div>
        <button
          aria-label="Submit edits to profile"
          onClick={async () => {
            this.onSubmitAll();
          }}
          className={classes.submitButton}
        >
          {t("profile.saveButton")}
        </button>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    maxWidth: 450,
    paddingBottom: 100,
    minWidth: 300,
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: "white",
    textAlign: "center",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "100%",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  textSmall: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 11,
    textAlign: "center",
    textTransform: "uppercase",
  },
  textSmaller: {
    color: "rgba(116, 107, 131, 1)",
    fontSize: 15,
    textAlign: "center",
  },
  noDefaultButton: {
    margin: 0,
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginTop: 10,
    marginBottom: 5,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 20,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    paddingLeft: 5,
    width: "100%",
    webkitTextFillColor: "white",
  },
  margin: {
    marginTop: 15,
  },
  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: "90px",
      // marginBottom: 100,
      paddingBottom: 30,
      marginLeft: "300px",
      width: "500px",
      maxWidth: "500px",
    },
    submitButton: {
      width: "50%",
      marginLeft: "25%",
      marginTop: "10%",
    },
  },
  "@media screen and (min-width: 1060px)": {
    body: {
      width: "745px",
      maxWidth: "745px",
    },
    textSmall: {
      fontSize: 18,
    },
  },
  "@media screen and (min-width: 1111px)": {
    body: {
      width: "765px",
      maxWidth: "765px",
    },
  },
};

const EditProfileTranslated = withTranslation("common")(EditProfile);
export default injectSheet(styles)(EditProfileTranslated);
