import React, { Component } from "react";
import injectSheet from "react-jss";
import "../../../styling/title.css";
import { Link } from "react-router-dom";
import "../../../styling/Cookies.css";
import "../../../context/Global.css";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class RegisterButton extends Component {
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <div className={classes.register}>
        <div className={classes.borders}>
          <div className={classes.verticalLine} />
          <span className={classes.textBold}>{t("logIn.new")}</span>
          <div className={classes.verticalLine} />
        </div>
        <Link
          to="/register"
          onClick={() => {
            mixpanel.track("Click_register_button");
          }}
        >
          <button aria-label="Register" className={classes.submitButton} style={{ marginTop: 10, marginBottom: 10 }}>
            <span>{t("logIn.register")}</span>
          </button>
        </Link>
      </div>
    );
  }
}

const styles = {
  linkText: {
    color: "rgba(255, 255, 255, .45)",
    fontSize: 14,
    "&:hover": {
      color: "#C59CDC",
    },
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 40,
    marginBottom: 15,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 50px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
    "&:hover": {
      backgroundColor: "#87c9ff",
    },
  },
  borders: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  textBold: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 15,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
    fontWeight: 500,
  },
  verticalLine: {
    borderTopColor: "rgba(255, 255, 255, .2)",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    alignItems: "center",
    minWidth: "25%",
  },
  "@media screen and (min-width: 800px)": {
    submitButton: {
      width: "calc(100% - 200px)",
    },
    verticalLine: {
      minWidth: "18%",
    },
  },
};

const RegisterButtonTranslated = withTranslation("common")(RegisterButton);
export default injectSheet(styles)(RegisterButtonTranslated);
