import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import injectSheet from "react-jss";

class AddButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeElement: window.location.pathname,
    };
  }
  render() {
    const { classes } = this.props;

    return (
      <Link to="/record">
        <button
          aria-label="Link record"
          onClick={() => this.setState({ activeElement: "/record" })}
          className={classes.addButton}
        >
          <Plus className={classes.icon} style={this.state.activeElement === "/record" ? { fill: "white" } : {}} />
        </button>
      </Link>
    );
  }
}

const styles = {
  addButton: {
    position: "fixed",
    backgroundColor: "#87C9FF",
    textAlign: "center",
    bottom: "50px",
    right: "50px",
    overflow: "hidden",
    border: "none",
    color: "#180931",
    borderRadius: "50%",
    display: "flex",
    margin: "auto",
    height: "60px",
    width: "60px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    "&:hover": {
      boxShadow: "0px 0px 21px #C59CDC",
      color: "#C59CDC",
    },
  },
  icon: {
    width: "130px",
    height: "130px",
  },
};

export default injectSheet(styles)(AddButton);
