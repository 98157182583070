import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Search } from "react-feather";

class SearchButton extends Component {
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <Link to="/search">
        <div className={classes.button}>
          <Search className={classes.icon} />
          <span className={classes.text}>{t("search.title")}</span>
        </div>
      </Link>
    );
  }
}

const styles = {
  button: {
    verticalAlign: "middle",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    borderRadius: 10,
    maxWidth: 320,
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  icon: {
    color: "white",
    width: 16,
    margin: 10,
    marginRight: 5,
  },
  text: {
    color: "white",
    fontSize: 15,
    fontWeight: 400,
  },
};

const SearchButtonComponentTranslated = withTranslation("common")(SearchButton);
export default injectSheet(styles)(SearchButtonComponentTranslated);
