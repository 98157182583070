import React, { Component } from "react";
import injectSheet from "react-jss";
import "../../../styling/title.css";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import "../../../styling/Cookies.css";
import "../../../context/Global.css";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { Mail, Lock } from "react-feather";
import RegisterButton from "./RegisterButton";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class LogInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      name: "",
      disable: false,
    };
    this.signIn = this.signIn.bind(this);
    this.handleChangeInput1 = this.handleChangeInput1.bind(this);
    this.handleChangeInput2 = this.handleChangeInput2.bind(this);
  }

  async handleChangeInput1(event) {
    this.setState({ username: event.target.value.toLowerCase() });
  }

  handleChangeInput2(event) {
    this.setState({ password: event.target.value });
  }

  async signIn() {
    try {
      console.log("start log in");
      const user = await Auth.signIn(this.state.username.toLowerCase(), this.state.password);
      if (user) {
        this.props.setRedirect(true);
        Auth.currentSession().then((res) => {
          let idToken = res.getIdToken();
          let id = idToken.getJwtToken();
          localStorage.setItem("token", id);
        });
      }
      this.setState({ disabled: true });
    } catch (error) {
      switch (error.message) {
        case "Username should be an email.":
          alert(error.message);
          console.log("Username should be an email.");
          break;
        case "User is not confirmed.":
          alert(error.message);
          console.log("User is not confirmed. Please check your emails.");
          break;
        case "Incorrect username or password.":
          alert(error.message);
          console.log("Incorrect username or password.");
          break;
        case "User does not exist.":
          alert(error.message);
          console.log("User does not exist.");
          break;
        default:
          console.log(error.message);
      }
      this.setState({ disabled: false });
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <div>
        <form className={classes.form}>
          <div className={classes.input_container}>
            <Mail className={classes.input_image} />
            <input
              placeholder={t("logIn.inputEmail")}
              className={classes.input}
              autoCapitalize="none"
              onChange={this.handleChangeInput1}
            />
          </div>
          <div className={classes.input_container}>
            <Lock className={classes.input_image} />
            <input
              type="password"
              placeholder={t("logIn.inputPassword")}
              className={classes.input}
              onChange={this.handleChangeInput2}
            />
          </div>
          <Link to="/forgotpassword" onClick={() => mixpanel.track("Click_forgot_password")} className={classes.linkA}>
            <span className={classes.link}>{t("logIn.forgotPassword")}</span>
          </Link>
          <button
            onClick={(e) => {
              e.preventDefault();
              this.signIn();
              mixpanel.track("Click_signin", {
                user: this.state.username,
              });
            }}
            className={classes.submitButton}
            style={{ marginTop: 10, marginBottom: 5 }}
          >
            {t("logIn.submit")}
          </button>
          {/*<Link to="/" className={classes.twitter}>
            <Twitter className={classes.twitterLogo} />
            Log in with Twitter
          </Link>*/}
        </form>
        <RegisterButton />
      </div>
    );
  }
}

const styles = {
  link: {
    color: "rgba(255, 255, 255, .4)",
    borderBottomStyle: "solid",
    fontWeight: 500,
    borderBottomColor: "rgba(255, 255, 255, .4)",
    borderBottomWidth: 1,
    fontSize: 12,


  },
  linkA: {
    padding: "none",
  },
  twitter: {
    width: " calc(100% - 100px)",
    margin: "auto",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    "&:hover": {
      color: "#03A9F4",
    },
  },
  twitterLogo: {
    fill: "#03A9F4",
    color: "#03A9F4",
    paddingRight: "5px ",
  },
  form: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 20,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 45,
    width: "calc(100% - 100px)",
    color: "white",
    webkitTextFillColor: "white",
  },
  linkText: {
    color: "rgba(255, 255, 255, .45)",
    fontSize: 14,
    "&:hover": {
      color: "#C59CDC",
    },
  },
  input_container: {
    position: "relative",
    padding: 0,
    margin: 0,
    marginTop: 5,
  },
  input_image: {
    color: "white",
    position: "absolute",
    bottom: 17,
    left: 40,
    width: 20,
    height: 20,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 50px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  "@media screen and (min-width: 800px)": {
    link: {
      float: "initial",
    },
    linkA: {
      padding: "10px 0px 30px 0px",
    },
    twitter:{
      width:"auto",
    },
    submitButton: {
      width: "calc(100% - 200px)",
      marginTop: "100px",
      "&:hover": {
        backgroundColor: "rgba(135, 201, 255)",
      },
    },
  },
};

const LogInFormTranslated = withTranslation("common")(LogInForm);
export default injectSheet(styles)(LogInFormTranslated);
