import React, { Component } from "react";
import Card from "../Components/payments/Card";
import Form from "../Components/payments/Form";
import Machine from "../Components/payments/Machine";
import "../Components/payments/payment.css";
import Header from "../../../general/Navigation/Header";
import Sidebar from "../../../general/Navigation/Sidebar";
import Coupon from "../Components/Coupon";
import BackButton from "../../../general/Navigation/components/BackButton";
import SettingsButton from "../../../general/SettingsButton";
import { getUser } from "../../../context/auth";
import { Redirect } from "react-router-dom";

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flipped: false,
      checkout: true,
      processing: false,
      complete: false,
      number: "",
      name: "",
      month: "",
      year: "",
      cvv: "",
      redirect: false,
    };
  }

  populate(field) {
    this.setState(field);
  }
  refreshPage() {
    window.location.reload();
  }
  flip(bool) {
    this.setState({ flipped: bool });
  }

  process() {}

  async componentDidMount() {
    getUser().then(async (redirect) => {
      this.setState({ redirect: redirect });
    });
  }
  render() {
    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className="container">
        <div className="noHeader">
          <Header />
        </div>
        <div onClick={this.props.goBack}>
          <BackButton url={"/buydrops"} />
        </div>
        <SettingsButton />
        <Sidebar />
        <div className="containerForm">
          <h3 style={{ textAlign: "center" }}>Purchase drops now</h3>

          <Coupon dropAmount={this.props.amount} total={this.props.price} />
          <Form
            title={"Credit Card Details"}
            buttonName={"Buy Now "}
            showSave={true}
            processing={this.state.processing}
            complete={this.state.complete}
            onFormSubmit={() => {
              this.setState({ processing: true });

              setTimeout(() => {
                this.setState({ complete: true });
              }, 5000);
            }}
            onFormInput={this.populate.bind(this)}
            onFlip={this.flip.bind(this)}
          />
        </div>
        <div className="process">
          <Machine processing={this.state.processing} complete={this.state.complete} />
          <Card
            fields={this.state}
            flipped={this.state.flipped}
            processing={this.state.processing}
            complete={this.state.complete}
          />
        </div>
      </div>
    );
  }
}

export default Checkout;
