import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import injectSheet from "react-jss";
import axios from "axios";
import { shuffle } from "../../context/helper";
import TrendingHashtags from "./TrendingHashtags";
import TrendingCreators from "./TrendingCreators";
import FlatList from "flatlist-react";
import ListCard from "../../general/ListCard";
import { Search as SearchIcon } from "react-feather";
import mixpanel from "mixpanel-browser";
import BackButton from "../../general/Navigation/components/BackButton";
import Sidebar from "../../general/Navigation/Sidebar";
import Header from "../../general/Navigation/Header";
import { getUser, getUsername } from "../../context/auth";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      searchItems: [],
      size: 0,
      search: false,
      inHeader: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  async componentDidMount() {
    getUser().then(async (redirect) => {
      await getUsername().then(async (name) => {
        await axios
          .get(process.env.REACT_APP_BACKEND_URL + "/audios/all", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(async (res) => {
            await axios
              .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                params: {
                  username: name,
                },
              })
              .then(async (response) => {
                let array = shuffle(res.data);
                array = array.filter((item) => !response.data[0] || !response.data[0].authors.includes(item.author));
                array = array.filter((item) => item.curated);
                console.log(res.data);
                this.setState({
                  items: [...array],
                  size: array.length,
                });
              })
          });
      });
    });
    mixpanel.track("Screen_open_search");
  }

  handleKeyDown(event) {
    if (event.target.value.length === 0) {
      this.setState({
        search: false,
      });
    } else {
      this.setState({
        search: true,
      });
      const finalResult = this.state.items.filter(
        (item) => item.hashtags.length > 0 && item.hashtags.includes(event.target.value) === true
      );
      const result2 = this.state.items.filter(
        (item) =>
          item.author.length > 0 && item.author.toLowerCase().includes(event.target.value.toLowerCase()) === true
      );
      const result3 = this.state.items.filter(
        (item) => item.title.length > 0 && item.title.toLowerCase().includes(event.target.value.toLowerCase()) === true
      );
      this.setState({
        searchItems: finalResult.concat(result2).concat(result3),
      });
      console.log("done");
    }
  }

  renderItem = (item, idx) => {
    return <ListCard idx={idx} item={item} myVoiyals={this.state.searchItems} activeItem={[]} withNames={true} />;
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <BackButton url={"/listenlist"} />
        <div className={classes.title}>
          <span>{t("search.title")}</span>
        </div>
        <div
          style={{
            justifyContent: "center",
            verticalAlign: "middle",
            alignItems: "center",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <div className={classes.input_container}>
            <SearchIcon className={classes.input_image} />
            <input
              className={classes.input}
              type={"text"}
              placeholder={t("listen.searchHashtags")}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          {this.state.search && (
            <div>
              <div style={{ marginTop: 15 }}>
                <span className={classes.titleBlue}>Trending Hashtags</span>
                <TrendingHashtags items={this.state.items} />
              </div>
              <div style={{ marginTop: 30 }}>
                <span className={classes.titleBlue}>Trending Creators</span>
                <TrendingCreators items={this.state.items} />
              </div>
            </div>
          )}
          {this.state.search && (
            <div className={classes.list}>
              <ul style={{ margin: 0, minWidth: 320, paddingLeft: 0 }}>
                <FlatList
                  list={this.state.searchItems}
                  renderItem={this.renderItem}
                  renderWhenEmpty={() => <div className="textSmall">{t("general.emptyList")}</div>}
                  renderOnScroll
                />
              </ul>
            </div>
          )}
        </div>
        <div className="noHeader">
          <Header />
        </div>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  body: {
    margin: 10,
    minWidth: 320,
    maxWidth: 450,
    position: "relative",
    overflowX: "hidden",
    paddingBottom: 150,
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.9)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginBottom: 10,
    backgroundColor: "rgba(135, 201, 255, .2)",
    fontSize: 25,
    fontFamily: "Poppins",
    border: "none",
    borderRadius: 10,
    paddingLeft: 45,
    height: 45,
    width: "calc(100% - 50px)",
    webkitTextFillColor: "white",
  },
  input_container: {
    position: "relative",
    padding: 0,
    margin: 0,
  },
  input_image: {
    color: "white",
    position: "absolute",
    bottom: 15,
    left: 15,
    width: 25,
    height: 25,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 30,
    fontWeight: 600,
    paddingTop: 25,
    fontSize: 18,
    color: "rgba(255, 255, 255, .85)",
  },
  titleBlue: {
    color: "rgba(135, 201, 255, .85)",
    marginTop: 30,
    fontSize: 18,
    fontWeight: 600,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 14,
    textAlign: "center",
  },
  list: {
    marginTop: 20,
    // position: "fixed",
    top: "110px",
    left: "222px",
  },
  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: 120,
      width: "512px",
    },
  },
};

const SearchTranslated = withTranslation("common")(Search);
export default injectSheet(styles)(SearchTranslated);
