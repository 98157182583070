import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link, Redirect } from "react-router-dom";
import { MessageSquare, Book, HelpCircle, BookOpen, FileText, Folder, Trash2, XCircle, Globe } from "react-feather";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import BackButton from "../../../general/Navigation/components/BackButton";
import LogoutButton from "../../../general/LogoutButton";
import i18next from "i18next";
import Sidebar from "../../../general/Navigation/Sidebar";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class SettingsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      redirect: false,
    };
    this.setRedirect = this.setRedirect.bind(this);
  }

  componentDidMount() {
    mixpanel.track("Screen_open_settings");
  }

  setRedirect(value) {
    this.setState({
      redirect: value,
    });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className={classes.body}>
        <BackButton url={"/profile"} />
        <LogoutButton setRedirect={this.setRedirect} />
        <div>
          <div
            style={{
              justifyContent: "center",
              verticalAlign: "middle",
              alignItems: "center",
              margin: "auto",
              maxWidth: 350,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("settings.title")}</span>
            </div>
          </div>
        </div>
        <ul className={classes.list}>
          <li className={classes.listElement}>
            <MessageSquare className={classes.icon} />
            <a rel="noreferrer" href={t("common:settings.feedbackLink")} target="_blank">
              {t("settings.feedback")}
            </a>
          </li>
          <div className={classes.line} />
          <Link className={classes.listElement} to={"/rules"}>
            <div style={{ marginBottom: 10 }}>
              <Book className={classes.icon} />
              {t("settings.rules")}
            </div>
          </Link>
          {/* to translate the  text later  {t("settings.rules")} */}
          <div className={classes.line} />
          <Link className={classes.listElement} to={"/"}>
            <div style={{ marginBottom: 10 }}>
              <Book className={classes.icon} />
              Edit Credit Card information
            </div>
          </Link>
          {/* to translate the  text later  {t("settings.rules")} */}
          <div className={classes.line} />
          <Link className={classes.listElement} to={"/"}>
            <div style={{ marginBottom: 10 }}>
              <Book className={classes.icon} />
              Edit payout information
            </div>
          </Link>

          <div className={classes.line} />
          <li className={classes.listElement}>
            <HelpCircle className={classes.icon} />
            <a href="mailto:support@voiyal.com?subject=Help me voiyal team">{t("settings.support")}</a>
          </li>
          <div className={classes.line} />
          <div style={{ marginBottom: 10 }}>
            <BookOpen className={classes.icon} />
            <a className={classes.listElement} target="_blank" rel="noreferrer" href="https://blog.voiyal.com/">
              Blog
            </a>
          </div>
          <div className={classes.line} />
          <div style={{ marginBottom: 10 }}>
            <XCircle className={classes.icon} />
            <Link to="/blockedusers" className={classes.listElementSmall}>
              {t("settings.blockedUsers")}
            </Link>
          </div>
          <div className={classes.line} />
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Globe className={classes.icon} />
            <div
              onClick={() => {
                i18next.changeLanguage("en");
                localStorage.setItem("i18next", "en");
              }}
              className={classes.listElementSmall}
            >
              {t("settings.changeLngEnglish")}
            </div>
          </div>
          <div className={classes.line} />
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Globe className={classes.icon} />
            <div
              onClick={() => {
                i18next.changeLanguage("de");
                localStorage.setItem("i18next", "de");
              }}
              className={classes.listElementSmall}
            >
              {t("settings.changeLngGerman")}
            </div>
          </div>
          <div className={classes.line} />
          <div style={{ marginBottom: 10 }}>
            <FileText className={classes.icon} />
            <a className={classes.listElementSmall} target="_blank" rel="noreferrer" href="/terms-of-service.html">
              {t("settings.terms")}
            </a>
          </div>
          <div className={classes.line} />
          <div style={{ marginBottom: 10 }}>
            <Folder className={classes.icon} />
            <a className={classes.listElementSmall} target="_blank" rel="noreferrer" href="/imprint-data-policy.html">
              {t("settings.imprint")}
            </a>
          </div>
          <div className={classes.line} />
          <div style={{ marginBottom: 10 }}>
            <li className={classes.listElementSmallest}>
              <Link to="/deleteaccount">
                <Trash2 className={classes.icon} />
                {t("settings.delete")}
              </Link>
            </li>
          </div>
          <div className={classes.line} />
        </ul>
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 14,
    textAlign: "center",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgba(255, 255, 255, .2)",
    marginBottom: 20,
  },
  list: {
    margin: "auto",
    padding: 0,
    marginTop: 20,
  },
  listElement: {
    "&:hover": {
      color: "#C59CDC",
    },
    color: "rgba(255, 255, 255, .65)",
    fontSize: 15,
    fontWeight: 400,
    listStyleType: "none",
    marginBottom: 10,
  },
  noDefaultButton: {
    margin: 0,
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
  listElementSmall: {
    "&:hover": {
      color: "#C59CDC",
    },
    listStyleType: "none",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 12,
  },
  listElementSmallest: {
    "&:hover": {
      color: "#C59CDC",
    },
    listStyleType: "none",
    color: "rgba(255, 255, 255, .43)",
    fontSize: 10,
  },
  body: {
    position: "relative",
    maxWidth: 450,
    minWidth: 280,
    paddingBottom: 100,
    margin: "auto",
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: "white",
  },
  icon: {
    color: "rgba(255, 255, 255, .6)",
    width: 17,
    marginRight: 5,
    verticalAlign: "middle",
    height: 15,
  },
};

const SettingsScreenComponentTranslated = withTranslation("common")(SettingsScreen);
export default injectSheet(styles)(SettingsScreenComponentTranslated);
