import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import { Droplet } from "react-feather";
import { Link } from "react-router-dom";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ChatSettings extends Component {
  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.body}>
        <Link to={"/buydrops"}>
          <div className={classes.border}>
            <Droplet className={classes.dropsIcon} />
            <b
              className={classes.text}
              style={{
                verticalAlign: "middle",
                fontWeight: 600,
              }}
            >
              {this.props.tokens}
            </b>
          </div>
        </Link>
        <SettingsButton />
      </div>
    );
  };
}

const styles = {
  body: {
    width: "25%",
    height: "80vh",
  },
  border: {
    position: "absolute",
    right: "30px",
    top: "22px",
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#87C9FF",
    borderRadius: 8,
    maxWidth: 80,
    textAlign: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: -3,
    paddingLeft: 5,
    paddingRight: 8,
    display: "flex",
    flexDirection: "row",
  },
  dropsIcon: {
    color: "#87C9FF",
    fill: "#87C9FF",
    verticalAlign: "middle",
    width: 15,
    margin: 3,
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 500,
  },
  "@media screen and (max-width: 400px)": {
    body: {
      maxWidth: 20,
      height: 600,
    },
    text: {
      fontSize: 10,
    },
    dropsIcon: {
      width: 12,
    },
  },
  "@media screen and (min-width: 400px)": {
    body: {
      maxWidth: 20,
      height: 600,
    },
    text: {
      fontSize: 10,
    },
    dropsIcon: {
      width: 12,
    },
  },
  "@media screen and (min-width: 800px)": {
    body: {
      height: "100vh",
      display: "none",
      backgroundColor: "#180931",
    },
  },
};

const ChatSettingsTranslated = withTranslation("common")(ChatSettings);
export default injectSheet(styles)(ChatSettingsTranslated);
