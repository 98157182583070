import React, { Component } from "react";
import injectSheet from "react-jss";
import "../../styling/title.css";
import "../../context/Global.css";
import "../../styling/Cookies.css";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <footer className={classes.footer}>
        <div className="verticalLine" style={{ marginTop: 0 }} />
        <span className={classes.textSmall}>©2022 Voiyal GmbH</span>
        <div className="footer-section">
          <p style={{ margin: 0, marginBottom: 5 }}>
            <a className={classes.linkText} href="/terms-of-service.html" style={{ padding: 0 }} target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            <span className={classes.linkText}> • </span>
            <a className={classes.linkText} href="/imprint-data-policy.html" target="_blank" rel="noreferrer">
              Imprint/ Data Policy
            </a>
            <span className={classes.linkText}> • </span>
            <a className={classes.linkText} target="_blank" href="/contact.html" rel="noreferrer">
              Contact
            </a>
            <span className={classes.linkText}> • </span>
            <a className={classes.linkText} target="_blank" href="https://blog.voiyal.com" rel="noreferrer">
              Blog
            </a>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 5,
          }}
        >
          <a className={classes.linkText} href="https://voiyal.com/faq-en.html" target="_blank" rel="noreferrer">
            <b>FAQ</b>
            <span
              className="material-icons"
              style={{
                fontSize: 14,
                marginLeft: 3,
                color: "rgba(255, 255, 255, .45)",
                verticalAlign: "middle",
              }}
            >
              &#xe89e;
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const styles = {
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 10,
    padding: 2,
    textAlign: "center",
  },
  linkText: {
    color: "rgba(255, 255, 255, .35)",
    fontSize: 8,
    textAlign: "center",
    "&:hover": {
      color: "#C59CDC",
    },
  },
  footer: {
    width: "100%",
    position: "fixed",
    backgroundColor: "#1f094d",
    textAlign: "center",
    bottom: 0,
    left: 0,
    overflow: "hidden",
    paddingBottom: 5,
    fontSize: "14px",
    lineHeight: "15px",
  },
};

const FooterTranslated = withTranslation("common")(Footer);
export default injectSheet(styles)(FooterTranslated);
