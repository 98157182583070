import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link, Redirect } from "react-router-dom";
import { getUser, getUsername } from "../../context/auth";
import axios from "axios";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import UploadProfilePicture from "./Components/UploadProfilePicture";
import logo from "../../assets/images/Logo_V_round_final_transparent_.png";
import ProfileButton from "./Components/ProfileButton";
import ProfileInfo from "./Components/ProfileInfo";
import SettingsButton from "../../general/SettingsButton";
import mdh from "../../assets/images/logo_mdh_de.png";
import Sidebar from "../../general/Navigation/Sidebar";
import Header from "../../general/Navigation/Header";
import drops from "../../assets/icons/drops.svg";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      redirect: false,
      tokens: 0,
      hotvoice: false,
    };
    this.addTokens = this.addTokens.bind(this);
  }

  async componentDidMount() {
    if (window.performance) {
      if (window.location.search.length > 0) {
        let params = new URLSearchParams(window.location.search);
        axios
          .get(process.env.REACT_APP_BACKEND_URL + "/session", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            if (res.data.filter((e) => e.id === params.get("session_id")).length === 0) {
              this.addTokens(parseInt(params.get("amount")), params.get("session_id"));
              const newSession = {
                id: params.get("session_id"),
              };
              axios.post(process.env.REACT_APP_BACKEND_URL + "/session/add", newSession, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              });
            }
          });
      }
    }
    getUser().then((redirect) => {
      this.setState({ redirect: redirect });
    });
    mixpanel.track("Screen_open_my_profile");
    getUsername().then((name) => {
      this.setState({ username: name });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/tokens/get", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.state.username,
          },
        })
        .then((res) => {
          if (res.data) this.setState({ tokens: res.data });
          else console.log("token error");
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/hotvoice?user=" + this.state.username, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              this.setState({ hotvoice: res.data });
            });
        });
    });
  }

  async addTokens(amount, sessionId) {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/tokens/add/" + amount, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: { username: this.state.username, sessionId: sessionId },
      })
      .then((res) => {
        if (res.data) this.setState({ tokens: this.state.tokens + amount });
        else console.log("add token error");
      });
  }

  render() {
    const { classes } = this.props;

    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className={classes.body}>
        <div className={classes.innerBody}>
          <div className="noHeader">
            <Header />
          </div>
          <Link to="/listenlist">
            <div className={classes.logo}>
              <img alt="voiyal_logo" style={{ maxWidth: 50 }} src={logo} />
            </div>
          </Link>
          <SettingsButton />
          <UploadProfilePicture name={this.state.username} />
          <h6
            className={classes.text}
            style={
              this.state.hotvoice
                ? {
                    fontWeight: 500,
                    marginTop: 10,
                    marginBottom: 5,
                    color: "#87C9FF",
                  }
                : {
                    fontWeight: 500,
                    marginTop: 10,
                    marginBottom: 5,
                  }
            }
          >
            {this.state.hotvoice && (
              <span
                className="material-icons"
                style={{
                  fontSize: 18,
                  marginRight: 3,
                  color: "#87C9FF",
                  verticalAlign: "middle",
                }}
              >
                &#xef55;
              </span>
            )}
            {this.state.username}
          </h6>
          {this.state.username === "mydirtyhobby" && (
            <div className={classes.sponsored}>
              <span className={classes.textSmall}>Sponsored by</span>
              <img alt="sponsor" className={classes.sponsoredLogo} src={mdh} />
            </div>
          )}
          <div className={classes.tokens}>
            <img className={classes.dropsImg} src={drops} alt="" />
            <b
              className={classes.text}
              style={{
                verticalAlign: "middle",
                fontWeight: 600,
              }}
            >
              {this.state.tokens !== -1 ? this.state.tokens : 0}{" "}
            </b>
          </div>
          <ProfileButton />
          <ProfileInfo name={this.state.username} />
          <Sidebar />
          {/* <NavBar /> */}
        </div>
      </div>
    );
  }
}

const styles = {
  dropsImg: {
    color: "#87C9FF",
    fill: "#87C9FF",
    verticalAlign: "middle",
    width: 10,
    margin: "-1px 5px 0px 3px",
  },
  logo: {
    position: "absolute",
    left: "5px",
    top: "5px",
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    textAlign: "center",
    fontWeight: 500,
  },
  tokens: {
    marginTop: 10,
    marginBottom: 20,
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: "#87C9FF",
    borderRadius: 8,
    width:"80px" ,
    textAlign: "center",
    alignItems: "center",
    margin: "auto",
  },
  body: {
    paddingBottom: 100,
    position: "relative",
    overflowX: "hidden",
  },
  innerBody: {
    width: "auto",
    // maxWidth: "500px",
  },

  "@media screen and (max-width: 320px)": {
    body: {
      maxWidth: 280,
    },
  },
  sponsored: {
    borderRadius: 5,
    backgroundColor: "rgba(255, 255, 255, .2)",
    verticalAlign: "middle",
    width: 200,
    textAlign: "center",
    margin: "auto",
  },
  sponsoredLogo: {
    width: 100,
    verticalAlign: "middle",
    marginTop: 3,
    marginLeft: 4,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 10,
    textAlign: "center",
    verticalAlign: "middle",
  },
  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: "150px",
      paddingTop: "20px",
      paddingBottom: 30,
      marginLeft: "300px",
      width: "480px",
      maxWidth: "480px",
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "rgba(0, 0, 0, .25)",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
    },
    dropsImg: {
      margin: "6px 15px 5px 15px",
      width: 15,
    },
    innerBody: {
      width: "480px",
      maxWidth: "480px",
      marginLeft: "20px",
      marginRight: "20px",
    },
    logo: {
      display: "none",
    },
    text: {
      fontSize: 22,
    },
    tokens: {
      height: "45px",
      width: "79.26px",
      display: "flex",
      justifyContent: "center",
      borderRadius: "5px",
      paddingRight:"20px",
    },
  },
  "@media screen and (min-width: 1060px)": {
    startButton: {
      width: "700px",
    },
    body: {
      width: "700px",
      maxWidth: "700px",
    },
  },
  "@media screen and (min-width: 1111px)": {
    startButton: {
      width: "750px",
    },
    body: {
      width: "765px",
      maxWidth: "765px",
    },
  },
};

const ProfileScreenComponentTranslated = withTranslation("common")(ProfileScreen);
export default injectSheet(styles)(ProfileScreenComponentTranslated);
