import React, { Component } from "react";
import injectSheet from "react-jss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import BackButton from "../../../general/Navigation/components/BackButton";
import { Check } from "react-feather";
import Header from "../../../general/Navigation/Header";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Confirm extends Component {
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <div style={{ marginTop: 10 }}>
          <BackButton url={"/"} />
        </div>
        <Header />
        {/* <Link to="/">
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              marginTop: 10,
            }}
          >
            <img
              style={{ width: 120 }}
              src="https://d3bk8pb8wlf64b.cloudfront.net/Logo_GIF.gif"
              alt="loading..."
            />
          </div>
        </Link> */}
        <img
          id="1000263741_cpa_testing"
          style={{ width: 0, height: 0, border: 0 }}
          alt="tracking"
          src="https://ads.trafficjunky.net/tj_ads_pt?a=1000273371&member_id=1003364231&cb=1&cti=1&ctv=0&ctd=login"
        />
        <div style={{ textAlign: "center", marginBottom: 15, marginTop: 25 }}>
          <div className={classes.circle}>
            <Check className={classes.check} />
          </div>
          <h6
            className="text"
            style={{
              marginBottom: 5,
              marginTop: 15,
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            {t("register.confirmation.title")}
          </h6>
          <span className="text" style={{ maxWidth: 300, fontSize: 14, fontWeight: 600 }}>
            {t("register.confirmation.text1")}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={classes.textSmall}>{t("register.confirmation.text2")}</span>
          <Link to="/" onClick={() => mixpanel.track("Submit_confirm_signup")}>
            <button aria-label="Confirm register" className={classes.submitButton}>
              {t("register.confirmation.button")}
            </button>
          </Link>
          <span
            style={{
              marginTop: 15,
              fontSize: 10,
              marginLeft: 20,
              marginRight: 20,
            }}
            className={classes.textSmaller}
          >
            {t("register.confirmation.text3")}
          </span>
        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    maxWidth: 400,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: 50,
    fontWeight: "500",
    margin: "auto",
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
  },
  textSmaller: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 13,
    textAlign: "center",
    lineHeight: "15px",
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 14,
    textAlign: "center",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 40,
    marginBottom: 15,
    marginLeft: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  circle: {
    borderRadius: 100,
    width: 70,
    height: 70,
    backgroundColor: "rgba(135, 201, 255, 1)",
    textAlign: "center",
    margin: "0 auto",
  },
  check: {
    color: "white",
    width: 40,
    height: 40,
    verticalAlign: "middle",
    marginTop: 16,
  },
  "@media screen and (min-width: 800px)": {
    body: {
      margin: "0px 56px",
    },
  },
};

const ConfirmComponentTranslated = withTranslation("common")(Confirm);
export default injectSheet(styles)(ConfirmComponentTranslated);
