import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

import mixpanel from "mixpanel-browser";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Over18 extends Component {
  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.popup}>
        <div className={classes.popupInner}>
          <h1 style={{ marginTop: 30, marginBottom: 30 }}>18+</h1>
          <span className={classes.title}>{t("popup.text1")}</span>
          <br />
          <span className={classes.title}>{t("popup.text2")}</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <a className={classes.linkText} href="/terms-of-service.html" target="_blank" rel="noreferrer">
              <span className={classes.textSmall} style={{ textDecoration: "underline" }}>
                {t("popup.textTerms1")}
              </span>
              <span className={classes.textSmall}> {t("popup.textTerms2")}</span>
            </a>
          </div>
          <button
            aria-label="Submit over 18"
            className={classes.submitButton}
            style={{ margin: 0, marginTop: 20, marginBottom: 40, fontSize: 14 }}
            onClick={() => {
              this.props.setShowPopup(false);
              mixpanel.track("Submit_18_plus");
            }}
          >
            {t("popup.submit")}
          </button>
        </div>
      </div>
    );
  };
}

const styles = {
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 100,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    position: "absolute",
    borderRadius: 20,
    left: "5%",
    zIndex: 102,
    right: "5%",
    top: "25%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    maxWidth: 280,
    background: "#180931",
  },
  title: {
    margin: "auto",
    marginTop: 10,
    fontSize: 18,
    fontWeight: 600,
  },
  textSmall: {
    fontSize: 11,
    opacity: 0.5,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  "@media screen and (min-width: 800px)": {
    popupInner: {
      height: "328px",
      maxWidth: "512px",
    },
    submitButton: {
      width: "calc(100% - 200px)",
    },
  },
};

const Over18Translated = withTranslation("common")(Over18);
export default injectSheet(styles)(Over18Translated);
