import React, { Component } from "react";
import injectSheet from "react-jss";

class AudioPlayerPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.timeUpdate = this.timeUpdate.bind(this);
  }

  timeUpdate() {
    let player = document.getElementById("player");
    if (parseInt(player.currentTime) > 10) {
      player.pause();
      player.currentTime = 0;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.body}>
        <div style={{ display: "none" }}>
          <audio
            id="player"
            controls
            controlsList={"nodownload"}
            autoPlay={false}
            onTimeUpdate={this.timeUpdate}
            src={this.props.displayItems[this.props.index] ? this.props.displayItems[this.props.index].url : ""}
          />
        </div>
      </div>
    );
  }
}

const styles = {};

export default injectSheet(styles)(AudioPlayerPreview);
