import React, { Component } from "react";
import injectSheet from "react-jss";
import { getUsername } from "../../../context/auth";
import axios from "axios";
import Compressor from "compressorjs";
import { withTranslation } from "react-i18next";
import { Image } from "react-feather";
import mixpanel from "mixpanel-browser";
import { GlobalStoreContext } from "../../../context/globalStore";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class UploadProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.fileSelected = this.fileSelected.bind(this);
    this.editFile = this.editFile.bind(this);
  }
  static contextType = GlobalStoreContext;

  async fileSelected(e) {
    let file = e.target.files[0];
    let fileCompressed;
    new Compressor(file, {
      quality: 0.5,
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        fileCompressed = compressedResult;
      },
    });
    const [globalStore, setGlobalStore] = this.context;
    const { username } = globalStore;
    const fileName = Date.now() + username + file.name.replace(/\s/g, "");
    const contentType = file.type;

    const options = {
      params: {
        Bucket: "audiofans-pictures",
        Key: fileName,
        ContentType: contentType,
      },
      headers: {
        ContentType: contentType,
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios.get(process.env.REACT_APP_BACKEND_URL + "/profilepic/generate-put-url", options).then((res) => {
      const {
        data: { putURL },
      } = res;
      axios
        .put(putURL, fileCompressed, {
          headers: { ContentType: contentType },
        })
        .then((res) => {
          getUsername().then((name) => {
            const newPic = {
              author: this.props.name,
              fileUrl: "https://d3bk8pb8wlf64b.cloudfront.net/" + fileName,
              fileName: fileName,
            };
            setGlobalStore({
              picUrl: "https://d3bk8pb8wlf64b.cloudfront.net/" + fileName,
              picName: fileName,
            });
            axios
              .post(process.env.REACT_APP_BACKEND_URL + "/profilepic/add", newPic, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((res) => {
                console.log(res);
              })
              .catch((e) => console.log(e));
          });
        });
    });
  }

  async editFile(e) {
    const [globalStore] = this.context;
    // eslint-disable-next-line no-unused-vars
    const { picUrl, picName } = globalStore;
    const options = {
      params: {
        Bucket: "audiofans-pictures",
        Key: picName,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios.get(process.env.REACT_APP_BACKEND_URL + "/profilepic/generate-delete-url", options).then((res) => {
      console.log(res);
      const {
        data: { deleteURL },
      } = res;
      axios.delete(deleteURL).then((res) => {
        axios
          .delete(process.env.REACT_APP_BACKEND_URL + "/profilepic/delete", {
            params: {
              username: this.props.name,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res);
          });
      });
      this.fileSelected(e);
    });
  }

  render() {
    const [globalStore] = this.context;
    // eslint-disable-next-line no-unused-vars
    const { picUrl, picName } = globalStore;
    const { classes } = this.props;

    return (
      <div>
        {picUrl ? (
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              margin: "auto",
            }}
          >
            <img className={classes.profilePicImage} alt="Profile" src={picUrl} />
          </div>
        ) : (
          <div className={classes.profilePicUpload}>
            <label
              htmlFor="upload-photo"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 30,
              }}
            >
              <Image className={classes.icon} />
              <b style={{ color: "rgba(255, 255, 255, .85)" }}>{/* {t("profile.editImage")} */}</b>
            </label>
            <input
              id="upload-photo"
              type="file"
              accept="image/png, image/jpeg"
              // placeholder={t("profile.editImage")}
              style={{
                opacity: 0,
                position: "absolute",
                zIndex: -1,
              }}
              onChange={async (e) => {
                await this.fileSelected(e);
                mixpanel.track("Upload_ProfilePic");
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  profilePicUpload: {
    width: 40,
    height: 40,
    borderStyle: "solid",
    borderColor: "rgba(255, 255, 255, .85)",
    borderWidth: 2,
    backgroundColor: "#87C9FF",
    borderRadius: 100,
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    marginTop: 21,
    marginRight: 14,
    display: "flex",
    justifyContent: "center",
  },
  profilePicImage: {
    width: "50px",
    height: "50px",
    objectFit: "cover",
    borderRadius: "50%",
    marginTop: 16,
    marginRight: 14,
  },
  icon: {
    alignSelf: "center",
    fontSize: 40,
    color: "rgba(255, 255, 255, .85)",
    marginBottom: "25px",
  },
  editButton: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "white",
    backgroundColor: "#87C9FF",
    width: 30,
    height: 30,
    borderRadius: 50,
    verticalAlign: "middle",
    position: "absolute",
    marginTop: -30,
    left: "55%",
  },
  "@media screen and (max-width: 1000px)": {
    // profilePicImage: {
    //   width: "30px",
    //   height: "30px",
    // },
  },
};

const UploadProfilePictureComponentTranslated = withTranslation("common")(UploadProfilePicture);
export default injectSheet(styles)(UploadProfilePictureComponentTranslated);
