import React, { Component } from "react";
import "./payment.css";

export class Machine extends Component {
  render() {
    return (
      <div
        className={`noHeader machine ${this.props.processing ? "active" : ""} ${this.props.complete ? "complete" : ""}`}
      >
        <div className="body">
          <div className="visor">
            <div className="screen"></div>
          </div>
          <div className="keypad">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}

export default Machine;
