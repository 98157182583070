import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { Trash2 } from "react-feather";
import mixpanel from "mixpanel-browser";
import Dropdown from "react-dropdown";
import axios from "axios";
import { getDate, sToTime } from "../../../context/helper";
import PreviewPlayer from "./PreviewPlayer";
import getBlobDuration from "get-blob-duration";
import { TagsInput } from "react-tag-input-component";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      titleInput: "",
      titleError: "Gib einen Titel ein",
      descriptionInput: "",
      lng: "other",
      disabled: true,
    };
    this.onUpload = this.onUpload.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeLng = this.handleChangeLng.bind(this);
  }

  async onUpload() {
    let duration = await getBlobDuration(this.props.blobURL);
    if (Math.round(duration) <= 540) {
      this.setState({ disabled: true });
      const contentType = this.props.audio_file.type;
      let fileEnding = contentType === "audio/x-m4a" ? ".m4a" : ".mp3";
      const fileName = this.state.titleInput.replace(/[^a-zA-Z0-9]/g, "") + Date.now() + fileEnding;
      const file = this.props.audio_file;
      this.props.setPopup(true);
      const options = {
        params: {
          Key: fileName,
          ContentType: contentType,
        },
        headers: {
          ContentType: contentType,
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.REACT_APP_BACKEND_URL + "/audios/generate-put-url", options).then((res) => {
        const {
          data: { putURL },
        } = res;
        let array = this.state.tags.map(item => item.replace("#", ""));
        axios.put(putURL, file, { headers: { ContentType: contentType } }).then(async () => {
          const newAudio = {
            title: this.state.titleInput,
            author: this.props.username,
            url: "https://d3c13ht4jhjgar.cloudfront.net/" + fileName,
            date: getDate(),
            duration: sToTime(Math.round(duration)),
            hashtags: array,
            curated: false,
            description: this.state.descriptionInput,
            lng: this.state.lng,
          };
          await axios
            .post(process.env.REACT_APP_BACKEND_URL + "/audios/add", newAudio, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(async (res) => {
              let body = {
                fileName: fileName,
              };
              await axios
                .post(process.env.REACT_APP_BACKEND_URL + "/audios/add-trailer", body, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  this.props.redirect();
                });
            })
            .catch((e) => console.log(e));
          await axios.get(process.env.REACT_APP_BACKEND_URL + "/cio/addRec", {
            params: {
              author: this.props.username,
              id: this.props.username + Date.now(),
            },
          });
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/chatengine/checkname/" + this.state.username, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(async (res) => {
              if (!res.data) {
                axios
                  .get(process.env.REACT_APP_BACKEND_URL + "/chatengine/createuser", {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                  .then(async (res) => {
                    console.log(res.data);
                  });
              }
            });
        });
      });
    }
  }

  handleChangeInput(event) {
    this.setState({
      titleInput: event.target.value,
    });
    if (event.target.value.length > 0) {
      this.setState({ titleError: "", disabled: false });
    } else {
      this.setState({ titleError: "Gib einen Titel ein", disabled: true });
    }
  }

  handleChangeDescription(event) {
    this.setState({
      descriptionInput: event.target.value,
    });
  }

  handleChangeLng(option) {
    console.log(option);
    this.setState({
      lng: option.label,
    });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.body}>
        <span style={{ display: "block" }} className={classes.title}>
          {t("record.uploadText")}
        </span>
        <div className={classes.playPreview}>
          <div style={{height:"93px"}}> <PreviewPlayer blobURL={this.props.blobURL} /></div>
          <Trash2 className={classes.deleteButton} />
          <button
            aria-label="Delete recording"
            className={classes.noDefaultButton}
            onClick={() => {
              this.props.delete();
              mixpanel.track("Delete_Recording");
            }}
          >

          </button>
        </div>
        <form className={classes.form}>
          <div className={classes.column}>
            <span className={classes.text}>{t("record.title")}</span>
            <input
              maxLength="50"
              placeholder={t("record.input")}
              className={classes.input}
              onChange={this.handleChangeInput}
            />
            <span
              style={{
                fontWeight: "bold",
                color: "red",
                height: 5,
              }}
            >
              {this.state.titleError ? t("record.error") : ""}
            </span>
          </div>
          <div className={classes.column} style={{ marginTop: 15 }}>
            <span className={classes.text}>{t("record.titleTags")}</span>
          </div>
          <div>
            <TagsInput
              style
              value={this.state.tags}
              onChange={(value) => {this.setState({tags: value})}}
              name="hashtags"
              placeHolder="Choose tags"
            />
          </div>
          <div className={classes.column}>
            <span className={classes.text} style={{ marginBottom: 10 }}>
              {t("record.lng")}
            </span>
            <Dropdown
              options={[
                t("common:record.dropdown1"),
                t("common:record.dropdown2"),
                t("common:record.dropdown4"),
              ]}
              onChange={this.handleChangeLng}
              value={0}
              placeholder={t("record.lng")}
            />
          </div>
          <div className={classes.column}>
            <span className={classes.text}>{t("record.description")}</span>
            <textarea
              maxLength="120"
              style={{ marginTop: 10 }}
              placeholder={t("record.descriptionInput")}
              value={this.props.description}
              className={classes.input}
              onChange={this.handleChangeDescription}
            />
          </div>
          <button
            aria-label="Upload audio"
            disabled={this.props.disabled}
            onClick={(e) => {
              e.preventDefault();
              this.onUpload();
              mixpanel.track("Start_Uploading");
            }}
            className={classes.submitButton}
          >
            {t("record.submit")}
          </button>
        </form>
      </div>
    );
  }
}

const styles = {
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "100%",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.1)",
    },
  },
  title: {
    marginBottom: 15,
    fontSize: 22,
    fontWeight: 600,
    color: "white",
  },
  deleteButton: {
    color: "white",
    position: "absolute",
    right: 15,
  },
  tags: {
    maxWidth: "420px",
  },
  // "react-tags": {
  //   maxWidth: "420px",
  // },
  text: {
    fontSize: 22,
    color: "white",
    fontWeight: 600,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    marginTop: 25,
    marginBottom: 25,
  },
  body: {
    display: "relative",
    maxWidth: 450,
    minWidth: 280,
    margin: "auto",
  },
  input: {
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.5)",
      fontWeight:500
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    paddingLeft: 10,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 20,
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    // width: "100%",
    webkitTextFillColor: "white",
  },
  playPreview: {
    background: "#180931",
    boxShadow: "0px 0px 10px #C59CDC",
    borderRadius: "20px",
    padding: "30px",
  },
  "@media screen and (max-width: 800px)": {
    deleteButton: {
      padding: "0px 15px",
    },
  },
  "@media screen and (min-width: 800px)": {
    playPreview: {
      padding: "45px",
    },
    deleteButton: {
      padding: "0px 30px",
    },
    tags: {
      maxWidth: "765px",
      width: "765px",
    },
    "react-tags": {
      width: "765px",
      maxWidth: "765px",
    },
    body: {
      display: "relative",
      maxWidth: "765px",
      minWidth: 280,
      margin: "auto",
    },
    submitButton: {
      width: "calc(100% - 400px)",
      // marginTop: "150px",
    },
  },
  "@media screen and (min-width: 1100px)": {
    playPreview: {
      padding: "15px 148px",
      width:"469px",
      height:"124px"
    },
    deleteButton: {
      padding: "0px 133px",
    },
  },
};

const FormComponentTranslated = withTranslation("common")(Form);
export default injectSheet(styles)(FormComponentTranslated);
