import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { ChatEngine } from "react-chat-engine";
import { getUser, getUsername } from "../../context/auth";
import axios from "axios";
import ChatList from "./Components/ChatList";
import ChatFeed from "./Components/ChatFeed";
import ChatSettings from "./Components/ChatSettings";
import mixpanel from "mixpanel-browser";
import Sidebar from "../../general/Navigation/Sidebar";
import Header from "../../general/Navigation/Header";
import { Redirect } from "react-router-dom";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      creators: [],
      tokens: 0,
      blockedUsers: [],
      reload: false,
      redirect: false,
    };
    this.getBlocks = this.getBlocks.bind(this);
    this.setTokens = this.setTokens.bind(this);
  }

  async componentDidMount() {
    getUser().then(async (redirect) => {
      this.setState({ redirect: redirect });

      await getUsername().then(async (name) => {
        this.setState({ username: name });
        await axios
          .get(process.env.REACT_APP_BACKEND_URL + "/chatengine/checkname/" + name, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(async (res) => {
            if (!res.data) {
              axios
                .get(process.env.REACT_APP_BACKEND_URL + "/chatengine/createuser", {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then(async (res) => {
                  console.log(res.data);
                });
            }
          });
        axios
          .get(process.env.REACT_APP_BACKEND_URL + "/tokens/get/", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              username: name,
            },
          })
          .then((res) => {
            if (res.data) this.setState({ tokens: res.data });
            else console.log("token error");
          });
        axios
          .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              username: name,
            },
          })
          .then((res) => {
            if (res.data.length > 0) this.setState({ blockedUsers: res.data[0].authors });
          });
      });
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + "/audios/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let creators = [];
          res.data.forEach((item) => {
            if (!creators.includes(item.author)) creators.push(item.author);
          });
          this.setState({ creators: creators });
        });
    });
    mixpanel.track("Screen_open_chat");
  }

  setTokens() {
    if (this.state.tokens > 0) {
      this.setState({
        tokens: this.state.tokens - 1,
      });
      mixpanel.track("Screen_send_message_payed");
    }
  }

  getBlocks() {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/report/blockedUsers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: this.state.username,
        },
      })
      .then((res) => {
        if (res.data.length > 0) this.setState({ blockedUsers: res.data[0].authors });
      });
  }

  render() {
    const { classes } = this.props;

    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className={classes.body}>
        <div className="noHeader">
          <Header tokenAmount={this.state.tokens} />
        </div>
        {this.state.username.length > 0 && (
          <ChatEngine
            className={{ backgroundColor: "#1D0D41", width: "1300px" }}
            projectID={process.env.REACT_APP_CHATENGINE_ID}
            userName={this.state.username}
            userSecret={this.state.username + process.env.REACT_APP_SECRET}
            renderChatSettings={(chatAppState) => (
              <ChatSettings chatAppState={chatAppState} tokens={this.state.tokens} />
            )}
            renderChatFeed={(chatAppState) => (
              <ChatFeed
                getBlocks={this.getBlocks}
                setTokens={this.setTokens}
                chatAppState={chatAppState}
                blockedUsers={this.state.blockedUsers}
                username={this.state.username}
                creators={this.state.creators}
                className={classes.chatFeed}
              />
            )}
            renderChatList={(chatAppState) => (
              <ChatList
                openChat={this.props.match.params.username}
                setNotSet={this.setNotSet}
                chatAppState={chatAppState}
                blockedUsers={this.state.blockedUsers}
                username={this.state.username}
                creators={this.state.creators}
              />
            )}
          />
        )}
        <Sidebar />
        {/* <NavBar /> */}
      </div>
    );
  }
}

const styles = {
  "ce-chat-engine": {
    backgroundColor: "#1D0D41",
  },
  "ce-chat-feed-column": {
    width: "400px",
    flex: "0",
    maxWidth: "400px",
  },
  body: {
    // maxWidth: 650,
    backgroundColor: "#1D0D41",
    boxSizing: "border-box",
  },
  "@media screen and (max-width: 400px)": {
    body: {
      // maxWidth: 300,
      overflow: "hidden",
      height:'100vh',
    },
  },
  "@media screen and (min-width: 800px)": {
    body: {
      position: "absolute",
      top: "130px",
      left: "330px",
      maxWidth: "800px",
      overflow: "visible",
      // width: "1300px",
    },
  },
};

const ChatComponentTranslated = withTranslation("common")(Chat);
export default injectSheet(styles)(ChatComponentTranslated);
