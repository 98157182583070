import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { LogOut } from "react-feather";

import mixpanel from "mixpanel-browser";
import { signOut } from "../context/auth";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class LogoutButton extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.settings}>
        <button
          aria-label="Log out"
          className={classes.noDefaultButton}
          onClick={async () => {
            await signOut();
            this.props.setRedirect(true);
          }}
        >
          <LogOut
            style={{
              maxWidth: 30,
              color: "rgba(255, 255, 255, .85)",
            }}
          />
        </button>
      </div>
    );
  }
}

const styles = {
  settings: {
    position: "absolute",
    right: "5px",
    top: "28px",
  },
  noDefaultButton: {
    margin: 0,
    padding: 0,
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    color: "rgba(255, 255, 255, .45)",
    fontSize: 18,
  },
};

const LogoutButtonComponentTranslated = withTranslation("common")(LogoutButton);
export default injectSheet(styles)(LogoutButtonComponentTranslated);
