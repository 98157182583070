import React from "react";
import { Droplet } from "react-feather";
import "./index.css";

const Coupon = ({ dropAmount, total }) => {
  const vatCalculate = (value) => {
    return (value * 0.19).toFixed(2) || 0;
  };
  const calculateGrandTotal = (total, vat) => {
    return (+total + +vat).toFixed(2);
  };
  return (
    <widget type="ticket" className="--flex-column">
      <div className="top --flex-column">
        <div className="deetz --flex-row-j!sb">
          <div className="event --flex-column">
            <div className="date">
              {dropAmount} <Droplet className="dropsIcon" />{" "}
            </div>
            {/* <div className="location -bold">1$</div> */}
          </div>
          <div className="price --flex-column">
            {/* <div className="label">Price</div> */}
            <div className="cost -bold">{total} $</div>
          </div>
        </div>

        <div className="deetz --flex-row-j!sb">
          <div className="event --flex-column">
            <div className="date">VAT</div>
            {/* <div className="location -bold">1$</div> */}
          </div>
          <div className="price --flex-column">
            {/* <div className="label">Price</div> */}
            <div className="cost -bold">{vatCalculate(total)} $</div>
          </div>
        </div>
      </div>
      <div className="rip"></div>
      <div className="bottom --flex-row-j!sb">
        <div className="deetz --flex-row-j!sb">
          <div className="event --flex-column">
            <div className="date">Total</div>
            {/* <div className="location -bold">1$</div> */}
          </div>
          <div className="price --flex-column">
            {/* <div className="label">Price</div> */}
            <div className="cost -bold">{calculateGrandTotal(total, vatCalculate(total))} $</div>
          </div>
        </div>
      </div>
    </widget>
  );
};

export default Coupon;
