import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { ChevronLeft, MoreVertical, Slash } from "react-feather";
import axios from "axios";
import NewChatForm from "./NewChatForm";
import ProfilePictureAuthor
  from "../../Profile/Components/ProfilePictureAuthor";
import noImg from "../../../assets/images/noimg.png";
import { Link } from "react-router-dom";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class ChatHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      picUrl: "",
    };
    this.block = this.block.bind(this);
  }

  async componentWillReceiveProps(nextProps, nextContext){
    let name =
      nextProps.chat.people[0].person.username !== nextProps.username
        ? nextProps.chat.people[0].person.username
        : nextProps.chat.people[1].person.username;
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
        params: {
          username: name,
        },
      })
      .then((res) => {
        res.data.length > 0 ? this.setState({ picUrl: res.data[0].fileUrl }) : this.setState({ picUrl: noImg });
      });
  }

  async block(){
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/report/addUser",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: this.props.username,
            author:
              this.props.chat.people[0].person.username !== this.props.username
                ? this.props.chat.people[0].person.username
                : this.props.chat.people[1].person.username,
          },
        }
      )
      .then(async (res) => {
        this.props.getBlocks();
      });
  }

  setActive(item){
    if (this.state.notSet) {
      if (this.state.id === 0) {
        this.setState({ id: item.id });
      }
    }
  }

  setNotSet(){
    this.setState({
      notSet: false,
    });
  }

  getMessageAuthor(item){
    if (item.people[0] && item.people[1]) {
      if (item.people[0].person.username !== this.props.username) {
        return item.people[0].person.username;
      } else {
        return item.people[1].person.username;
      }
    } else {
      return "";
    }
  }

  render = () => {
    const { classes } = this.props;
    let map = this.props.chatAppState.chats;

    return (
      <div className={classes.header}>
        {this.props.chat !== null && (
          <div>
            <div className={classes.headerContentDesktop}>
              <div className={classes.headerContent}>
                <div
                  style={{

                    flexDirection: "row",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                  <div className={classes.burgerMenu}
                       onClick={() => this.props.setShowList()}>
                    <ChevronLeft className={classes.burgerMenuIcon} />
                  </div>
                  {!this.props.showList ? (
                    <div className={classes.headerContent1}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                        <div style={{ display: 'flex' }}>
                          {this.state.picUrl ? (
                            <div style={{ verticalAlign: "middle" }}>
                              <img alt={" "} src={this.state.picUrl}
                                   className={classes.mainPic} />
                            </div>
                          ) : (
                            <div class="center">
                              <img
                                alt={""}
                                style={{ opacity: 0.5 }}
                                src={"https://voiyal.de/voiyal.png"}
                                className={classes.mainPic}
                              />
                            </div>
                          )}
                          <div>
                            <a
                              className="noDefaultButton"
                              href={
                                window.location.origin + this.props.chat.people[0].person.username !== this.props.username
                                  ? "/authorprofile/" + this.props.chat.people[0].person.username
                                  : "/authorprofile/" + this.props.chat.people[1].person.username
                              }
                            >
                                <span className={classes.title}>
                                  {this.props.chat.people[0].person.username !== this.props.username
                                    ? this.props.chat.people[0].person.username
                                    : this.props.chat.people[1].person.username}
                                </span>
                              <br />
                            </a>
                          </div>
                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                          <div>
                            <div className={classes.button}
                                 onClick={this.block}>
                              <Slash className={classes.icon} />
                              <span
                                style={{ fontSize: 10 }}
                                className={classes.text}>Block</span>
                            </div>
                          </div>
                          <Link to='/settings' className={classes.noMobile} style={{marginTop: 3}}>
                            <MoreVertical />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span
                      style={{ marginTop: 6, fontWeight: 800, fontSize: 16 }}
                      className={classes.title}>
                      Your chats
                    </span>
                  )}
                </div>
                <div className={classes.verticalLine}></div>
                {this.props.showList && (
                  <div className={classes.burgerMenu}>
                    <div className={classes.messageList}>
                      {this.props.chatAppState.chats !== null && (
                        <div
                          style={{
                            marginTop: 10,
                            height: "100%",
                            paddingBottom: 20,
                            marginLeft: 10,
                            overflow: "hidden",
                            overflowY: "scroll",
                            position: "relative",
                            zIndex: 10,
                          }}
                        >
                          {Object.keys(map).map(
                            (keyName) =>
                              !this.props.blockedUsers.includes(this.getMessageAuthor(map[keyName])) && (
                                <li
                                  onClick={() => {
                                    this.props.chatAppState.setActiveChat(map[keyName].id);
                                  }}
                                  className={classes.listElement}
                                  key={map[keyName].id}
                                >
                                  <span
                                    className={classes.text}
                                    style={
                                      map[keyName].id === this.props.chatAppState.activeChat
                                        ? {
                                          color: "#87C9FF",
                                          width: "100%",
                                          cursor: "pointer"
                                        }
                                        : { width: "100%", cursor: "pointer" }
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer"
                                      }}
                                      onClick={() => {
                                        this.props.chatAppState.setActiveChat(map[keyName].id);
                                      }}
                                    >
                                      <ProfilePictureAuthor
                                        name={this.getMessageAuthor(map[keyName])} />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "-webkit-fill-available",
                                          marginRight: 5,
                                        }}
                                      >
                                        <div style={{
                                          display: "flex",
                                          flexDirection: "column"
                                        }}>
                                          <div>{this.getMessageAuthor(map[keyName])}</div>
                                          <div
                                            style={{
                                              fontWeight: 500,
                                              fontSize: " 11px",
                                              lineHeight: "16px",
                                              color: "rgba(255, 255, 255, 0.5)",
                                            }}
                                          >
                                            {/* {map[keyName].last_message.sender === this.getMessageAuthor(map[keyName]) && */}
                                            {map[keyName].last_message.text}
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            style={{
                                              color: "rgba(255, 255, 255, 0.5)",
                                            }}
                                          >
                                            {map[keyName].last_message.created.slice(11, 16)}
                                          </div>
                                          {map[keyName].people.find((obj) => {
                                              return obj.person.username === this.props.username;
                                            }).last_read !== map[keyName].last_message.id &&
                                            map[keyName].last_message.created &&
                                            <div className="newSms">N</div>}
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </li>
                              )
                          )}
                        </div>
                      )}
                    </div>
                    <div style={{
                      position: "relative",
                      zIndex: 10,
                      marginLeft: 10
                    }}>
                      <NewChatForm creds={this.props.chatAppState.creds}
                                   creators={this.props.creators} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.headerContentMobile}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  verticalAlign: "middle",
                }}
              >
                <div className={classes.burgerMenu}
                     onClick={() => this.props.setShowList()}>
                  <ChevronLeft className={classes.burgerMenuIcon} />
                </div>
                {!this.props.showList ? (
                  <div className={classes.headerContent}>
                    {this.state.picUrl ? (
                      <div style={{ verticalAlign: "middle" }}>
                        <img alt={" "} src={this.state.picUrl}
                             className={classes.mainPic} />
                      </div>
                    ) : (
                      <div class="center">
                        <img
                          alt={""}
                          style={{ opacity: 0.5 }}
                          src={"https://voiyalvoices.de/voiyal.png"}
                          className={classes.mainPic}
                        />
                      </div>
                    )}
                    <div>
                      <a
                        className="noDefaultButton"
                        href={
                          window.location.origin + this.props.chat.people[0].person.username !== this.props.username
                            ? "/authorprofile/" + this.props.chat.people[0].person.username
                            : "/authorprofile/" + this.props.chat.people[1].person.username
                        }
                      >
                        <span className={classes.title}>
                          {this.props.chat.people[0].person.username !== this.props.username
                            ? this.props.chat.people[0].person.username
                            : this.props.chat.people[1].person.username}
                        </span>
                        <br />
                      </a>
                      {/*<div className={classes.button} onClick={this.block}>
                       <Slash className={classes.icon} />
                       <span
                       style={{ fontSize: 8 }}
                       className={classes.text}>Block</span>
                       </div>*/}
                    </div>
                  </div>
                ) : (
                  <span style={{ marginTop: 6, fontWeight: 800, fontSize: 16 }}
                        className={classes.title}>
                    Your chats
                  </span>
                )}
              </div>
              <div className={classes.verticalLine}></div>
              {this.props.showList && (
                <div className={classes.burgerMenu}>
                  <div className={classes.messageList}>

                    {this.props.chatAppState.chats !== null && (
                      <div
                        style={{
                          marginTop: 10,
                          height: '100%',
                          paddingBottom: 20,
                          marginLeft: 10,
                          overflow: "hidden",
                          overflowY: "scroll",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        {Object.keys(map).map(
                          (keyName) =>
                            !this.props.blockedUsers.includes(this.getMessageAuthor(map[keyName])) && (
                              <li
                                onClick={() => {
                                  this.props.chatAppState.setActiveChat(map[keyName].id);
                                }}
                                className={classes.listElement}
                                key={map[keyName].id}
                              >
                                <span
                                  className={classes.text}
                                  style={
                                    map[keyName].id === this.props.chatAppState.activeChat
                                      ? {
                                        color: "#87C9FF",
                                        width: "100%",
                                        cursor: "pointer"
                                      }
                                      : { width: "100%", cursor: "pointer" }
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      this.props.chatAppState.setActiveChat(map[keyName].id);
                                    }}
                                  >
                                    <ProfilePictureAuthor
                                      name={this.getMessageAuthor(map[keyName])} />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "-webkit-fill-available",
                                        marginRight: 5,
                                      }}
                                    >
                                      <div style={{
                                        display: "flex",
                                        flexDirection: "column"
                                      }}>
                                        <div>{this.getMessageAuthor(map[keyName])}</div>
                                        <div
                                          style={{
                                            fontWeight: 500,
                                            fontSize: " 11px",
                                            lineHeight: "16px",
                                            color: "rgba(255, 255, 255, 0.5)",
                                          }}
                                        >
                                          {/* {map[keyName].last_message.sender === this.getMessageAuthor(map[keyName]) && */}
                                          {map[keyName].last_message.text}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            color: "rgba(255, 255, 255, 0.5)",
                                          }}
                                        >
                                          {map[keyName].last_message.created.slice(11, 16)}
                                        </div>
                                        {map[keyName].people.find((obj) => {
                                            return obj.person.username === this.props.username;
                                          }).last_read !== map[keyName].last_message.id &&
                                          map[keyName].last_message.created &&
                                          <div className="newSms">N</div>}
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </li>
                            )
                        )}
                      </div>
                    )}
                  </div>
                  <div style={{
                    position: "relative",
                    zIndex: 10,
                    marginLeft: 10
                  }}>
                    <NewChatForm creds={this.props.chatAppState.creds}
                                 creators={this.props.creators} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
}

const styles = {
  header: {
    marginLeft: -5,
  },
  mobileBurger: {
    height: '60vh'
  },
  icon: {
    width: 12,
    height: 12,
    verticalAlign: "middle",
    color: "rgba(255,255,255,0.5)",
  },
  noMobile: {
    display: 'none'
  },
  title: {
    color: "white",
    fontSize: 15,
    fontWeight: 500,
    verticalAlign: "middle",
  },
  headerContentMobile: {
    display: "none",
    verticalAlign: "middle",
  },
  headerContentDesktop: {
    display: "block",
    backgroundColor: "transparent",
  },
  burgerMenu: {
    display: "block",
    verticalAlign: "middle",
    position: 'absolute',
  },
  button: {
    cursor: "pointer",
    borderWidth: 1,
    borderRadius: 10,
    borderStyle: "solid",
    width: 80,
    textAlign: "center",
    alignSelf: "center",
    verticalAlign: "middle",
    borderColor: "rgba(255,255,255,0.5)",
    fontSize: 10,
  },
  text: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 10,
    fontWeight: 500,
    marginTop: 8,
    marginLeft: 5,
  },
  blocked: {
    color: "rgba(255, 50, 0, 0.7)",
    fontSize: 12,
    fontWeight: 500,
    marginTop: 8,
    marginLeft: 5,
  },
  mainPic: {
    width: 30,
    height: 30,
    marginTop: 0,
    borderRadius: 50,
    margin: 2,
    marginRight: 15,
    verticalAlign: "middle",
    objectFit: "cover",
  },
  headerContent: {
    display: "block",
    lexDirection: "row",
    justifyContent: "space-between",
    verticalAlign: "middle",
  },
  headerContent1: {
    paddingLeft: '25px',
    // display: "flex",
    lexDirection: "row",
    justifyContent: "space-between",
    verticalAlign: "middle",
  },
  burgerMenuIcon: {
    color: "white",
    width: 25,
    height: 25,
    marginTop: 5,
    marginRight: 4,
  },
  listElement: {
    listStyleType: "none",
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    borderBottom: "  1px solid rgba(255, 255, 255, 0.2)",
    cursor: "pointer",
  },
  textList: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    fontWeight: 600,
  },
  verticalLine: {
    borderTopColor: "rgba(255, 255, 255, .2)",
    borderTopWidth: 1,
    width: "100%",
    borderTopStyle: "solid",
    alignItems: "center",
    marginTop: 10,
  },

  messageList: {
    height: "60vh",
  },
  "@media screen and (max-width: 400px)": {
    header: {
      height: 70,
    },
    headerContent: {
      display: "flex",
      justifyContent: "start",
      marginLeft: '25px',
    },
    mainPic: {
      width: 30,
      height: 30,
      borderRadius: 30,
      objectFit: "cover",
    },
    title: {
      fontSize: 15,
    },

    button: {
      marginTop: 6,
      fontSize: 8,
      padding: 1,
      paddingTop: 4,
    },
    burgerMenu: {
      display: "block",
    },
    messageList: {
      height: "60vh",
      marginLeft: 20,
      position: "relative",
      zIndex: 10,
    },
    headerContentMobile: {
      display: "block",
    },
    headerContentDesktop: {
      display: "none",
    },
  },
  "@media screen and (min-width: 800px)": {
    header: {
      backgroundColor: "#180931",
    },
    burgerMenu: {
      display: "none",
    },
    noMobile: {
      display: 'block'
    },
    headerContentDesktop: {
      backgroundColor: "#1d0d41",
    },
    headerContent: {
      backgroundColor: "#1f094d",
      // display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      verticalAlign: "middle",
    },
    verticalLine: {
      display: "none",
    },
    mainPic: {
      width: 61,
      height: 61,
      objectFit: "cover",
    },
  },
};

const ChatHeaderTranslated = withTranslation("common")(ChatHeader);
export default injectSheet(styles)(ChatHeaderTranslated);
