import { Auth } from "aws-amplify";
import axios from "axios";

export async function getUser() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    return !user.username;
  } catch (e) {
    console.log(e);
    return true;
  }
}

export async function forgotPassword(username) {
  return Auth.forgotPassword(username).catch((err) => alert("No Account found. Try another email address."));
}

export async function changePassword(username, code, new_password) {
  Auth.forgotPasswordSubmit(username, code, new_password)
    .then((data) => alert("You changed your password. Try logging in with the new password."))
    .catch((err) => alert("Something went wrong"));
}

export async function signOut() {
  try {
    localStorage.removeItem("token");
    await Auth.signOut({ global: true });
  } catch (error) {
    await Auth.signOut();
    console.log("error signing out: ", error);
  }
}

export async function getUsername() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    Auth.currentSession().then((res) => {
      let idToken = res.getIdToken();
      let id = idToken.getJwtToken();
      localStorage.setItem("token", id);
    });
    return user.attributes.name;
  } catch (error) {
    console.log("error signing out: ", error);
    if (window.location.pathname !== "/" && window.location.pathname !== "/confirmmail") window.location.href = "/";
  }
}

export async function deleteAccount() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    console.log(user);
    if (user) {
      axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/username/delete", {
          params: {
            username: user.attributes.name,
          },
        })
        .then((res) => {
          console.log(res.data);
          axios
            .delete(process.env.REACT_APP_BACKEND_URL + "/emails/delete", {
              params: {
                email: user.attributes.email,
              },
            })
            .then((res) => {
              axios.delete(process.env.REACT_APP_BACKEND_URL + "/audios/oneuser/delete", {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                params: {
                  username: user.attributes.name,
                },
              });
              console.log(res.data);
            });
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/cio/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/bio/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res.data);
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/instagram/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res.data);
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/myvoiyals/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/profilepic/delete", {
          params: {
            username: user.attributes.name,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/report/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(async (res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/tokens/deleteTokenAll", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(async (res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/showrules/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(async (res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/tutorial/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(async (res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/lastlogindrops/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/transactions/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/twitter/delete", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + "/chatengine/delete/user", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("done");
        });
      await user.deleteUser();
    }
  } catch (error) {
    console.log("error signing out: ", error);
  }
}
