import React, { Component } from "react";
import injectSheet from "react-jss";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      rating: 0,
      rated: false,
      displayRating: false,
    };
    this.changeRating = this.changeRating.bind(this);
  }

  componentDidMount() {
    let audio = document.getElementById("audio");
    audio.addEventListener(
      "error",
      function (e) {
        let noSourcesLoaded = this.networkState === HTMLMediaElement.NETWORK_NO_SOURCE;
        if (noSourcesLoaded) console.log("could not load audio source", e.currentTarget.error.code);
      },
      true
    );
  }

  changeRating(newRating) {
    if (!this.state.rated) {
      this.setState({
        rating: newRating,
        rated: true,
      });
      if (newRating > 3) this.props.increaseRating();
      else this.props.decreaseRating();
    }
  }

  displayRatingFalse() {
    this.setState({
      displayRating: false,
    });
  }

  stopAudio() {
    let audio = document.getElementById("audio");
    audio.pause();
  }

  displayRatingTrue() {
    this.setState({
      displayRating: true,
    });
  }

  resetRating() {
    this.setState({
      rating: 0,
      rated: false,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.body}>
        <audio
          id="audio"
          onTimeUpdate={(time) => {
            if (this.props.updateTime) {
              this.props.updateTime(time);
              console.log(time);
            }
          }}
          controls
          controlsList={"nodownload"}
          autoPlay={true}
          className={classes.audioPlayer}
          src={this.props.activeItem.url}
        />
      </div>
    );
  }
}

const styles = {
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 22,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
    fontWeight: 700,
  },
  audioPlayer: {
    marginTop: 5,
    marginBottom: 5,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 18,
    textAlign: "center",
    fontWeight: 800,
  },
  body: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "none",
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
};

export default injectSheet(styles)(AudioPlayer);
