import React, { Component } from "react";
import injectSheet from "react-jss";
import "../../../styling/title.css";
import { changePassword, forgotPassword } from "../../../context/auth";
import { Lock, Mail } from "react-feather";
import { withTranslation } from "react-i18next";
import Footer from "../../../general/Navigation/Footer";
import Header from "../../../general/Navigation/Header";
import OtpInput from "react-otp-input";

class Forgotpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 2,
      username: "",
      code: "",
      new_password: "",
      redirect: false,
    };
    this.handleInput1 = this.handleInput1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInput3 = this.handleInput3.bind(this);
    this.onSubmit1 = this.onSubmit1.bind(this);
    this.onSubmit2 = this.onSubmit2.bind(this);
  }
  handleChange = (code) => this.setState({ code });

  handleInput1(event) {
    this.setState({ username: event.target.value });
  }
  // old handle for code
  // handleInput2(event) {
  //   this.setState({ code: event.target.value });
  // }

  handleInput3(event) {
    this.setState({ new_password: event.target.value });
  }

  async onSubmit1() {
    const res = await forgotPassword(this.state.username.toLowerCase());
    if (res) {
      this.setState({ step: 1 });
    }
  }

  async onSubmit2() {
    await changePassword(this.state.username, this.state.code, this.state.new_password);
    this.setState({ redirect: true });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <div className={classes.body}>
        <Header />
        {/* <BackButton url={"/"} />
        <Link to="/">
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <img
              style={{ width: 120 }}
              src="https://d3bk8pb8wlf64b.cloudfront.net/Logo_GIF.gif"
              alt="loading..."
            /> */}
        {/* </div>
        </Link> */}
        <div style={{ marginBottom: 10, marginLeft: 12 }}>
          <h6 className={classes.text} style={{ marginBottom: 5 }}>
            Forgot password?
          </h6>
        </div>
        {this.state.step === 2 ? (
          <div style={{ textAlign: "center" }}>
            <span className={classes.textSmall} style={{ marginLeft: 10, display: "inline-block" }}>
              Enter your email address to reset password
            </span>
            <div className={classes.input_container} style={{ marginTop: 35 }}>
              <Mail className={classes.input_image} />
              <input
                placeholder={t("register.forgotpassword.input1")}
                className={classes.input}
                onChange={this.handleInput1}
                style={{ color: "white" }}
              />
            </div>
            <button
              aria-label="Forgot password submit"
              onClick={(e) => {
                e.preventDefault();
                this.onSubmit1();
              }}
              className={classes.submitButton}
            >
              {t("register.forgotpassword.submit1")}
            </button>
          </div>
        ) : (
          <div className={classes.column}>
            <span className={classes.textSmall} style={{ marginBottom: 20 }}>
              Check you inbox, we have just send you your recovery code.
            </span>
            <div className={classes.input_container}>
              <Mail className={classes.input_image} />

              <input
                placeholder={t("register.forgotpassword.input1")}
                className={classes.input}
                onChange={this.handleInput1}
                style={{ color: "white" }}
              />
            </div>
            <span className={classes.helpText}>Check you inbox, we have just send you your recovery code.</span>
            <div className={classes.input_container}>
              <span className={classes.textSmall} style={{ paddingLeft: "10px" }}>
                {t("register.forgotpassword.input2")}
              </span>
              <OtpInput
                value={this.state.code}
                className={classes.inputOtp}
                onChange={this.handleChange}
                numInputs={6}
                separator={false}
                isInputNum={true}
                containerStyle={{ backgroundColor: "transparent", marginLeft: "10px" }}
                inputStyle={classes.otpInput}
                focusStyle={{ color: "#ffffff" }}
              />
              {/* old input recovery  code */}
              {/* <input
                placeholder={t("register.forgotpassword.input2")}
                className={classes.input}
                onChange={this.handleInput2}
              /> */}
            </div>
            <div className={classes.input_container}>
              <Lock className={classes.input_image} />
              <input
                type="password"
                placeholder={t("register.forgotpassword.input3")}
                className={classes.input}
                onChange={this.handleInput3}
              />
            </div>
            <span style={{ fontSize: 10, color: "white", opacity: 0.5 }}>{t("register.step3.error1")}</span>
            <button
              aria-label="Submit new password"
              onClick={(e) => {
                e.preventDefault();
                this.onSubmit2();
              }}
              className={classes.submitButton}
            >
              {t("register.forgotpassword.submit2")}
            </button>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

const styles = {
  body: {
    paddingBottom: 130,
    position: "relative",
    overflowX: "hidden",
    marginLeft: 0,
    marginRight: 10,
    maxWidth: 450,
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  textSmaller: {
    fontSize: 15,
    fontWeight: 500,
    marginTop: 15,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    marginLeft: 10,
    marginBottom: 10,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 15,
    fontWeight: 500,
  },
  helpText: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 15,
    fontWeight: 500,
    margin: "10px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },

  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginBottom: 10,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 25,
    color: 'white',
    fontFamily: "Poppins",
    paddingTop: 5,
    paddingBottom: 8,
    border: "none",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 45,
    width: "calc(100% - 60px)",
    webkitTextFillColor: "white",
  },
  inputOtp: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    fontSize: 25,
    fontFamily: "Poppins",
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
    borderRadius: 10,
    marginLeft: 0,
    marginRight: 5,
    paddingLeft: 0,
    width: "calc(100% - 60px)",
    webkitTextFillColor: "white",
  },
  otpInput: {
    color: "#ffffff",
    width: "100%!important",
    height: "40px",
    borderRadius: 10,
    backgroundColor: "transparent",
    border: "0px",
  },
  input_container: {
    position: "relative",
    textAlign: "initial",
    padding: 0,
    margin: 0,
  },
  input_image: {
    color: "white",
    position: "absolute",
    top: 15,
    left: 23,
    width: 20,
    height: 20,
  },
  "@media screen and (min-width: 800px)": {
    submitButton: {
      width: "calc(100% - 200px)",
    },
    input: {
      maxWidth: "450px",
    },
    inputOtp: {
      marginRight: 20,
    },
    body: {
      fontSize: "15px",
      maxWidth: "512px",
      paddingBottom: "120px",
      width: "512px",
      position: "absolute",
      margin: " 0px -256px",
      top: "97px",
      left: "50%",
    },
    text: {
      width: "512px",
      textAlign: "center",
      fontSize: "22px",
    },
    otpInput: {
      width: "56px!important",
      height: "45px",
    },
    helpText: {
      marginBottom: "70px",
      marginTop: "30px",
      marginLeft: "60px",
      marginRight: "60px",
    },
  },
};

const ForgotpasswordComponentTranslated = withTranslation("common")(Forgotpassword);
export default injectSheet(styles)(ForgotpasswordComponentTranslated);
