import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { sToTime, format } from "../../../context/helper";
import { PlayCircle, StopCircle, Square } from "react-feather";
import getBlobDuration from "get-blob-duration";
import next from "../../../assets/icons/forward.svg";
import back from "../../../assets/icons/backward.svg";
import playButton from "../../../assets/icons/playbtn.svg";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class PreviewPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      duration: "00:00",
      time: 0,
      startTime: 0,
      progressBar: 0,
      audio: {},
    };
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.updateTime = this.updateTime.bind(this);
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ startTime: 0 });
    if (nextProps.blobURL.length > 0 && !this.props.duration) {
      await this.stop();
      let time = await getBlobDuration(nextProps.blobURL);
      let duration = format(time);
      this.setState({
        duration: duration,
      });
    } else {
      await this.stop();
      if (this.props.duration) {
        this.setState({ duration: this.props.duration, time: 0 });
        await this.play();
      }
    }
  }

  async updateTime() {
    if (this.state.playing) {
      let player = document.getElementById("player");
      const currTime = player.currentTime;
      if (!this.props.duration) {
        let duration = await getBlobDuration(this.props.blobURL);
        this.setState({
          time: currTime,
          progressBar: currTime / duration,
        });
      } else {
        let mili = parseInt(this.props.duration.split(":")[1]);
        mili += parseInt(this.props.duration.split(":")[0]) * 60;
        let value = mili;
        this.setState({
          time: currTime,

          progressBar: currTime / value,
        });
      }
    }
  }

  async play() {
    let player = document.getElementById("player");
    player.currentTime = 0;
    await player.play();
    this.setState({ playing: true });
  }

  async stop() {
    let player = document.getElementById("player");
    await player.pause();
    player.currentTime = 0;
    this.setState({ playing: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ display: "relative" }}>
        <audio
          id="player"
          src={this.props.blobURL}
          style={{ display: "none" }}
          onTimeUpdate={(time) => this.updateTime(time)}
        />
        <div>
          <progress style={{ width: "100%" }} value={this.state.progressBar} max="0.98"></progress>
        </div>
        <div className={classes.player}>
          <span className={classes.textSmall}>{this.state.playing ? sToTime(this.state.time.toFixed()) : "00:00"}</span>
          <div style={{ position: "relative" }}>
            <img
              onClick={() => {
                this.props.next();
              }}
              src={next}
              alt=""
              className={classes.arrowL}
            />
            <img
              onClick={() => {
                this.props.previous();
              }}
              src={back}
              alt=""
              className={classes.arrowR}
            />
            {!this.state.playing ? (
              <>
                <PlayCircle
                  onClick={async () => {
                    await this.play();
                  }}
                  style={{ width: 60, height: 60, fill: "#87C9FF" }}
                  className={classes.iconLarge}
                />
                <img
                  src={playButton}
                  alt=""
                  onClick={async () => {
                    await this.play();
                  }}
                  style={{ width: 60, height: 60, fill: "#87C9FF" }}
                  className={classes.iconPlay}
                />
              </>
            ) : (
              <>
                <StopCircle
                  onClick={async () => {
                    await this.stop();
                  }}
                  style={{ width: 60, height: 60, fill: "#87C9FF" }}
                  className={classes.iconLarge}
                />
                <div className={classes.iconStop}>
                  <Square
                    onClick={async () => {
                      await this.stop();
                    }}
                    style={{ width: 22, height: 22, fill: "white" }}
                  />
                </div>
              </>
            )}
          </div>
          <span className={classes.textSmall}>{this.state.duration}</span>
        </div>
      </div>
    );
  }
}

const styles = {
  arrowL: {
    position: "absolute",
    width: 15,
    top: 29,
    right: "-40px",
    cursor: "pointer",
    display: "none",
  },
  arrowR: {
    position: "absolute",
    width: 15,
    top: 29,
    left: "-40px",
    cursor: "pointer",
    display: "none",
  },
  player: {
    height: 80,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    verticalAlign: "middle",
    margin: "auto",
  },
  progressBarUnactive: {
    marginTop: 20,
    width: 280,
    borderRadius: 10,
    borderWidth: 2,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    borderStyle: "solid",
    borderColor: "rgba(255, 255, 255, .2)",
  },
  progressBarActive: {
    marginTop: -1,
    width: 280,
    borderRadius: 10,
    borderWidth: 3,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    borderStyle: "solid",
    borderColor: "#87C9FF",
    position: "absolute",
  },
  textSmall: {
    color: "white",
    fontSize: 11,
    marginTop: 30,
    fontWeight: 600,
    textAlign: "center",
    verticalAlign: "middle",
    width: 30,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  iconLarge: {
    color: "white",
    verticalAlign: "middle",
    margin: 10,
    cursor: "pointer",
  },
  iconPlay: {
    display: "none",
  },
  iconStop: {
    display: "none",
  },
  "@media screen and (min-width: 800px)": {
    
    iconStop: {
      backgroundColor: "#87C9FF",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "60px",
      height: "60px",
      color: "white",
      borderRadius: "50%",
      marginTop: "10px",
    },
    iconPlay: {
      width: "60px",
      height: "60px",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fill: "#87C9FF",
      borderRadius: "50%",
      marginTop: "10px",
    },
    iconLarge: {
      display: "none",
    },
    arrowL: {
      display: "block",
    },
    arrowR: {
      display: "block",
    },
    progressBarUnactive: {
      width: "auto",
    },
    progressBarActive: {
      width: "auto",
    },
    player: {
      width: "auto",
      height: 80,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      verticalAlign: "middle",
      margin: "auto",
    },
  },
};

const PreviewPlayerTranslated = withTranslation("common")(PreviewPlayer);
export default injectSheet(styles)(PreviewPlayerTranslated);
