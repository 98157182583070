import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class PopupBuy extends Component {
  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.popup}>
        <div className={classes.popupInner}>
          <h4>{this.props.displayItems[this.props.index].duration.split(":")[0] === "0" &&
          parseInt(this.props.displayItems[this.props.index].duration.split(":")[1]) < 30 ? t("listen.popupfree") : t("listen.popup")}</h4>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              aria-label="Submit Buy"
              className="submitButton"
              onClick={async () => {
                await this.props.setActiveItem(this.props.displayItems[this.props.index]);
                this.props.showPopup(false);
                mixpanel.track("Click_buy_story");
              }}
            >
              {this.props.displayItems[this.props.index].duration.split(":")[0] === "0" &&
              parseInt(this.props.displayItems[this.props.index].duration.split(":")[1]) < 30 ? "Play" : t("listen.popupSubmit")}
            </button>
            <button
              aria-label="Cancle Buy"
              className="submitButton"
              style={{
                opacity: 0.6,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 10,
              }}
              onClick={() => {
                this.props.showPopup(false);
                mixpanel.track("Click_cancel_buy_story");
              }}
            >
              {t("listen.popUpCancel")}
            </button>
          </div>
        </div>
      </div>
    );
  };
}

const styles = {
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 100,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    position: "absolute",
    borderRadius: 20,
    left: "10%",
    zIndex: 102,
    right: "10%",
    top: "10%",
    bottom: "40%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    background: "#180931",
  },
  "@media screen and (min-width: 800px)": {
    popup: {
      top: "200px",
      width: "100%",
    },
    popupInner: {
      width: "30%",
      maxWidth: "511px",
      maxHeight: "447px",
      padding: "20px",
      marginLeft: "36.5%",
      minWidth: "300px",
    },
  },
};

const PopupBuyTranslated = withTranslation("common")(PopupBuy);
export default injectSheet(styles)(PopupBuyTranslated);
