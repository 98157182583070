import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";

class Upload extends Component {
  render = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.popup}>
        <div className={classes.popupInner}>
          <div
            style={{
              width: 35,
              height: 35,
              margin: "auto",
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            <svg viewBox="0 0 128 128">
              <g fill="#FFFFFF">
                <path
                  d="M31.6 8.98a4.02 4.02 0 0 0 1.7 5.3l6.12 3.22a52.56 52.56 0 1 0 63.13 10.73L96 33.97a43.88 43.88 0 1 1-53-8.5l-3.65 7.4a4.02 4.02 0 0 0 1.72 5.3 3.73 3.73 0 0 0 5.13-1.67l7.66-15.63a4.18 4.18 0 0 0-1.75-5.4L36.74 7.32a3.73 3.73 0 0 0-5.13 1.66z"
                  fill="#ffffff"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1800ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <span className={classes.textSmall}>{t("donationpopup.upload2")}</span>
          </div>
          <h6
            style={{
              marginBottom: 5,
              fontSize: 16,
              opacity: 0.6,
            }}
          >
            {t("donationpopup.upload3")}
          </h6>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              aria-label="Close popup"
              className="submitButton"
              style={{
                margin: 0,
                marginTop: 20,
                fontSize: 15,
                color: "white",
              }}
              onClick={() => this.props.setPopup(false)}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };
}

const styles = {
  popup: {
    textAlign: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    color: "white",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 100,
    backgroundColor: "rgba(0,0,0, 0.5)",
  },
  popupInner: {
    zIndex: 102,
    position: "absolute",
    borderRadius: 20,
    left: "10%",
    right: "10%",
    top: "10%",
    bottom: "30%",
    margin: "auto",
    padding: 20,
    fontSize: 20,
    background: "#180931",
  },
  "@media screen and (min-width: 800px)": {
    popupInner: {
      top: "180px",
      left: "calc(50% - 92px)",
      right: "calc(50% - 404px)",
      bottom: "30%",
      margin: "auto",
    },
  },
};

const UploadTranslated = withTranslation("common")(Upload);
export default injectSheet(styles)(UploadTranslated);
