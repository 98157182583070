import React, { Component } from "react";
import { displayDate } from "../context/helper";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import { Play } from "react-feather";

class ListCardMyVoiyals extends Component {
  render() {
    const { classes } = this.props;

    return (
      <li key={this.props.item.idx} className={this.props.active ? classes.listCardActive : classes.listCard}>

        <span style={{ position: "absolute", fontSize:"11", color:"white", fontWeight:600,display:"flex" }} className={classes.textSmall}>
          {this.props.item.duration}
          <div style={{
                width: "17px",
                backgroundColor: "#87C9FF",
                height: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                borderRadius: "50%",
                marginTop: "5px",
                marginLeft:"5px"
              }}>
                <Play
                  onClick={async () => {
                    await this.play();
                  }}
                  style={{ width: 16, height: 14, fill: "white", marginLeft: "5px", marginRight:"3px" }}
                />
              </div>
        </span>
        <div className={classes.date}>
          <span className={classes.textSmaller}>{displayDate(this.props.item.date)}</span>
        </div>
        <button
          aria-label="Play audio"
          className={classes.noDefaultButton}
          style={{
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "80%",
          }}
          onClick={() => this.props.setActiveItem(this.props.item)}
        >

          <span className={classes.text}>{this.props.item.title}</span>
          <b style={{ marginTop: 8, fontSize:"11px", fontWeight:500, color:"rgba(255, 255, 255, .5)" }} className={classes.textSmall}>
            {this.props.item.author}
          </b>
          {this.props.item.hashtags && this.props.item.hashtags.length > 0 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                marginTop: 8,
              }}
            >
              {this.props.item.hashtags.slice(0, 5).map((hashtag) => (
                <p className={classes.textSmaller} style={{ marginRight: 3, marginTop:0, marginBottom:0}}>
                  [{hashtag}]
                </p>
              ))}
            </div>
          )}
        </button>
      </li>
    );
  }
}

const styles = {
  date: {
    position: "absolute",
    top: 5,
    right: 0,
    marginRight: 5,
    marginBottom: 5,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
  },
  listCard: {
    display: "inline-block",
    width: "40%",
    marginRight: "2%",
    marginLeft: "2%",
    height: 160,
    marginBottom: 20,
    padding: 8,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    borderRadius: 10,
    position: "relative",
    "&:hover": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
    "&:active": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
  },
  listCardActive: {
    display: "inline-block",
    width: "40%",
    marginRight: "2%",
    marginLeft: "2%",
    height: 160,
    marginBottom: 20,
    padding: 8,
    textAlign: "left",
    listStyleType: "none",
    borderRadius: 10,
    position: "relative",
    boxShadow: "0px 0px 10px #C59CDC",
    backgroundColor: "#180931",
  },
  listCardHot: {
    display: "inline-block",
    width: "40%",
    height: 170,
    marginRight: "2%",
    marginLeft: "2%",
    marginBottom: 20,
    padding: 8,
    textAlign: "left",
    listStyleType: "none",
    backgroundColor: "rgba(135, 201, 255, 0.2)",
    borderRadius: 5,
    position: "relative",
    boxShadow: "0px 0px 2rem #C59CDC",
    "&:hover": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
    "&:active": {
      boxShadow: "0px 0px 10px #C59CDC",
      backgroundColor: "#180931",
    },
  },
  textSmaller: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 11,
    fontWeight: 500,
  },
  textSmall: {
    color: "white",
    fontSize: 11,
    fontWeight: 500,
  },
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 15,
    fontWeight: 600,
    textAlign: "left",
  },
  price: {
    left: 5,
    borderWidth: 1,
    bottom: 5,
    position: "absolute",
    borderColor: "#87C9FF",
    borderStyle: "solid",
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 5,
  },
  "@media screen and (min-width: 800px)": {
    listCard: {
      marginLeft: 0,
      marginRight: 0,
      width: "46.5%",
      marginBottom: "25px",
      borderRadius:"10px",
      height:"162px",
    },
    listCardActive: {
      marginLeft: 0,
      marginRight: 0,
      width: "46.5%",
      marginBottom: "25px",
      height:"162px",
      borderRadius:"10px",
    },
    price: {
      left: 15,
      bottom: 15,
      width: 25,
    },
    date: {

      top: 12,
      right: 15,
    },
    textSmall: {
      top: 15,
      left: 15,
    },
    text:{
    marginTop:8

    }
  },
};

const ListCardMyVoiyalsTranslated = withTranslation("common")(ListCardMyVoiyals);
export default injectSheet(styles)(ListCardMyVoiyalsTranslated);
