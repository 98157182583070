import React, { Component } from "react";
import { getUser, getUsername } from "../../../context/auth";
import axios from "axios";
import { withTranslation } from "react-i18next";
import injectSheet from "react-jss";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import BackButton from "../../../general/Navigation/components/BackButton";
import Sidebar from "../../../general/Navigation/Sidebar";
import Header from "../../../general/Navigation/Header";
import { Redirect } from "react-router-dom";
import passpay from "../../../assets/images/passpay.png";
import { getTransactions } from "../../../context/api";
import { GlobalStoreContext } from "../../../context/globalStore";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Payout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      dropsAmount: 0,
      drops: 0,
      showHistory: false,
      payoutsHistory: [],
      redirect: false,
      redirectUrl: "/",
      ageVerify: false,
      loading: true,
      disabled: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlePayout = this.handlePayout.bind(this);
  }
  static contextType = GlobalStoreContext;
  async componentDidMount() {
    const [globalStore] = this.context;
    const { isVerified, isHotVoice } = globalStore;
    getUser().then(async (redirect) => {
      const username = await getUsername();
      if (!isVerified) window.location.href = "/verifyage";
      const tokens = await getTransactions(username);
      this.setState({ username, dropsAmount: tokens, isHotVoice, loading: false });
    });
  }

  async handleClick() {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/masspay/getUserHistory", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.setState({ payoutsHistory: res.data });
      });
  }

  async handlePayout() {
    this.setState({ disabled: true });
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/masspay/load",
        {
          drops: this.state.drops,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        alert("Transaction completed!");
        this.setState({
          disabled: false,
          dropsAmount: this.state.dropsAmount - this.state.drops,
        });
        mixpanel.track("Payout_success");
      })
      .catch((e) => {alert(e?.response?.data?.message || "Error payout")
      this.setState({disabled:false});})
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/transactions/addpayouttospread",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            name: this.state.username,
            amount: this.state.drops / 100,
          },
        }
      );
      await axios.get(process.env.REACT_APP_BACKEND_URL + "/cio/payout", {
        params: {
          author: this.state.username,
          id: this.state.username + Date.now(),
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    if (this.state.loading === true) {
      return (
        <>
          <Header />
          <Sidebar />
        </>
      );
    } else
      return this.state.redirect ? (
        <Redirect to={{ pathname: this.state.redirectUrl }} />
      ) : (
        <div className={classes.body}>
          <BackButton url={"/profile"} />
          <div className="noHeader">
            <Header />
          </div>
          <SettingsButton />
          {this.state.showHistory ? (
            <div>
              <div className={classes.historyPlan} style={{ minWidth: 320 }}>
                {this.state.payoutsHistory !== undefined ? (
                  <ul className={classes.historyList}>
                    {this.state.payoutsHistory.map((item, index) => (
                      <li key={index} className={classes.textSmaller}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div>
                    <span className={classes.textSmaller}>
                     {t("payout.noHistory")}              
                    </span>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                }}
              >
                <button
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:disabled": {
                      backgroundColor: "rgba(135, 201, 255, 0.65)",
                    },
                  }}
                  aria-label="Link to show history"
                  role="link"
                  onClick={() => this.setState({ showHistory: false })}
                  className={classes.secondButton}
                >
                   {t("listen.back")}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.headerText}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    verticalAlign: "middle",
                    alignItems: "center",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <span className={classes.title}>
                     {t("payout.availableDrops")}
                   
                  </span>
                </div>
                <div style={{ marginTop: 10 }}>
                  <span className={classes.textSmaller}>
                    {t("payout.convert")}
                  </span>
                </div>
              </div>
              <div className={classes.plans}>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: 30,
                  }}
                >
                  <div style={{ marginTop: 10 }}>
                    <span className={classes.textSmaller}>
                      {t("payout.yourDrops")} 
                     
                    </span>
                  </div>
                  {this.state.dropsAmount}
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 18,
                      marginLeft: 2,
                      marginTop: -2,
                      color: "#87C9FF",
                      verticalAlign: "middle",
                    }}
                  >
                    &#xe798;
                  </span>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: 30,
                  }}
                >
                  {" "}
                  <div style={{ marginTop: 10 }}>
                    <span className={classes.textSmaller}>
                      {t("payout.dropsToConvert")}               
                    </span>
                  </div>
                  {this.state.dropsAmount > 100 ? this.state.dropsAmount - (this.state.dropsAmount % 100) : 0}
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 18,
                      marginLeft: 2,
                      marginTop: -2,
                      color: "#87C9FF",
                      verticalAlign: "middle",
                    }}
                  >
                    &#xe798;
                  </span>
                </div>
              </div>
              <div className={classes.headerText}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    verticalAlign: "middle",
                    alignItems: "center",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  {/* <span className={classes.title}>
                  Get Payment
                </span> */}
                </div>

                <div style={{ marginTop: 30 }}>
                  <span className={classes.textSmaller} style={{ color: "rgba(255, 255, 255, .45)" }}>
                    100{" "}
                    <span
                      className="material-icons"
                      style={{
                        fontSize: 18,
                        marginLeft: 2,
                        marginTop: -2,
                        color: "#87C9FF",
                        verticalAlign: "middle",
                      }}
                    >
                      &#xe798;
                    </span>{" "}
                    drops = 60 $
                  </span>
                  <div className={classes.verticalLine} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                      marginRight: "auto",
                      justifyContent: "center",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  ></div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <span className={classes.textSmaller}>
                    {t("payout.selectDrops")}
                    
                  </span>
                </div>
              </div>

              <div className={classes.plans}>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: 30,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="validity">
                    <select
                      style={{ width: "auto", marginRight: 20, marginBottom: 0, cursor: "pointer" }}
                      ref={(select) => (this.dropsAmount = select)}
                      onChange={(event) => this.setState({ drops: event.target.value })}
                    >
                      <option value="" selected hidden>
                      {t("payout.selectAmount")}
                      </option>
                      <optgroup label="Drops">
                        {new Array((this.state.dropsAmount - (this.state.dropsAmount % 100)) / 100)
                          .fill(1)
                          .map((el, index) => {
                            return (
                              <option key={`month-${index}`} value={(index + 1) * 100}>
                                {(index + 1) * 100}
                              </option>
                            );
                          })}
                      </optgroup>
                    </select>
                  </div>
                  <div>
                    <div>
                      <span className={classes.textSmaller}>
                       {t("payout.total")}
                        
                      </span>
                    </div>
                    ${(this.state.drops / 100) * 60 || "-"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                }}
              >
                <button
                  style={{}}
                  aria-label="Link to buy drops"
                  role="link"
                  onClick={async () => {
                    await this.handlePayout();
                  }}
                  disabled={this.state.disabled === true}
                  // onClick={() => this.setState({ checkout: true })} //masspay
                  className={classes.submitButton}
                >
                  {t("payout.payout")}
                  <img className="masspay" src={passpay} alt="ccbill" />
                </button>
                {/*<button*/}
                {/*  style={{*/}
                {/*    display: "flex",*/}
                {/*    alignContent: "center",*/}
                {/*    justifyContent: "center",*/}
                {/*    alignItems: "center",*/}
                {/*  }}*/}
                {/*  aria-label="Link to show history"*/}
                {/*  role="link"*/}
                {/*  onClick={async () => {*/}
                {/*    this.setState({ showHistory: true });*/}
                {/*    await this.handleClick();*/}
                {/*  }}*/}
                {/*  className={classes.secondButton}*/}
                {/*>*/}
                {/*  /!* {t("buydrops.checkout")} *!/*/}
                {/*  Payout History*/}
                {/*</button>*/}
              </div>
            </div>
          )}
          <Sidebar />
        </div>
      );
  }
}

const styles = {
  body: {
    position: "relative",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    maxWidth: "450px",
    textAlign: "center",
    paddingBottom: "75px",
  },
  cardLogo: {
    width: 120,
    height: 25,
    marginTop: 15,
  },
  // cardFooters: {
  //   position: "fixed",
  //   bottom: 80,
  //   width: "90%",
  //   maxWidth: 350,
  // },
  backButtonIcon: {
    width: 12,
    height: 20,
    marginTop: 10,
    position: "absolute",
  },
  cardLogoMaster: {
    width: 40,
    marginLeft: 20,
    marginTop: 7,
  },
  title: {
    // marginTop: 25,
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: "white",
  },
  textSmall: {
    color: "rgba(116, 107, 131, 1)",
    fontSize: 15,
    fontWeight: 600,
  },
  textSmaller: {
    color: "rgba(255, 255, 255, 0.5)",
    fontSize: 11,
  },
  headerText: {
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    maxWidth: 350,
    textAlign: "center",
  },
  label: {
    color: "white",
    fontSize: 15,
    fontWeight: 500,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    "&:disabled": {
      cursor:"progress",
      backgroundColor: "#87c9ff57",
      // color:"rgba(135, 201, 255, 0.5)"
    },
  },
  secondButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "grey",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  verticalLine: {
    borderTopColor: "rgba(255, 255, 255, .2)",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    alignItems: "center",
    // marginBottom: 20,
  },
  plans: {
    marginBottom: "20px",
    display: "flex",
    maxWidth: "350px",
    flexDirection: "column",
  },
  historyPlan: {
    marginBottom: "20px",
    display: "flex",
    maxWidth: "350px",
    flexDirection: "column",
  },
  historyList: {
    width: "90%",
    maxHeight: "335px",
    overflowX: "hidden",
    overflowY: "scroll",
    textAlign: "start",
  },
  "@media screen and (min-width: 800px)": {
    body: {
      display: "flex",
      top: "120px",
      left: "50%",
      margin: " 0px -93px",
      maxWidth: "512px",
    },
    submitButton: {
      width: "calc(100% - 80px)",
      // marginBottom: "50px",
    },
    secondButton: {
      width: "calc(100% - 80px)",
      marginBottom: "50px",
    },
    plans: {
      margin: "auto",
      display: "flex",
      maxWidth: "412px",
      padding: "20px 50px",
      flexDirection: "row",
      backgroundColor: "#180931",
      border: "1px solid rgba(255, 255, 255, .25)",
      boxShadow: "0px 0px 10px #C59CDC",
      borderRadius: "20px",
      // marginBottom: "150px",
    },
    historyPlan: {
      margin: "auto",
      display: "flex",
      maxWidth: "412px",
      padding: "20px 20px",
      flexDirection: "row",
      backgroundColor: "#180931",
      border: "1px solid rgba(255, 255, 255, .25)",
      boxShadow: "0px 0px 10px #C59CDC",
      borderRadius: "20px",
    },
    headerText: {
      maxWidth: "512px",
      paddingBottom: 15,
    },
    title: {
      // marginTop: 25,
      marginBottom: 15,
      fontSize: 18,
      fontWeight: 600,
      color: "white",
    },
    textSmall: {
      color: "rgba(116, 107, 131, 1)",
      fontSize: "17px",
      lineHeight: "27px",
      fontWeight: 600,
    },
    textSmaller: {
      color: "rgba(255, 255, 255, 0.5)",
      fontSize: "15px",
      lineHeight: "22.5px",
    },
    // cardFooters: {
    //   position: "inherit",
    //   width: "100%",
    //   maxWidth: "512px",
    // },
    // verticalLine: {
    //   display: "none",
    // },
  },
};

const PayoutComponentTranslated = withTranslation("common")(Payout);
export default injectSheet(styles)(PayoutComponentTranslated);
