import React, { Component } from "react";
import "./payment.css";

export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  flip(bool) {
    this.props.onFlip(bool);
  }

  handleSubmit(event) {
    document.activeElement.blur();
    event.preventDefault();

    this.props.onFormSubmit();
  }

  format(type, input, event) {
    // eslint-disable-next-line no-useless-escape
    let value = input.value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");

    // eslint-disable-next-line default-case
    switch (type) {
      case "card-number":
        value = value.replace(/\D/g, "");
        input.value = value.replace(/([0-9]{4})\B/g, "$1 ");

        if (input.value !== value) {
          setTimeout(() => input.setSelectionRange(input.value.length + 1, input.value.length + 1), 0);
        }

        this.props.onFormInput({ number: input.value });
        break;

      case "card-name":
        input.value = value.replace(/\d*/g, "");
        this.props.onFormInput({ name: input.value });
        break;

      case "card-month":
        this.props.onFormInput({ month: input[input.selectedIndex].value });
        break;

      case "card-year":
        this.props.onFormInput({ year: input[input.selectedIndex].value });
        break;

      case "card-cvv":
        input.value = value.replace(/\D/g, "");
        this.props.onFormInput({ cvv: input.value });
        break;
    }
  }

  render() {
    return (
      <div className="formMob">
        {!this.props.processing ? (
          <form ref={(form) => (this.form = form)} onSubmit={this.handleSubmit.bind(this)} className="form">
            <div className="details">
              <h3 className="title">{this.props.title}</h3>
              <div className="group input-type">
                <label htmlFor="card-number">CARD NUMBER</label>
                <input
                  type="text"
                  id="card-number"
                  placeholder="0000 0000 0000 0000"
                  pattern="[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}"
                  maxLength="19"
                  ref={(input) => (this.cardNumber = input)}
                  onInput={(event) => this.format("card-number", this.cardNumber, event)}
                  onFocus={() => this.flip(false)}
                  required
                />
                <i className="far fa-user"></i>
              </div>
              <div className="group input-type">
                <label htmlFor="card-name">CARDHOLDER NAME</label>
                <input
                  id="card-name"
                  placeholder="JOHN SMITH"
                  ref={(input) => (this.cardName = input)}
                  onInput={(event) => this.format("card-name", this.cardName, event)}
                  onFocus={() => this.flip(false)}
                  required
                />
                <i className="far fa-credit-card"></i>
              </div>
              <div className="row">
                <div className="group">
                  <label htmlFor="card-validity">VALID THROUGH</label>
                  <div className="validity">
                    <select
                      ref={(select) => (this.cardMonth = select)}
                      onChange={(event) => this.format("card-month", this.cardMonth, event)}
                      onFocus={() => this.flip(false)}
                      required
                    >
                      <option value="" selected hidden>
                        Month
                      </option>
                      <optgroup label="Month">
                        {new Array(12).fill(1).map((el, index) => {
                          return (
                            <option key={`month-${index}`} value={`${index + 1 < 10 ? `0${index + 1}` : index + 1}`}>
                              {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            </option>
                          );
                        })}
                      </optgroup>
                    </select>
                    <select
                      ref={(select) => (this.cardYear = select)}
                      onChange={(event) => this.format("card-year", this.cardYear, event)}
                      onFocus={() => this.flip(false)}
                      required
                    >
                      <option value="" selected hidden>
                        Year
                      </option>
                      <optgroup label="Year">
                        {new Array(12).fill(1).map((el, index) => {
                          return (
                            <option
                              key={`month-${index + parseInt(`${new Date().getFullYear()}`.slice(2, 4))}`}
                              value={`${index + parseInt(`${new Date().getFullYear()}`.slice(2, 4))}`}
                            >
                              {index + parseInt(`${new Date().getFullYear()}`.slice(2, 4))}
                            </option>
                          );
                        })}
                      </optgroup>
                    </select>
                  </div>
                </div>
                <div className="group input-type">
                  <label htmlFor="card-cvv">CVV</label>
                  <input
                    id="card-cvv"
                    placeholder="123"
                    pattern="[0-9]{3}"
                    maxLength="3"
                    ref={(input) => (this.cardCVV = input)}
                    onInput={(event) => this.format("card-cvv", this.cardCVV, event)}
                    onFocus={() => this.flip(true)}
                    onBlur={() => this.flip(false)}
                    required
                  />
                  <i className="fas fa-lock"></i>
                </div>
              </div>
              <div className="group">
                {this.props.showSave && (
                  <div className="checkbox">
                    <input type="checkbox" className="checkboxInput" id="saveLater" checked={false} onChange="" />

                    <div>
                      <label htmlFor="saveLater">Save for Later</label>
                    </div>
                  </div>
                )}
                <button className="newAccButton" type="submit">
                  {this.props.buttonName} <i className="fas fa-lock"></i>
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className="payment" style={{ marginTop: 40 }}>
            {!this.props.complete ? (
              <div className="trying">
                <h4>Processing payment...</h4>
                <div className="loader">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            ) : (
              <div>
                <div className="success">
                  <h4>Your payment was processed successfully!</h4>
                  <div className="check">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Form;
