import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import Filter from "./Filter";
import axios from "axios";
import { shuffle } from "../../../context/helper";
import TrendingHashtags from "../../Search/TrendingHashtags";
import TrendingCreators from "../../Search/TrendingCreators";
import FlatList from "flatlist-react";
import ListCard from "../../../general/ListCard";
import { Search as SearchIcon } from "react-feather";
import mixpanel from "mixpanel-browser";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class HeaderSearchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchItems: [],
      search: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
  }
  getAudios() {
    mixpanel.track("Screen_open_search");
    return axios
      .get(process.env.REACT_APP_BACKEND_URL + "/audios/all", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        let array = (res.data);
        array = array.filter((item) => item.curated);
        return array;})
  }

  async handleSearch(event) {
    let { searchValue } = this.state;
    if (event) {
      searchValue = event?.target?.value;
    }
    if (!searchValue || searchValue.length === 0) {
      this.setState({
        search: false,
        searchItems: [],
        searchValue,
      });
      return;
    }
    let { items } = this.state;
    if (!items) {
      items = await this.getAudios();
    }
    items = shuffle(items);
    const finalResult = items.filter(
      (item) => item.hashtags.length > 0 && item.hashtags.includes(searchValue) === true
    );
    const result2 = items.filter(
      (item) => item.author.length > 0 && item.author.toLowerCase().includes(searchValue.toLowerCase()) === true
    );
    const result3 = items.filter(
      (item) => item.title.length > 0 && item.title.toLowerCase().includes(searchValue.toLowerCase()) === true
    );
    this.setState({
      searchItems: [...finalResult, ...result2, ...result3],
      items,
      search: true,
      searchValue,
    });
  }

  renderItem = (item, idx) => {
    return <ListCard idx={idx} item={item} myVoiyals={this.state.searchItems} activeItem={[]} withNames={true} />;
  };
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const { items, search, searchItems } = this.state;

    return (
      <>
        <div className={classes.searchField}>
          <div className={classes.input_container}>
            <div className={classes.searchBody}>
              <div
                style={{
                  justifyContent: "center",
                  verticalAlign: "middle",
                  alignItems: "center",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <div className={classes.input_container}>
                  {search ? (
                    <button  onClick={() => this.setState({ search: false, searchValue:"" })} className={classes.openCloseBtn}>
                      x
                    </button>
                  ) : (
                    <SearchIcon className={classes.input_image} />
                  )}
                  <div style={{marginTop:2}}>
                    <input
                      className={classes.input}
                      type={"text"}
                      placeholder={t("listen.searchHashtags")}
                      value={this.state.searchValue}
                      onChange={this.handleSearch}      
                    />
                  </div>
                </div>
              </div>

              {search && (
                <div className={classes.containerSearch}>
                  <div>
                    <div style={{ marginTop: 15 }}>
                      <span className={classes.titleBlue}>Trending Hashtags</span>
                      <span><TrendingHashtags items={items} /></span>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <span className={classes.titleBlue}>Trending Creators</span>
                      <TrendingCreators items={items} />
                    </div>
                  </div>
                  <div className={classes.list}>
                    <ul className={classes.ListCardUl} style={{ margin: 0, minWidth: 320, paddingLeft: 0 }}>
                      <FlatList
                        list={searchItems}
                        renderItem={this.renderItem}
                        renderWhenEmpty={() => (
                          <div className="textSmall" style={{ margin: "auto" }}>
                            {t("general.emptyList")}
                          </div>
                        )}
                        renderOnScroll
                      />
                    </ul>
                  </div>
                </div>
              )}
            </div>

            {/* <Search className={classes.input_image} />
            <input
              className={classes.input}
              type={"text"}
              placeholder={t("listen.searchHashtags")}
              onKeyDown={this.handleKeyDown}
            /> */}
          </div>
          <div className={classes.searchButtons}>
            <div className={window.location.pathname === "/listenlist" ? "showFilter" : "hideFilter"}>
              <Filter
                filterAllPrices={this.props.filterAllPrices}
                filterFree={this.props.filterFree}
                filterPaid={this.props.filterPaid}
                filterAll={this.props.filterAll}
                filterEnglish={this.props.filterEnglish}
                filterSpanish={this.props.filterSpanish}
                filterGerman={this.props.filterGerman}
              />
            </div>
            <div className={window.location.pathname === "/listenlist" ? "" : "noFilterShow"} style={{marginTop:"2px"}}>
              <button
                onClick={() => this.handleSearch()}
                
                className={classes.searchButton}            
              >
                <span>{t("listen.searchHashtags")}</span>
              </button>
            </div>  
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  openCloseBtn: {
    position: "absolute",
    background:"transparent",
    border:"none",
    top: "7px",
    left: "18px",
    display: "block",
    margin: "auto",
    width: "30px",
    height: "30px",
    verticalAlign: "middle",
    lineHeight: "15px",
    color: "white",
    textDecoration: "none",
    fontSize: "28px",
    // border: "3px solid transparent",
    textAlign: "center",
    "-webkitTransition": "all 0.3s ease-out",
    transition: "all 0.3s ease-out",
    borderRadius: "50%",
    transform: "scale(0.8)",
    "&:hover": {
      color: "white",
      // border: "3px solid grey",
      transform: "scale(1) ",
    },
  },

  list: {
    marginBottom: "auto",
  },
  // searchBody: {
  //   paddingBottom: 150,
  //   position: "fixed",
  // },
  ListCardUl: {
    display: "block",
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 30,
    fontWeight: 600,
    paddingTop: 25,
    fontSize: 18,
    color: "rgba(255, 255, 255, .85)",
  },
  searchButtons: {
    display: "flex",
  },
  titleBlue: {
    marginTop: 30,
    fontSize: 18,
    fontWeight: 600,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .65)",
    fontSize: 14,
    textAlign: "center",
  },
  containerSearch: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "1125px",
    left: "-250px",
    top: "84px",
    backgroundColor: "#180931",
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "rgba(255, 255, 255, 0.9)",
    },
    "&:focus": {
      color: "white",
      outline: "none",
    },
    "&:active": {
      color: "white",
    },
    width: "300px",
    verticalAlign: "middle",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    height: 35,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "rgba(135, 201, 255, .2)",
    fontSize: 15,
    fontFamily: "Poppins",
    border: "none",
    webkitTextFillColor: "white",
    color: "white",
    fontWeight: 400,
    paddingLeft: "50px",
  },
  input_container: {
    position: "relative",
    width: "351px",
    padding: 0,
    margin: 0,
  },
  input_image: {
    color: "white",
    position: "absolute",
    top: 4,
    left: 14,
    width: 21,
    height: 18,
    margin: 10,
    marginRight: 5,
  },
  searchField: {
    display: "block",
  },
  searchButton: {
    display: "none",
  },

  "@media screen and (min-width: 800px)": {
    searchField: {
      display: "flex",
      // width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      gap:"3px"
    },
    list: {
      marginBottom: "90px",
    },
    ListCardUl: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxWidth: 800,
      paddingLeft: "10px!important",
      paddingRight: 10,
      margin: "auto!important",
    },
    filter: {
      margin: "auto",
      padding: " 0px 10px ",
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
    },
    button: {
      width: "351px",
      maxWidth: "351px",
    },
    searchButton: {
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      height: "45px",
      cursor: "pointer",
      // marginLeft: "10px",
      marginTop: 0,
      marginBottom: 0,
      // padding: 14,
      alignSelf: "center",
      verticalAlign: "middle",
      textAlign: "center",
      backgroundColor: "rgba(135, 201, 255, .85)",
      color: "white",
      fontWeight: 600,
      fontSize: 15,
      borderRadius: 10,
      border: 0,
      width: "125px",

      "&:disabled": {
        backgroundColor: "rgba(135, 201, 255, 0.65)",
      },
    },
    containerSearch: {
      position: "fixed",
      boxShadow: "0px 0px 10px 0px #c59cdc",
      top: "119px",
      left: "305px",
      overflow: "scroll",
      height: "100%",
      width: "calc(100% - 300px)",
    },
    input_container: {
      width: "130px",
    },
    input: {
      width: 81,
    },
  },
  "@media screen and (min-width: 940px)": {
    searchButton: {
      display: "flex",
      width: 70,
    },
    input_container: {
      width: "190px",
    },
    input: {
      width: 141,
    },
  },
  "@media screen and (min-width: 1160px)": {
    searchButton: {
      width: 125,
    },
    containerSearch: {
      width: "calc(100% - 300px)",
    },
    input_container: {
      width: "351px",
    },
    input: {
      width: "300px",
    },
  },
};

const HeaderSearchButtonComponentTranslated = withTranslation("common")(HeaderSearchButton);
export default injectSheet(styles)(HeaderSearchButtonComponentTranslated);
