import React, { Component } from "react";
import CCard from "../Components/payments/CCard";
import Form from "../Components/payments/Form";
import "../Components/payments/payment.css";
import Header from "../../../general/Navigation/Header";
import Sidebar from "../../../general/Navigation/Sidebar";
import "../Components/index.css";
import BackButton from "../../../general/Navigation/components/BackButton";
import SettingsButton from "../../../general/SettingsButton";
import { Redirect } from "react-router-dom";
import { getUser } from "../../../context/auth";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flipped: false,
      processing: false,
      complete: false,
      number: "",
      name: "",
      month: "",
      year: "",
      cvv: "",
      redirect: false,
    };
  }
  populate(field) {
    this.setState(field);
  }

  flip(bool) {
    this.setState({ flipped: bool });
  }
  async componentDidMount() {
    getUser().then(async (redirect) => {
      this.setState({ redirect: redirect });
    });
  }

  process() {}

  render() {
    return this.state.redirect || localStorage.getItem("token") === null ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <div className="cardContainer">
        <div className="noHeader">
          <Header />
        </div>
        <BackButton url={"/buydrops"} />
        <SettingsButton />
        <Sidebar />
        <h3 className="hideDesktop" style={{ textAlign: "center" }}>
          Edit Payment Information
        </h3>
        <h3 className="titlePayment" style={{ textAlign: "left" }}>
          Available Cards
        </h3>
        <div className="cards">
          {/* {cardFeed.map(({ name, num, date }, index) => ( */}
          <CCard key="{index}" showModal={this.toggleModal} name="{name}" number="123" month="01" year="23" />
          <CCard key="{index}" showModal={this.toggleModal} name="{name}" number="123" month="01" year="23" />
          <CCard key="{index}" showModal={this.toggleModal} name="{name}" number="123" month="01" year="23" />
          <CCard key="{index}" showModal={this.toggleModal} name="{name}" number="123" month="01" year="23" />
          <CCard key="{index}" showModal={this.toggleModal} name="{name}" number="123" month="01" year="23" />

          {/* ))} */}
          {/* <Card
            fields={this.state}
            flipped={this.state.flipped}
            processing={this.state.processing}
            complete={this.state.complete}
          /> */}
        </div>
        <div className="containerForm add">
          <Form
            title={"Add New Credit Card"}
            buttonName={"Add Card "}
            showSave={false}
            processing={this.state.processing}
            complete={this.state.complete}
            onFormSubmit={() => {
              this.setState({ processing: true });

              setTimeout(() => {
                this.setState({ complete: true });
              }, 5000);
            }}
            onFormInput={this.populate.bind(this)}
            onFlip={this.flip.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Payment;
