import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../general/Navigation/Footer";
import { getUsername } from "../../context/auth";

import mixpanel from "mixpanel-browser";
import AudioPlayerButtons from "../Listen/Components/AudioPlayerButtons";
import Header from "../../general/Navigation/Header";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayTime: 0,
      author: "",
      title: "",
      duration: "00:00",
      description: "",
      url: "",
      id: "",
      pathName: "",
      urlAudio: "",
      currentTime: 0,
    };
    this.timeUpdate = this.timeUpdate.bind(this);
  }

  async componentDidMount() {
    const values = this.props.location.pathname;
    const id = values.substring(values.lastIndexOf("/") + 1);
    await getUsername().then(async (name) => {
      if (name) this.setState({ redirect: true, pathname: "/listenlist/" + id });
    });
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/audios/singleaudiopreview/" + id)
      .then(async (res) => {
        if (res.data.length > 0) {
          console.log(res.data.duration);
          this.setState({
            author: res.data[0].author,
            title: res.data[0].title,
            duration: res.data[0].duration,
            id: id,
            urlAudio: res.data[0].url,
            description: res.data[0].description,
          });
          await axios
            .get(process.env.REACT_APP_BACKEND_URL + "/profilepic/get", {
              params: {
                username: res.data[0].author,
              },
            })
            .then((res) => {
              if (res.data.length > 0) this.setState({ url: res.data[0].fileUrl });
              else this.setState({ url: "" });
            });
        } else {
          window.location.href = "https://www.voiyal.com";
        }
      })
      .catch((error) => {
        console.log("error" + error);
      });
    mixpanel.track("Screen_open_audio_preview");
  }

  timeUpdate() {
    let player = document.getElementById("player");
    this.setState({
      currentTime: player.currentTime,
      displayTime: this.state.displayTime + 1,
    });
    if (player.currentTime > 30) {
      player.pause();
      player.currentTime = 0;
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return this.state.redirect ? (
      <Redirect
        to={{
          pathname: this.state.pathname,
          state: { username: this.state.name },
        }}
      />
    ) : (
      <div className={classes.body}>
        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 50 }}>
          <Header />
          {/* <Link to={"/"}>
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <img style={{ width: 120 }} src="https://d3bk8pb8wlf64b.cloudfront.net/Logo_GIF.gif" alt="loading..." />
            </div>
          </Link> */}
          <div className={classes.card}>
            <div className={classes.price}>
              <div style={{ marginTop: 2 }}>
                <b className={classes.textSmall} style={{ verticalAlign: "middle" }}>
                  FREE
                </b>
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                textAlign: "left",
                marginLeft: 10,
                width: 250,
              }}
            >
              <b className={classes.text} style={{ fontSize: 16 }}>
                {this.state.title}
              </b>
              <br />
              <span className={classes.textSmaller}>{t("preview.limitText")}</span>
              <br />
            </div>
            <AudioPlayerButtons
              time={0}
              next={() => console.log("next")}
              previous={() => console.log("previous")}
              stop={() => document.getElementById("player").pause()}
              clickPlay={() => {
                document.getElementById("player").play();
              }}
              duration={"00:30"}
              progressBar={(30 / 100) * this.state.currentTime}
            />
            <div
              style={{
                textAlign: "center",
                alignSelf: "center",
                justifyContent: "center",
                display: "none",
              }}
            >
              <audio
                id="player"
                controls
                controlsList={"nodownload"}
                autoPlay={true}
                className={classes.audioPlayer}
                onTimeUpdate={this.timeUpdate}
                src={this.state.urlAudio}
              />
            </div>
            <div className={classes.line} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "middle",
              }}
            >
              <img alt={"cover"} className={classes.audioListCardPic} src={this.state.url} />
              <div
                className={classes.textSmall}
                style={{
                  marginTop: 22,
                  marginLeft: 10,
                  verticalAlign: "middle",
                  fontWeight: 600,
                }}
              >
                <span className={classes.text} style={{ opacity: 0.8 }}>
                  {this.state.author}
                </span>
              </div>
            </div>
            <div className={classes.buttons}>
              <Link to="/register" className={classes.registerButton}>
                <span
                  className="material-icons"
                  style={{
                    fontSize: "25px",
                    color: "white",
                    verticalAlign: "middle",
                    marginRight: 5,
                  }}
                >
                  &#xe038;
                </span>
                <span style={{ fontSize: 20, verticalAlign: "middle" }}>{t("preview.register")}</span>
              </Link>
              <span className={classes.textSmaller}>{t("preview.or")}</span>
              <Link to="/" className={classes.textSmall} style={{ textDecoration: "underline", marginTop: 5 }}>
                {t("preview.login")}
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const styles = {
  card: {
    zIndex: 20,
    display: "inline-block",
    width: 320,
    margin: 15,
    height: 430,
    padding: 5,
    textAlign: "left",
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 15,
    position: "relative",
    boxShadow:
      "0px 0px 8px 3px #c59cdc, 0px 50px 1px -30px #463668, 1px 98px 2px -60px #4c3a7180, 1px 20px 2px -100px #463668",
  },
  buttons: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
  },
  text: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 15,
  },
  date: {
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: 5,
    marginTop: 7,
  },
  textSmall: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 14,
    textAlign: "center",
  },
  textSmaller: {
    fontSize: 13,
    color: "rgba(255, 255, 255, .65)",
  },
  body: {
    marginBottom: 100,
    paddingTop: 20,
  },
  playButtonTop: {
    width: 30,
    height: 30,
  },
  noDefaultButton: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  listCard: {
    display: "inline-block",
    width: 320,
    margin: 15,
    height: 250,
    padding: 5,
    textAlign: "left",
    backgroundColor: "rgba(0, 0, 0, .25)",
    borderRadius: 5,
    position: "relative",
  },
  registerButton: {
    cursor: "pointer",
    marginTop: 5,
    marginBottom: 5,
    padding: 8,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 12,
    borderRadius: 5,
    border: 0,
    width: "70%",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  list: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    verticalAlign: "middle",
  },
  textToken: {
    color: "rgba(255, 255, 255, .85)",
    fontSize: 32,
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    verticalAlign: "middle",
  },
  mainPic: {
    width: 120,
    height: 120,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 25,
  },
  audioListCardPic: {
    width: 50,
    height: 50,
    margin: 10,
    borderRadius: 5,
    marginRight: 5,
    marginBottom: 7,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgba(255, 255, 255, 0.2)",
    margin: 5,
  },
  price: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#87C9FF",
    borderRadius: 5,
    maxWidth: 55,
    verticalAlign: "middle",
    marginLeft: 10,
    marginTop: 8,
    textAlign: "center",
    height: 25,
  },
  "@media screen and (min-width: 800px)": {
    body: {
      marginTop: "120px",
      maxWidth: 800,
      width: "100%",
    },
    card: {
      width: 500,
      height: 280,
    },
    buttons: {
      marginTop: 90,
    },
    registerButton: {
      width: "50%",
    },
  },
  "@media screen and (min-width: 1111px)": {
    card: {
      width: "765px",
      maxWidth: "765px",
    },
  },
};

const PreviewComponentTranslated = withTranslation("common")(Preview);
export default injectSheet(styles)(PreviewComponentTranslated);
