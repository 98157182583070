import React, { Component } from "react";
import injectSheet from "react-jss";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { getUsername } from "../../../context/auth";
import FlatList from "flatlist-react";
import { displayDate } from "../../../context/helper";
import { Trash2 } from "react-feather";
import drops from "../../../assets/icons/drops.svg";
import playButton from "../../../assets/icons/playbtn.svg";

class AudioList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      stories: [],
      counters: [],
    };
  }

  componentDidMount() {
    getUsername().then((name) => {
      this.setState({ username: name });
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/audios/oneuser", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            username: name,
          },
        })
        .then(async (res) => {
          let array = res.data;
          for (let i = 0; i < array.length; i++) {
            axios
              .get(process.env.REACT_APP_BACKEND_URL + "/myvoiyals/count/" + array[i]._id, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then(async (res) => {
                console.log(res.data);
                array[i].count = res.data;
                this.setState({ stories: array });
              });
          }
        });
    });
  }

  async deleteStory(id) {
    axios
      .delete(process.env.REACT_APP_BACKEND_URL + "/audios/delete/" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(() => {
        axios
          .get(process.env.REACT_APP_BACKEND_URL + "/audios/oneuser", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              username: this.state.username,
            },
          })
          .then((res) => {
            this.setState({ stories: res.data });
          });
      });
  }

  renderItem = (item, idx) => {
    const { classes } = this.props;
    return item.curated ? (
      <li key={idx} className={classes.listCard}>
        <div>
          <a className="noDefaultButton" href={window.location.origin + "/listenlist/" + item._id}>
            <span className={classes.text}>{item.title}</span>
          </a>
          <button
            style={{ width: "initial", padding: "0px" }}
            aria-label="Delete story"
            className={classes.noDefaultButton}
            onClick={() => this.deleteStory(item._id)}
          >
            <Trash2 className={classes.deleteButton} />
          </button>
        </div>
        <div style={{ marginTop: 5 }}>
          <span className={classes.textSmaller}>{displayDate(item.date)}</span>
          {item.hashtags.slice(-3).map((hashtag) => (
            <span
              className={classes.textSmaller}
              style={{
                margin: 0,
                marginRight: 2,
                marginLeft: 2,
              }}
            >
              [{hashtag}]
            </span>
          ))}
        </div>
        <div style={{ marginTop: 5 }}>
          <span className={classes.textSmall}>{item.description}</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", verticalAlign: "middle" }}
        >
          <div className={classes.price}>
            <img
              src={drops}
              alt=""
              style={{
                color: "#87C9FF",
                fill: "#87C9FF",
                verticalAlign: "middle",
                width: 7.24,
                height: 9.69,
                marginRight: 6,
              }}
            />
            <b className={classes.textSmall} style={{ verticalAlign: "middle" }}>
              {item.duration.split(":")[0]}
            </b>
          </div>
          <div className={classes.plays}>
            <img
              src={playButton}
              alt=""
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                color: "#87C9FF",
                verticalAlign: "middle",
                width: 12,
                height: 12,
                marginRight: 6,
              }}
            />
            <b className={classes.textSmall} style={{ verticalAlign: "middle" }}>
              {item.count}
            </b>
          </div>
        </div>
      </li>
    ) : (
      <li key={idx} className={classes.listCardNotLive}>
        <div>
          <a className="noDefaultButton" href={window.location.origin + "/listenlist/" + item._id}>
            <span className={classes.text}>{item.title}</span>
          </a>
          <div className={classes.noDefaultButton} onClick={() => this.deleteStory(item._id)}>
            <Trash2 className={classes.deleteButton} />
          </div>
        </div>
        <div style={{ marginTop: 5 }}>
          <span className={classes.textSmaller}>{displayDate(item.date)}</span>
          {item.hashtags.slice(-3).map((hashtag) => (
            <span
              className={classes.textSmaller}
              style={{
                margin: 0,
                marginRight: 2,
                marginLeft: 2,
              }}
            >
              [{hashtag}]
            </span>
          ))}
        </div>
        <div style={{ marginTop: 5 }}>
          <span className={classes.textSmall}>{item.description}</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", verticalAlign: "middle" }}
        >
          <div className={classes.price}>
            <img
              src={drops}
              alt=""
              style={{
                color: "#87C9FF",
                fill: "#87C9FF",
                verticalAlign: "middle",
                width: 8,
                marginRight: 6,
              }}
            />
            <b className={classes.textSmall} style={{ verticalAlign: "middle" }}>
              {item.duration.split(":")[0]}
            </b>
          </div>
          <div className={classes.inreview}>
            <b style={{ fontSize: 14 }}>in review</b>
          </div>
        </div>
      </li>
    );
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <FlatList
        list={this.state.stories}
        renderItem={this.renderItem}
        renderWhenEmpty={() => (
          <div className={classes.textSmaller} style={{ textAlign: "center" }}>
            {t("myAudios.noStories")}
          </div>
        )}
        renderOnScroll
      />
    );
  }
}

const styles = {
  text: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 18,
    fontWeight: 600,
  },
  textSmall: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 13,
  },
  textSmaller: {
    color: "rgba(255, 255, 255, .5)",
    fontSize: 11,
  },
  listCard: {
    marginTop: 15,
    display: "block",
    listStyleType: "none",
    backgroundColor: "rgba(24, 9, 49, 1)",
    borderRadius: 10,
    boxShadow: "0px 0px 10px #C59CDC",
    margin: 5,
    padding: 10,
    // maxWidth: 320,
    minWidth: 280,
  },
  listCardNotLive: {
    marginTop: 15,
    display: "block",
    listStyleType: "none",
    backgroundColor: "rgba(255,255,255,0.3)",
    borderRadius: 10,
    boxShadow: "0px 0px 10px #C59CDC",
    margin: 5,
    padding: 10,
    // maxWidth: 320,
    minWidth: 280,
  },
  noDefaultButton: {
    float: "right",
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    verticalAlign: "sub",
  },
  deleteButton: {
    color: "white",
    width: 20,
    height: 24,
  },
  price: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#87C9FF",
    borderRadius: 5,
    width: 50,
    verticalAlign: "middle",
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  plays: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#87C9FF",
    borderRadius: 5,
    width: 50,
    verticalAlign: "middle",
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: 'center',
  },
  inreview: {
    width: 80,
    opacity: 0.9,
    height: 15,
    bottom: 0,
    verticalAlign: "middle",
    marginTop: 15,
    color: "#FF6057",
    borderRadius: 5,
  },
  "@media screen and (min-width: 800px)": {
    listCard: {
      maxWidth: "none",
      padding: 20,
      borderRadius: 20,
    },
    listCardNotLive: {
      padding: 20,
      borderRadius: 20,
    },
    price: {
      height: 23,
    },
    plays: {
      height: 23,
    },
    textSmaller: {
      fontSize: "14px",
    fontWeight: '500',


    },
    title: {
      fontSize: "18px",
    },
    textSmall: {
      fontSize: "14px",
      fontStyle: 'normal',
      fontWeight: 600,
    },
  },
};

const AudioListComponentTranslated = withTranslation("common")(AudioList);
export default injectSheet(styles)(AudioListComponentTranslated);
