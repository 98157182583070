import React, { Component } from "react";
import { getUser, getUsername } from "../../../context/auth";
import { withTranslation } from "react-i18next";
import injectSheet from "react-jss";
import mixpanel from "mixpanel-browser";
import SettingsButton from "../../../general/SettingsButton";
import BackButton from "../../../general/Navigation/components/BackButton";
import Sidebar from "../../../general/Navigation/Sidebar";
import Header from "../../../general/Navigation/Header";
import axios from "axios";
import { Redirect } from "react-router-dom";

mixpanel.init("9054c99c1bfc9aab2194f949f30da7cf", { debug: true });

class VerifyAge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
  }

  async componentDidMount() {
    getUser().then((redirect) => {
      this.setState({ redirect: redirect });
    });
    getUsername().then((name) => {
      this.setState({ username: name });
    });
    mixpanel.track("Screen_open_buy_drops");
    this.initAgeChecker();

    // this.checkIfVerified();
  }

  checkIfVerified() {
    return axios
      .get(process.env.REACT_APP_BACKEND_URL + "/ageverify/check", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((r) => r.data)
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  initAgeChecker() {
    const w = window;
    const d = document;
    let config = {
      element: "#verifyAge",
      key: process.env.REACT_APP_AGE_CHECKER_KEY,
      onstatuschanged: function (verification) {
        if (verification.status === "accepted") {
          axios.post(process.env.REACT_APP_BACKEND_URL + "/ageverify/add", verification, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          window.location.href ="/record"
        }
      },
    };

    w.AgeCheckerConfig = config;
    if (config.path && (w.location.pathname + w.location.search).indexOf(config.path)) return;
    const h = d.getElementsByTagName("head")[0];
    var a = d.createElement("script");
    a.src = "https://cdn.agechecker.net/static/popup/v1/popup.js";
    a.crossOrigin = "anonymous";
    a.onerror = function (a) {
      w.location.href = "https://agechecker.net/loaderror";
    };
    h.insertBefore(a, h.firstChild);
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;

    return this.state.redirect ? (
      <Redirect to={{ pathname: this.state.redirectUrl }} />
    ) : (
      <div className={classes.body}>
        <BackButton url={"/profile"} />
        <SettingsButton />
        <div>
          <div className={classes.headerText}>
            <div style={{ marginTop: "10px" }}>
              <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M46.0415 0.5H5.95847C2.67157 0.5 0 3.17157 0 6.45847V33.5415C0 36.8284 2.67157 39.5 5.95847 39.5H46.0415C49.3284 39.5 52 36.8284 52 33.5415V6.45847C52 3.17157 49.3284 0.5 46.0415 0.5V0.5ZM16.25 9.16653C19.2358 9.16653 21.6665 11.5977 21.6665 14.5835C21.6665 17.5688 19.2358 20 16.25 20C13.2642 20 10.8335 17.5688 10.8335 14.5835C10.8335 11.5977 13.2642 9.16653 16.25 9.16653ZM26 29.2085C26 30.1055 25.272 30.8335 24.375 30.8335H8.125C7.228 30.8335 6.5 30.1055 6.5 29.2085V28.125C6.5 24.8381 9.17157 22.1665 12.4585 22.1665H20.0415C23.3284 22.1665 26 24.8381 26 28.125V29.2085ZM43.875 30.8335H31.9585C31.0615 30.8335 30.3335 30.1055 30.3335 29.2085C30.3335 28.3115 31.0615 27.5835 31.9585 27.5835H43.875C44.772 27.5835 45.5 28.3115 45.5 29.2085C45.5 30.1055 44.772 30.8335 43.875 30.8335ZM43.875 22.1665H31.9585C31.0615 22.1665 30.3335 21.4385 30.3335 20.5415C30.3335 19.6445 31.0615 18.9165 31.9585 18.9165H43.875C44.772 18.9165 45.5 19.6445 45.5 20.5415C45.5 21.4385 44.772 22.1665 43.875 22.1665ZM43.875 13.5H31.9585C31.0615 13.5 30.3335 12.772 30.3335 11.875C30.3335 10.978 31.0615 10.25 31.9585 10.25H43.875C44.772 10.25 45.5 10.978 45.5 11.875C45.5 12.772 44.772 13.5 43.875 13.5Z"
                  fill="white"
                />
              </svg>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <span className={classes.title}>{t("verifyage.title")}</span>
            </div>

            <div style={{ margin: "0px 10px" }}>
              <span className={classes.textSmall}>{t("verifyage.text")}</span>
            </div>
          </div>
          <div className={classes.plans}>
            <div
              style={{
                textAlign: "left",
                margin: 23,
              }}
            >
              <p className={classes.textSmall1}>{t("verifyage.paragraph")} </p>
              <p className={classes.textSmaller1}>{t("verifyage.paragraph")} </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <button id="verifyAge" aria-label="Verify Age Link" role="link" className={classes.submitButton}>
            {t("verifyage.startAccountVerification")}
            </button>
          </div>
        </div>
        <Sidebar />
        <div className="noHeader">
          <Header />
        </div>

        {/* <NavBar /> */}
        <noscript>
          <meta httpEquiv="refresh" content="0;url=https://agechecker.net/noscript" />
        </noscript>
      </div>
    );
  }
}

const styles = {
  body: {
    position: "relative",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    maxWidth: "450px",
    textAlign: "center",
    marginLeft: 20,
    marginRight: 20,
  },

  backButtonIcon: {
    width: 12,
    height: 20,
    marginTop: 10,
    position: "absolute",
  },
  cardLogoMaster: {
    width: 40,
    marginLeft: 20,
    marginTop: 7,
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: "white",
  },
  textSmall: {
    fontSize: 15,
    fontWeight: 600,
    color: "white",
  },
  textSmall1: {
    fontSize: 15,
    fontWeight: 600,
    color: "#C59CDC",
  },
  textSmaller1: {
    fontSize: 13,
    color: "#C59CDC",
  },
  headerText: {
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    margin: "auto",
    maxWidth: 350,
    textAlign: "center",
  },
  label: {
    color: "white",
    fontSize: 15,
    fontWeight: 500,
  },
  submitButton: {
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 14,
    alignSelf: "center",
    verticalAlign: "middle",
    textAlign: "center",
    backgroundColor: "rgba(135, 201, 255, .85)",
    color: "white",
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    "&:disabled": {
      backgroundColor: "rgba(135, 201, 255, 0.65)",
    },
  },
  verticalLine: {
    borderTopColor: "rgba(255, 255, 255, .2)",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  plans: {
    marginBottom: "20px",
    marginTop: "30px",
    display: "flex",
    maxWidth: "350px",
    height: "219px",

    flexDirection: "column",
    border: "1px solid rgba(255, 255, 255, .25)",
    boxShadow: "0px 0px 10px #C59CDC",
    borderRadius: "20px",
  },
  "@media screen and (min-width: 800px)": {
    body: {
      top: "23%",
      left: "50%",
      width: "512px",
      margin: "0px -127px",
      position: "absolute",
      maxWidth: "512px",
    },
    submitButton: {
      width: "calc(100% - 160px)",
      marginBottom: "10px",
    },
    plans: {
      margin: "auto",
      display: "flex",
      maxWidth: "412px",
      height: "219px",
      flexDirection: "column",
      backgroundColor: "#180931",
      border: "1px solid rgba(255, 255, 255, .25)",
      boxShadow: "0px 0px 10px #C59CDC",
      borderRadius: "20px",
      marginBottom: "10%",
    },
    headerText: {
      maxWidth: "512px",
      paddingBottom: 35,
    },
    title: {
      marginTop: 25,
      marginBottom: 15,
      fontSize: 18,
      fontWeight: 600,
      color: "white",
    },
    textSmall: {
      fontSize: "17px",
      lineHeight: "27px",
      fontWeight: 600,
    },
    textSmall1: {
      fontSize: "17px",
      lineHeight: "27px",
      fontWeight: 600,
      color: "#C59CDC",
    },
    textSmaller: {
      fontSize: "13px",
      lineHeight: "22.5px",
      color: "#C59CDC",
    },

    verticalLine: {
      display: "none",
    },
  },
};

const VerifyAgeComponentTranslated = withTranslation("common")(VerifyAge);
export default injectSheet(styles)(VerifyAgeComponentTranslated);
